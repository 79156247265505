import {
  CLIENTES_BY_ID,
} from "../constants/cliente.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];

function cliente2Reducer(clientesbyId = initialState, action) {
  const { type, payload } = action;

  function Refreshdata(){
    window.location.reload(true);
  }

  switch (type) {
    
    case CLIENTES_BY_ID: 
      return payload;

    default:
      return clientesbyId;
  }
};

export default cliente2Reducer;