import {
  CREATE_TIPO_USUARIO,
  RETRIEVE_TIPO_USUARIOS,
  UPDATE_TIPO_USUARIO,
  DELETE_TIPO_USUARIO,
  TIPO_USUARIOS_BY_ID
} from "../constants/tipo_usuario.constants";

import { toast } from 'react-toastify';

import TipoUsuarioDataService from "../services/tipo_usuario.service";

export const createTipoUsuario = (nombre) => async (dispatch) => {
  try {
    const res = await TipoUsuarioDataService.create({ nombre});

    dispatch({
      type: CREATE_TIPO_USUARIO,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err.message);
  }
};

export const retrieveTipoUsuario = () => async (dispatch) => {
  try {
    const res = await TipoUsuarioDataService.getAllData();

    dispatch({
      type: RETRIEVE_TIPO_USUARIOS,
      payload: res.data,
      
    });
    
  } catch (err) {
    console.log(err);
  }
};

export const findTipoUsuariosById = (id) => async (dispatch) => {
  try {
    const res = await TipoUsuarioDataService.get(id);

    dispatch({
      type: TIPO_USUARIOS_BY_ID,
      payload: res.data,
    }); 
    
  } catch (err) {
    console.log(err);
  }
};

export const updateTipoUsuario = (id, data) => async (dispatch) => {
  try {
    const res = await TipoUsuarioDataService.update(id, data);

    dispatch({
      type: UPDATE_TIPO_USUARIO,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteTipoUsuario = (id) => async (dispatch) => {
  try {
    await TipoUsuarioDataService.delete(id);

    dispatch({
      type: DELETE_TIPO_USUARIO,
      payload: { id },
    });
  } catch (err) {
    //console.log(err);

    if (err.message == "Request failed with status code 400") {
      toast.error('No se puede Eliminar este Tipo Usuario, el Id está siendo usada por otra tabla', {
        autoClose: 10000,
      });
    }
    if (err.message == "Request failed with status code 401") {
      toast.error('Sin Autorización');
    }

    if (err.message == "Request failed with status code 505") {
      toast.error('No tiene permisos suficientes para visitar esta página');
    }

  }
};
