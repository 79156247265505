
import React, { Component } from "react";
import { connect } from "react-redux";


import MaterialTable,{ MTableToolbar }  from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import jwt from 'jwt-decode'
import {Button, Chip, Icon, Checkbox} from '@material-ui/core';
import {Grid, Divider, Paper} from "@material-ui/core";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from 'react-toastify';
import DialogContentText from '@mui/material/DialogContentText';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {TextField} from "@material-ui/core";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import HighlightOff from '@material-ui/icons/HighlightOff';
import ReorderIcon from '@mui/icons-material/Reorder';
import {
  retrieveMoneda, deleteMoneda, updateMoneda, findMonedasById
} from "../../actions/moneda.actions";
import {
  findPermisosById
} from "../../actions/permisos.actions";

import MonedaDialog from "../Dialog/moneda.dialog";
import MonedaForm from "../form/moneda.form";
import {isEmail, isEmpty,isLength, equals} from 'validator';

class MonedaList extends Component {
  constructor(props) {
    super(props);

    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyUp2 = this.onKeyUp.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleChangeDelete = this.handleChangeDelete.bind(this);
    this.onChangeMoneda = this.onChangeMoneda.bind(this);
    this.onChangeIdMoneda = this.onChangeIdMoneda.bind(this);
    this.onChangeDescripcion = this.onChangeDescripcion.bind(this);
    this.onChangeSimbolo = this.onChangeSimbolo.bind(this);
    this.handleChangeData = this.handleChangeData.bind(this)
     
    this.state = {
        checkedAdd: true ? false : true,
        checkedEdit: true ? false : true,
        checkedFilter: true ? false : true,
        checkedDelete: false,
        deleted : false,
        updated: false,
        moneda: "",
        id: "",
        simbolo: "",
        idmoneda: "",
        descripcion: "",
        togleedit: false,
        varData: false,

        idUser: "",
        Psimbolo: "",
        Pidmoneda: "",
        Pdescripcion: "",

        /* ------------------------*/
        idPermisosAddMoneda: "",
        idPermisosEditMoneda: "",
        idPermisosEliminarMoneda: "",
        idPermisosListMoneda: "",

        idPermisosAddPais: "",
        idPermisosEditPais: "",
        idPermisosEliminarPais: "",
        idPermisosListPais: "",

        idPermisosAddUsuarios: "",
        idPermisosEditUsuarios: "",
        idPermisosEliminarUsuarios: "",
        idPermisosListUsuarios: "",

        idPermisosAddPaquetes: "",
        idPermisosEditPaquetes: "",
        idPermisosEliminarPaquetes: "",
        idPermisosListPaquetes: "",

        /* permisos de permisos */

        idPermisos_PermisosAddMoneda: "",
        idPermisos_PermisosEditMoneda: "",
        idPermisos_PermisosEliminarMoneda: "",
        idPermisos_PermisosListMoneda: "",

        idPermisos_PermisosAddPais: "",
        idPermisos_PermisosEditPais: "",
        idPermisos_PermisosEliminarPais: "",
        idPermisos_PermisosListPais: "",

        idPermisos_PermisosAddUsuarios: "",
        idPermisos_PermisosEditUsuarios: "",
        idPermisos_PermisosEliminarUsuarios: "",
        idPermisos_PermisosListUsuarios: "",

        idPermisos_PermisosAddPaquetes: "",
        idPermisos_PermisosEditPaquetes: "",
        idPermisos_PermisosEliminarPaquetes: "",
        idPermisos_PermisosListPaquetes: "",

    }

  }

  componentDidMount() {

   // this.props.retrieveMoneda();

    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
    if (!tken) return false
    const decoded = jwt(tken); 
    
    //console.log("idUsuarios", decoded.idUsuarios)
    this.props.findPermisosById(decoded.idUsuarios)
  
  }

  componentDidUpdate(prevProps, prevState) {
    //let newName = 'Obaseki Nosa'

    const { permisosbyid} = this.props 
    // Don't forget to compare states
    
    if (prevState && prevState.datapermisosbyid !== permisosbyid) {
      permisosbyid.data && permisosbyid.data.map (permisos => {
      //console.log("permisosbyid", permisosbyid.code)
      if(permisos.code == "2003"){
       
        this.state.idPermisosListMoneda = permisos.code;
    }
  })
    
  }
  }

  handleChangeData(varData) {
    this.setState({ 
      varData
    });
    if(this.state.idPermisosListMoneda == "2003"){
      this.props.retrieveMoneda();
    }
  }


  handleChangeFilter(checkedFilter){
    this.setState({ 
        checkedFilter
      });

      
  }

  onChangeIdMoneda(e) {
    const idmoneda = e.target.value.toUpperCase();
    
    this.setState({
      idmoneda: idmoneda,
    });
  }

  onChangeDescripcion(e) {
    const descripcion = e.target.value.toUpperCase();
    
    this.setState({
      descripcion: descripcion,
    });
  }

  onKeyUp(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.handleChangeAdd(false, true, this.state.id);
    }
  }

  onKeyUp2(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.handleChangeDelete(false, true, this.state.id);
    }
  }

  onChangeSimbolo(e) {
    const simbolo = e.target.value;
    
    this.setState({
      simbolo: simbolo,
    });
  }


  onChangeMoneda(e) {
    const moneda = e.target.value;
    
    this.setState({
      moneda: moneda,
    });
  }

  handleChangeEdit(id){
    //this.props.history.push(`/admin/ingreso/edit/${id}`)
    window.location.replace(`/#/admin/ingresos/edit/${id}`);
   //toast.success('Cliente Registrado de manera Éxitosa');
  }

  handleChangeAdd(checkedAdd, updated, id){
    this.state.id = id
    if(this.state.id){
    this.props.findMonedasById(id);}
    else{
      console.log("cerrado")
    }
    
      //console.log("id2: ", id)
      //this.handleChangeEdit(this.state.id)

      if(checkedAdd== true && updated == false){
       
          this.setState({
            checkedAdd, 
          })


        //console.log("ABIERTO")
      }
      if(checkedAdd== false && updated == false){
        this.setState({ 
          checkedAdd,
          togleedit: false
        });
        
        //console.log("CERRADO")
      }
  
      if(checkedAdd== false && updated == true){
       /* this.setState({ 
          checkedDelete,
        });
        console.log("ELIMINADO ID", id)
      
      }*/

      if(this.state.simbolo == null){
        this.setState({ 
          simbolo: this.state.Psimbolo
        });
      }

      if(this.state.descripcion == null){
        this.setState({ 
          descripcion: this.state.Pdescripcion
        });
      }

      if(this.state.idmoneda == null){
        this.setState({ 
          idmoneda: this.state.Pidmoneda
        });
      }
     
      var dataUp = { 
        simbolo: this.state.simbolo,
        idmoneda: this.state.idmoneda,
        descripcion: this.state.descripcion
      };
    

      this.props
     .updateMoneda(id, dataUp)
     .then((data) => {
      this.setState({
        simbolo: data.simbolo,
        idmoneda: data.idmoneda,
        idmoneda: data.idmoneda,
     // this.props.history.push("/tutorials");
      })
    })
    .catch((e) => {
      console.log(e);
    });	 
      
      
  }
}


toggleChange2 = () => {
  this.setState({
    togleedit: !this.state.togleedit,
  });

}


  handleChangeDelete(checkedDelete, deleted, id){


    this.state.id = id
    if(checkedDelete== true && deleted == false){
      this.setState({ 
        checkedDelete,
      });
      //this.toggleChange2(true);
      //console.log("ABIERTO")
    }
    if(checkedDelete== false && deleted == false){
      this.setState({ 
        checkedDelete,
      });
      //console.log("CERRADO")
    }

    if(checkedDelete== false && deleted == true){
     /* this.setState({ 
        checkedDelete,
      });
      console.log("ELIMINADO ID", id)
    }*/
    this.props.deleteMoneda(id)
   .then(() => {
   // this.props.history.push("/tutorials");
  })
  .catch((e) => {
    //console.log(e);

    console.log("err: ", e)
        
  });	 
   
 }
}
  


  render() {
    const {monedas} = this.props;
    const {monedasbyid} = this.props;
    const {permisosbyid} = this.props;
    if(!this.state.togleedit){
      monedasbyid.data && monedasbyid.data.map (moneda => {
    this.state.idmoneda = `${moneda.idMoneda}`
    this.state.descripcion = `${moneda.descripcion}`
    this.state.simbolo = `${moneda.simbolo}`
      });
    }

    permisosbyid.data && permisosbyid.data.map (permisos => {
      if(permisos.code == "2003") {this.state.idPermisosListMoneda = "2003"}
        if(permisos.code == "2001") {this.state.idPermisosAddMoneda = "2001"}
        if(permisos.code == "2002") {this.state.idPermisosEditMoneda = "2002"}
        if(permisos.code == "2004") {this.state.idPermisosEliminarMoneda = "2004"}
    }
     // console.log("permisos", permisos)
  
)
   this.state.togle = true
    return (
        <React.Fragment >
         
			<div style={{width: "100%", justifyContent: 'center'}}>
      <Grid container >
     
       { equals(this.state.idPermisosAddMoneda, "2001") ?
       <>
       <Grid item lg={8} md={8} sm={8} xs={12} >
       <MonedaDialog/>
      </Grid>
        </>
        :
        <></>
      }
        { equals(this.state.idPermisosListMoneda, "2003") ? 
        <>
   
    <Grid item lg={4} md={4} sm={4} xs={12} style={{width: "100%", justifyContent: 'end', alignContent: 'flex-end', alignItems: 'flex-end'}}>
    <Button color="primary" onClick={() => this.handleChangeData(true)} >
          <ReorderIcon style={{ fontSize: "40px" }} /> <p style={{ fontSize: "15px" }}>{this.state.varData ? "Volver a listar Moneda" : "Listar Moneda"}</p>
      </Button>
        </Grid>
        </>
        :
        <></>
    }
     </Grid>
      <Dialog disableEnforceFocus
      onKeyPress={this.onKeyUp2}
          open={this.state.checkedDelete} 
          onClose={!this.state.checkedDelete}
          aria-labelledby="form-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Eliminar Moneda
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           ¿Está seguro que quiere eliminar esta Moneda?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button variant="contained" onClick={() => this.handleChangeDelete(false, false)} color="primary">
                      No
          </Button>
          <Button variant="contained" color="secondary" onClick={() => this.handleChangeDelete(false, true, this.state.id)}>Si</Button>
        </DialogActions>
        </Dialog>
      <div className="list row">

      { equals(this.state.idPermisosListMoneda, "2003") ?
      <Paper style={{justifyContent: 'center', width: "100%", padding: '10px'}}>
 
      <Grid container >
   
   <Grid item lg={5} md={5} sm={5} xs={12}  style={{margin: '10px'}}>
   
     </Grid>
     <Grid item lg={3} md={3} sm={3} xs={12}  style={{margin: '10px'}}>
 
 </Grid>
 </Grid>

<MaterialTable
                 title="Listado de Monedas"
                 columns={ [
                  { title: 'Código', field: 'idMoneda' },
                  { title: 'Simbolo', field: 'simbolo' },
                  { title: 'Descripción', field: 'descripcion' }
                 ]
               
              }
                 
                 data={monedas.data && monedas.data.map(monedas =>(monedas))}
                         
                 options={{
                   filtering: this.state.checkedFilter,
                   exportButton: true,
                   exportAllData: true,
                   actionsColumnIndex: -1,
                   pageSize: 10,
                   pageSizeOptions: [10, 50, 100]
                 }}
                 
                 actions={ this.state.idPermisosEliminarMoneda !="2004" && this.state.idPermisosEditMoneda !="2002" ?
                 []
                 :[

                  rowData => (
                    {
                      icon: VisibilityIcon,
                      tooltip: 'Detalle Moneda',
                      onClick:((event, rowData) => this.handleChangeAdd(true, false, rowData.idMoneda )),
                      hidden: this.state.idPermisosEditMoneda !="2002"
                  }),
                        
                    rowData =>({
                      icon: DeleteIcon,
                      tooltip: 'Eliminar Moneda',
                      onClick: ((event, rowData)  => this.handleChangeDelete(true, false, rowData.idMoneda)),
                      hidden: this.state.idPermisosEliminarMoneda !="2004"
                    }
                  )
                  
                   
                  ]}

                  components={{
                    Toolbar: props => (
                      <div>
                        <MTableToolbar {...props} />
                        <Chip icon={this.state.checkedFilter? <FilterAltOffIcon /> : <FilterAltIcon />} label="Filtrar" color="secondary" onClick={() => this.handleChangeFilter(!this.state.checkedFilter)} style={{marginLeft: 20, marginTop: 10}}/>
                      </div>
                    )
                  }}
               />
              
               
                </Paper> 
                 :
                 <></>
  
                 }   
      </div>
      <Dialog disableEnforceFocus
        onKeyPress={this.onKeyUp}
        open={this.state.checkedAdd} 
        onClose={!this.state.checkedAdd}
        aria-labelledby="form-dialog-title"
      >
        
        <DialogActions style={{paddingLeft: 10, paddingTop:10}}>
              <Grid container >
              <Grid item lg={10} md={10} sm={10} xs={10}  style={{alignContent: "flex-start", alignItems: "flex-start", textAlign: "flex-start"}}>
              <DialogTitle id="form-dialog-title">Detalle Moneda</DialogTitle>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Button variant="contained" onClick={() => this.handleChangeAdd(false, false)} color="secondary">
                  <HighlightOff  style={{ fontSize: "30px" }} /> 
                  </Button>
                  </Grid>
                  </Grid>
              </DialogActions>

            <DialogContent style={{padding: "30px 30px 10px 30px"}}>
	
            <Grid container >
            <div style={{width: "100%", justifyContent: 'center'}}>
            <Paper style={{justifyContent: 'center', padding: '10px'}}>

	
                    
              <div style={{justifyContent: 'center',
              '& .MuiTextField-root': {
                  margin: '1px'
              },display: 'flex',
              flexWrap: 'wrap'}}
                  component={Paper} >

<Grid item lg={12} md={12} sm={12} xs={12} style={{margin: '10px'}}>

<FormControlLabel style={{justifyContent: 'start'}} control={<Checkbox checked={this.state.togleedit} disabled={this.state.togleedit ? true: false}
         onChange={this.toggleChange2} />} label="Editar" />

</Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Código"
                disabled={this.state.togleedit ? false : true}
                placeholder={this.state.Pidmoneda}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeIdMoneda}
                value={this.state.idmoneda}
                
                />
                
                 </Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                placeholder={this.state.Pdescripcion}
                disabled={this.state.togleedit ? false : true}
                name="Vfechareg"
                label="Descripción"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeDescripcion}
                value={this.state.descripcion}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Simbolo"
                disabled={this.state.togleedit ? false : true}
                placeholder={this.state.Psimbolo}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeSimbolo}
                value={this.state.simbolo}
                />
                 </Grid>
              </div>
              </Paper>
              </div>
              </Grid>
            </DialogContent>
            

            
              <DialogActions style={{padding: 30}}>
              <LoadingButton
                size="small"
                onClick={() => this.handleChangeAdd(false, true, this.state.id)}
                color="secondary"
                loading={this.state.loading}
                loadingPosition="end"
                variant="contained"
                
              >
                Actualizar
          </LoadingButton >
            </DialogActions>

            

           {/* <MonedaForm Datasimbolo={this.state.Psimbolo} Datadescripcion={this.state.Pdescripcion} DataidMoneda={this.state.Pidmoneda}/>
            */}

              </Dialog> 
			</div>
	</React.Fragment>
    );
  }
  
}

const mapStateToProps = (state) => {
  return {
    monedas: state.monedas,
    monedasbyid: state.monedasbyid,
    permisosbyid: state.permisosbyid
  };
};

export default connect(mapStateToProps, {
 retrieveMoneda, deleteMoneda, updateMoneda, findMonedasById, findPermisosById
})(MonedaList);

