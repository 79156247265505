import axios from "axios";


export const http = axios.create({
 //baseURL: 'http://localhost:4000',
  baseURL: 'https://pataemingo-backend.back.ntcomunicaciones.net',
    headers: {
      "Content-type": "application/json" 
    }
  });  
  
export default {
  auth() {
    return {
        login: ({username, contraseña}) => http.post( '/api/private/auth/authenticate', {username, contraseña})
        //register: ({email, name, password}) => http.post('/register', {email, name, password})
    }
  }
}
