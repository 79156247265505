
import React, { Component } from "react";
import { connect } from "react-redux";


import MaterialTable,{ MTableToolbar }  from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import jwt from 'jwt-decode'
import {Button, Chip, Icon} from '@material-ui/core';
import {Grid, Divider, Paper} from "@material-ui/core";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from '@mui/material/DialogContentText';
import { Checkbox } from "@mui/material";
import {TextField} from "@material-ui/core";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import HighlightOff from '@material-ui/icons/HighlightOff';
import {
  retrieveTipoUsuario, deleteTipoUsuario, updateTipoUsuario, findTipoUsuariosById
} from "../../actions/tipo_usuario.actions";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tipo_usuarioDialog from "../Dialog/tipo_usuario.dialog";

class TipoUsuarioList extends Component {
  constructor(props) {
    super(props);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyUp2 = this.onKeyUp.bind(this);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleChangeDelete = this.handleChangeDelete.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeTipo_Usuario = this.onChangeTipo_Usuario.bind(this);
     
    this.state = {
        checkedAdd: true ? false : true,
        checkedEdit: true ? false : true,
        checkedFilter: true ? false : true,
        checkedDelete: false,
        deleted : false,
        tipo_usuario: "",
        id: "",
        togleedit: false,
        nombre: "",
        Pnombre: "",
    }

  }

  componentDidMount() {
    this.props.retrieveTipoUsuario();
  }

  handleChangeFilter(checkedFilter){
    this.setState({ 
        checkedFilter
      });
  }

  toggleChange2 = () => {
    this.setState({
      togleedit: !this.state.togleedit,
    });
  
  }
  onChangeTipo_Usuario(e) {
    const tipo_usuario = e.target.value;
    
    this.setState({
      tipo_usuario: tipo_usuario,
    });
  }

  onChangeName(e) {
    const nombre = e.target.value.toUpperCase();
    
    this.setState({
      nombre: nombre,
    });
  }

  onKeyUp(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.handleChangeAdd(false, true, this.state.id);
    }
  }

  onKeyUp2(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.handleChangeDelete(false, true, this.state.id);
    }
  }

  handleChangeAdd(checkedAdd, updated, id){
    this.state.id = id
    this.props.findTipoUsuariosById(id);
      //console.log("id2: ", id)
      //this.handleChangeEdit(this.state.id)

      if(checkedAdd== true && updated == false){
        this.setState({ 
          checkedAdd,
        });
        //console.log("ABIERTO")
      }
      if(checkedAdd== false && updated == false){
        this.setState({ 
          checkedAdd,
          togleedit: false
        });
        //console.log("CERRADO")
      }
  
      if(checkedAdd== false && updated == true){
       /* this.setState({ 
          checkedDelete,
        });
        console.log("ELIMINADO ID", id)
      
      }*/
      if(this.state.nombre == null){
        this.setState({ 
          nombre: this.state.Pnombre
        });
      }

      var dataUp = { 
        id: this.state.id,
        nombre: this.state.nombre,
      };
    
      this.props
     .updateTipoUsuario(id, dataUp)
     .then((data) => {
      this.setState({
        nombre: data.nombre
     // this.props.history.push("/tutorials");
      })
    })
    .catch((e) => {
      console.log(e);
    });	 
      
      
  }
}

  handleChangeDelete(checkedDelete, deleted, id){


    this.state.id = id
    if(checkedDelete== true && deleted == false){
      this.setState({ 
        checkedDelete,
      });
      //console.log("ABIERTO")
    }
    if(checkedDelete== false && deleted == false){
      this.setState({ 
        checkedDelete,
      });
      //console.log("CERRADO")
    }

    if(checkedDelete== false && deleted == true){
     /* this.setState({ 
        checkedDelete,
      });
      console.log("ELIMINADO ID", id)
    }*/
    this.props
   .deleteTipoUsuario(id)
   .then(() => {
   // this.props.history.push("/tutorials");
  })
  .catch((e) => {
    console.log(e);
  });	 
   
 }
}
  


  render() {
    const { tipo_usuarios} = this.props;
    const { tipo_usuariosbyId} = this.props;

    if(!this.state.togleedit){
    this.state.nombre = `${tipo_usuariosbyId.nombre}`
    }
    this.state.Pnombre = `${tipo_usuariosbyId.nombre}`

    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
    if (!tken) return false
   const decoded = jwt(tken); 
    return (
        <React.Fragment >
         
			<div style={{width: "100%", justifyContent: 'center'}}>
        <Tipo_usuarioDialog/>
      <Dialog disableEnforceFocus
      onKeyPress={this.onKeyUp2}
          open={this.state.checkedDelete} 
          onClose={!this.state.checkedDelete}
          aria-labelledby="form-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Eliminar Tipo Usuarios
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           ¿Está seguro que quiere eliminar este Tipo Usuario?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button variant="contained" onClick={() => this.handleChangeDelete(false, false)} color="primary">
                      No
          </Button>
          <Button variant="contained" color="secondary" onClick={() => this.handleChangeDelete(false, true, this.state.id)}>Si</Button>
        </DialogActions>
        </Dialog>
      <div className="list row">
      <Paper style={{justifyContent: 'center', width: "100%", padding: '10px'}}>
 
      <Grid container >
   
   <Grid item lg={5} md={5} sm={5} xs={12}  style={{margin: '10px'}}>
   
     </Grid>
     <Grid item lg={3} md={3} sm={3} xs={12}  style={{margin: '10px'}}>
 
 </Grid>
 </Grid>

                 <MaterialTable
                 title="Listado de Tipo Usuarios"
                 columns={ [
                  { title: 'Nombres', field: 'nombre' }
                 ]
               
              }
                 
                 data={tipo_usuarios && tipo_usuarios.map(tipo_usuarios =>(tipo_usuarios))}
                         
                 options={{
                   filtering: this.state.checkedFilter,
                   exportButton: true,
                   exportAllData: true,
                   actionsColumnIndex: -1,
                   pageSize: 10,
                   pageSizeOptions: [10, 50, 100]
                 }}
                 actions={[
                 rowData => (
                    {
                      icon: VisibilityIcon,
                      tooltip: 'Detalle Tipo Usuarios',
                      onClick: ((event, rowData)  => this.handleChangeAdd(true, false, rowData.idTipoUsuarios)),
                    }),
                    rowData =>({
                      icon: DeleteIcon,
                      tooltip: 'Eliminar Tipo Usuarios',
                      onClick: ((event, rowData)  => this.handleChangeDelete(true, false, rowData.idTipoUsuarios)),
                    }
                  )
                  
                  ]}

                  components={{
                    Toolbar: props => (
                      <div>
                        <MTableToolbar {...props} />
                        <Chip icon={this.state.checkedFilter? <FilterAltOffIcon /> : <FilterAltIcon />} label="Filtrar" color="secondary" onClick={() => this.handleChangeFilter(!this.state.checkedFilter)} style={{marginLeft: 20, marginTop: 10}}/>
                      </div>
                    )
                  }}
               />
               
                </Paper>    
      </div>
      <Dialog disableEnforceFocus
      onKeyPress={this.onKeyUp}
      open={this.state.checkedAdd} 
      onClose={!this.state.checkedAdd}
      aria-labelledby="form-dialog-title"
      >

<DialogActions style={{paddingLeft: 10, paddingTop:10}}>
              <Grid container >
              <Grid item lg={10} md={10} sm={10} xs={10}  style={{alignContent: "flex-start", alignItems: "flex-start", textAlign: "flex-start"}}>
              <DialogTitle id="form-dialog-title">Detalle Tipo Usuario</DialogTitle>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Button variant="contained" onClick={() => this.handleChangeAdd(false, false)} color="secondary">
                  <HighlightOff  style={{ fontSize: "30px" }} /> 
                  </Button>
                  </Grid>
                  </Grid>
              </DialogActions>

    <DialogContent style={{padding: "30px 30px 10px 30px"}}>

    <Grid container >
    <div style={{width: "100%", justifyContent: 'center'}}>
    <Paper style={{justifyContent: 'center', padding: '10px'}}>


            
      <div style={{justifyContent: 'center',
      '& .MuiTextField-root': {
          margin: '1px'
      },display: 'flex',
      flexWrap: 'wrap'}}
          component={Paper} >


<Grid item lg={12} md={12} sm={12} xs={12} style={{margin: '10px'}}>

<FormControlLabel style={{justifyContent: 'start'}} control={<Checkbox checked={this.state.togleedit} disabled={this.state.togleedit ? true: false}
         onChange={this.toggleChange2} />} label="Editar" />

</Grid>
<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
        <TextField
        placeholder={this.state.Pnombre}
        name="Vname"
        label="Nombre"
        disabled={this.state.togleedit ? false : true}
        variant= "outlined"
        fullWidth
        autoComplete="off"
        onChange={this.onChangeName}
        value={this.state.nombre}
        
        />
        
        
         </Grid>

        
      </div>
      </Paper>
      </div>
      </Grid>
            </DialogContent>

      <DialogActions style={{padding: 30}}>
     
          
      <LoadingButton
        size="small"
        onClick={() => this.handleChangeAdd(false, true, this.state.id)}
        color="secondary"
        loading={this.state.loading}
        loadingPosition="end"
        variant="contained"
      >
         Actualizar
      </LoadingButton >
      </DialogActions>

      </Dialog>
      </div>
	</React.Fragment>
    );
  }
  
}

const mapStateToProps = (state) => {
  return {
    tipo_usuarios: state.tipo_usuarios,
    tipo_usuariosbyId: state.tipo_usuariosbyId
  };
};

export default connect(mapStateToProps, {
 retrieveTipoUsuario, deleteTipoUsuario, updateTipoUsuario, findTipoUsuariosById
})(TipoUsuarioList);

