import {http} from "../utils/api";

const config = {
  headers: {
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }
};

class PermisosDataService {  

  getAllData() {
    return http.get("/api/private/permisos", config);
  } 

  get(id) {
    return http.get(`/api/private/permisos/${id}`, config);
  }

  create(data) {
    return http.post("/api/private/permisos/register", data, config);
  }

  update(id, data) {
    return http.put(`/api/private/permisos/${id}`, data, config);
  }

  delete(id) {
    return http.delete(`/api/private/permisos/${id}`,config);
  }

  delete2(id, code) {
    return http.delete(`/api/private/permisos/delete/${id}/${code}`,config);
  }
}

export default new PermisosDataService();