import {
  CREATE_PAIS,
  RETRIEVE_PAISS,
  UPDATE_PAIS,
  DELETE_PAIS,
} from "../constants/pais.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];



function paisReducer(paiss = initialState, action) {
  const { type, payload } = action;

  function Refreshdata(){
    window.location.reload(true);
  }

  switch (type) {
    case CREATE_PAIS:
      toast.success('Pais Registrado de manera Éxitosa');
      window.location.reload(true);
     //setInterval(Refreshdata, 1000)
      return [...paiss, payload];

    case RETRIEVE_PAISS: 
      return payload;

    case UPDATE_PAIS:
      toast.success('Pais Actualizado de manera Éxitosa');
      window.location.reload(true);
      //setInterval(Refreshdata, 1000)
      return {...paiss, payload};

    case DELETE_PAIS:
      
      //setInterval(Refreshdata, 100)

      toast.success('Pais Eliminado de manera Éxitosa');
      window.location.reload(true);
      return paiss.filter(({ id }) => id !== payload.id);

    default:
      return paiss;
  }
};



export default paisReducer;