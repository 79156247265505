import {
  PAQUETES_BY_ID,
} from "../constants/paquete.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];



function paquete2Reducer(paquetes2 = initialState, action) {
  const { type, payload } = action;

  function Refreshdata(){
    window.location.reload(true);
  }

  switch (type) {
   
    case PAQUETES_BY_ID: 
      return payload;

    
    default:
      return paquetes2;
  }
};



export default paquete2Reducer;