import {http} from "../utils/api";

const config = {
  headers: {
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }
};

class PaqueteDataService { 

  getAllData() {
    return http.get("/api/private/paquetes", config);
  }

  get(id) {
    return http.get(`/api/private/paquetes/${id}`, config);
  }

  create(data) {
    return http.post("/api/private/paquetes/register", data, config);
  }

  update(id, data) { 
    return http.put(`/api/private/paquetes/${id}`, data, config);
  }

  delete(id) {
    return http.delete(`/api/private/paquetes/${id}`,config);
  }

  
}

export default new PaqueteDataService();