import React from "react";
import API from "../utils/api"
import jwt from 'jwt-decode'
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

let AuthContext;
const { Provider, Consumer } = (AuthContext = React.createContext());

class AuthProvider extends React.PureComponent {

    state = { 
        token : null,
        errorMsg : null
    };

    isAuthenticated = () => {
      const tken = this.state.token ? this.state.token : localStorage.getItem("token")
      if (!tken) return false

      const decoded = jwt(tken); 
       //console.log("token", decoded)

      if (Date.now() / 1000 > decoded.exp - 5) {
        localStorage.clear();
        toast.error('La sesión ha caducado, vuelva a iniciar sesión');
        return false
      }
      return true
    }

    setLogin = (data) => {
      if (data) {
        localStorage.setItem('token', data.token)
        
        this.setState({
          token : data.token,
         
        })
      }
      else {
        this.setState({
          token : null,
        })
        localStorage.removeItem("token");
       
      }
    }
    
    login = (username, contraseña, history, setIsLoading, setErrorMessage) => {
      setIsLoading(true);
      this.setLogin(null)
 
      if (!!username && !!contraseña) {
        API.auth().login({ username, contraseña})
        .then(res => {
            // console.log("res : ", JSON.stringify(res.data))
            
            if (res.status === 200 && res.data) {
              this.setLogin(res.data)
              setIsLoading(false)
              setErrorMessage(null)
             // window.location.replace('/#/admin/dashboard');
             window.location = '/';
              //history.push('/admin/dashboard')
              return true
            }
            else {
              setIsLoading(false);
              setErrorMessage( res.data.message)
              return false
            }
        })
        .catch(err => {
            setIsLoading(false);

            console.log(err)
            if (err.response) 
              setErrorMessage(err.response.data.message)
            else {
              setErrorMessage(err)
            }
              
            return false
        });
      } else {
        setIsLoading(false);
        setErrorMessage("email and password is empty")
        return false
      }
    }
    
    register = (email, name, password, history, setIsLoading, setErrorMessage) => {
      setIsLoading(true);
    
      if (!!email && !!name && !!password) {
        API.auth().register({ email, name, password })
            .then(res => {
              // console.log(res)
              setErrorMessage(null)
              setIsLoading(false);
              history.push('/admin/login')
            })
            .catch(err => {
              console.log(err.response.data)
              setErrorMessage(err.response.data.error)
              setIsLoading(false);
            });
      } else {
        setErrorMessage("All field is required")
        setIsLoading(false);
      }
    }

    logout = (history) => {
      this.setState({  
        token : null,
      });
      localStorage.removeItem("token");
     
      history.push("/login");
     // window.location.replace('/login');
    }

    render() {
      return (
        <Provider
          value={{
              ...this.state,
              
              login : this.login,
              register : this.register,
              logout : this.logout,
              isAuthenticated : this.isAuthenticated,
              isATC : this.isATC,
              isSuperAdmin: this.isSuperAdmin
          }}
        >
          {this.props.children}
        </Provider>
      );
    }
}

export { AuthProvider, Consumer, AuthContext };
