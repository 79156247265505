
import React, { Component } from "react";
import { connect } from "react-redux";


import MaterialTable,{ MTableToolbar }  from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import jwt from 'jwt-decode'
import {Button, Chip, Icon} from '@material-ui/core';
import {Grid, Divider, Paper} from "@material-ui/core";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle"; 
import DialogContentText from '@mui/material/DialogContentText';
import {TextField} from "@material-ui/core";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { Checkbox, FormControlLabel } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReorderIcon from '@mui/icons-material/Reorder';
import {
  retrieveAppupdate, deleteAppupdate, updateAppupdate, findAppupdateById
} from "../../actions/appupdate.actions";

import {
  findPermisosById
} from "../../actions/permisos.actions";
import { format } from 'date-fns'
import {isEmail, isEmpty,isLength, equals} from 'validator';

import AppupdateDialog from "../Dialog/appupdate.dialog";

class AppupdateList extends Component {
  constructor(props) {
    super(props);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleChangeDelete = this.handleChangeDelete.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyUp2 = this.onKeyUp.bind(this);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.onChangeNombre = this.onChangeNombre.bind(this);
    this.onChangeVersion = this.onChangeVersion.bind(this);
    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onChangeSO = this.onChangeSO.bind(this);
    this.onChangeFechaPublish = this.onChangeFechaPublish.bind(this);
    this.handleChangeData = this.handleChangeData.bind(this)
    this.state = {
        checkedAdd: true ? false : true,
        checkedEdit: true ? false : true,
        checkedFilter: true ? false : true,
        checkedDelete: false,
        deleted : false,
        updated: false,
        appupdate: "",

        idUser: "",

        id: "",
        idAppUpdate: "",
        Nombre: "",
        version: "",
        Url: "",
        FechaPublish: "",
        SO: "",
        togleedit: false,

        
        loading: false,
        varData: false,

        /* ------------------------*/
       

        idPermisosAddAppupdate: "",
        idPermisosEditAppupdate: "",
        idPermisosEliminarAppupdate: "",
        idPermisosListAppupdate: "",

       
    }

  }

  componentDidMount() {

   
  
    
    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
    if (!tken) return false
    const decoded = jwt(tken); 
    /*this.setState({ 
      idUser: decoded.idUsuarios
    });
    */
    //console.log("idUsuarios", decoded)
    this.props.findPermisosById(decoded.idUsuarios)
  }

  componentDidUpdate(prevProps, prevState) {
    //let newName = 'Obaseki Nosa'

    const { permisosbyid} = this.props 
    // Don't forget to compare states
    
    if (prevState && prevState.datapermisosbyid !== permisosbyid) {
      permisosbyid.data && permisosbyid.data.map (permisos => {
      //console.log("permisosbyid", permisosbyid.code)
      if(permisos.code == "6003"){
        this.state.idPermisosListAppupdate = permisos.code;
    }
  })
   
  }


  }
  handleChangeData(varData) {
    this.setState({ 
      varData
    });
    if(this.state.idPermisosListAppupdate == "6003"){
      this.props.retrieveAppupdate();
    }
  }

  handleChangeFilter(checkedFilter){
    this.setState({ 
        checkedFilter
      });
  }

  toggleChange2 = () => {
    this.setState({
      togleedit: !this.state.togleedit,
    });
  
  }

  onKeyUp(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.handleChangeAdd(false, true, this.state.id);
    }
  }

  onKeyUp2(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.handleChangeDelete(false, true, this.state.id);
    }
  }

  onChangeNombre(e) {
    const Nombre = e.target.value;
    
    this.setState({
      Nombre: Nombre,
    });
  }

  onChangeVersion(e) {
    const version = e.target.value.toUpperCase();
    
    this.setState({
      version: version,
    });
  }
  onChangeUrl(e) {
    const Url = e.target.value;
    
    this.setState({
      Url: Url,
    });
  }

  onChangeFechaPublish(e) {
    const FechaPublish = e.target.value;
    
    this.setState({
      FechaPublish: FechaPublish,
    });
  }

  onChangeSO(e) {
    const SO = e.target.value.toUpperCase();
    
    this.setState({
      SO: SO,
    });
  }
  


  handleChangeAdd(checkedAdd, updated, idAppUpdate){
    this.state.idAppUpdate = idAppUpdate
    this.props.findAppupdateById(idAppUpdate);
      //console.log("id2: ", id)
      //this.handleChangeEdit(this.state.id)

      if(checkedAdd== true && updated == false){
        this.setState({ 
          checkedAdd,
        });
        //console.log("ABIERTO")
      }
      if(checkedAdd== false && updated == false){
        this.setState({ 
          checkedAdd,
          togleedit: false
        });
        //console.log("CERRADO")
      }
  
      if(checkedAdd== false && updated == true){
       /* this.setState({ 
          checkedDelete,
        });
        console.log("ELIMINADO ID", id)
      
      }*/
    

      var dataUp = { 
        Nombre: this.state.Nombre,
        version: this.state.version,
        Url: this.state.Url,
        FechaPublish: this.state.FechaPublish,
        SO: this.state.SO
      };
    

      this.props
     .updateAppupdate(this.state.idAppUpdate, dataUp)
     .then((data) => {
      this.setState({
        Nombre: data.Nombre,
        version: data.version,
        Url: data.Url,
        FechaPublish: data.FechaPublish,
        SO: data.SO
     // this.props.history.push("/tutorials");
      })
    })
    .catch((e) => {
      console.log(e);
    });	 
      
      
  }
}

  handleChangeDelete(checkedDelete, deleted, id){


    this.state.idAppUpdate = id
    if(checkedDelete== true && deleted == false){
      this.setState({ 
        checkedDelete,
      });
      //console.log("ABIERTO")
    }
    if(checkedDelete== false && deleted == false){
      this.setState({ 
        checkedDelete,
      });
      //console.log("CERRADO")
    }

    if(checkedDelete== false && deleted == true){
     /* this.setState({ 
        checkedDelete,
      });
      console.log("ELIMINADO ID", id)
    }*/
    this.props
   .deleteAppupdate(id)
   .then(() => {
   // this.props.history.push("/tutorials");
  })
  .catch((e) => {
    console.log(e);
  });	 
   
 }
}
  


  render() {
    const { appupdates} = this.props;
    const { appupdatesbyid} = this.props;
    const {permisosbyid} = this.props;

    console.log("AppUpdate", appupdatesbyid)

    if(!this.state.togleedit){
      appupdatesbyid.data && appupdatesbyid.data.map (Appupdate => {
    this.state.Nombre = `${Appupdate.Nombre}`
    this.state.version= `${Appupdate.version}`
    this.state.Url= `${Appupdate.Url}`
    var mydate = new Date(`${Appupdate.FechaPublish}`);
    this.state.FechaPublish= format(mydate, 'yyyy-MM-dd')
    this.state.SO= `${Appupdate.SO}`
      })
    }
  
    permisosbyid.data && permisosbyid.data.map (permisos => {
      // console.log("permisos", permisos);
       
        //if(permisos.code= '1003') console.log("permisos list", permisos.code);
       
        if(permisos.code == '6003') {this.state.idPermisosListAppupdate = "6003"}
        if(permisos.code == "6001") {this.state.idPermisosAddAppupdate = "6001"}
        if(permisos.code == "6002") {this.state.idPermisosEditAppupdate = "6002"}
        if(permisos.code == "6004") {this.state.idPermisosEliminarAppupdate = "6004"}

        //if(permisos.code= '1001') console.log("permisos add", permisos.code);
        //if(permisos.code= '1002') console.log("permisos edit", permisos.code);
        //if(permisos.code= '1003') console.log("permisos list", permisos.code);
        //if(permisos.code= '1004') console.log("permisos deleted", permisos.code);
       
      })
    return (
        <React.Fragment >
         
			<div style={{width: "100%", justifyContent: 'center'}}>
      <Grid container >
      { equals(this.state.idPermisosAddAppupdate, "6001") ?
       <>
       <Grid item lg={8} md={8} sm={8} xs={12} >
     <AppupdateDialog/>
      </Grid>
        </>
        :
        <></>
      }
      { equals(this.state.idPermisosListAppupdate, "6003") ? 
        <>
   
    <Grid item lg={4} md={4} sm={4} xs={12} style={{width: "100%", justifyContent: 'end', alignContent: 'flex-end', alignItems: 'flex-end'}}>
    <Button color="primary" onClick={() => this.handleChangeData(true)} >
          <ReorderIcon style={{ fontSize: "40px" }} /> <p style={{ fontSize: "15px" }}>{this.state.varData ? "Volver a listar Versiones" : "Listar Versiones"}</p>
      </Button>
        </Grid>
        </>
        :
        <></>
    }
    </Grid>
      <Dialog disableEnforceFocus
      onKeyPress={this.onKeyUp2}
          open={this.state.checkedDelete} 
          onClose={!this.state.checkedDelete}
          aria-labelledby="form-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Eliminar Versión de App
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           ¿Está seguro que quiere eliminar esta Versión de App?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button variant="contained" onClick={() => this.handleChangeDelete(false, false)} color="primary">
                      No
          </Button>
          <Button variant="contained" color="secondary" onClick={() => this.handleChangeDelete(false, true, this.state.idAppUpdate)}>Si</Button>
        </DialogActions>
        </Dialog>
      <div className="list row">
      { equals(this.state.idPermisosListAppupdate, "6003") ?
      <Paper style={{justifyContent: 'center', width: "100%", padding: '10px'}}>
 
      <Grid container >
   <Grid item lg={5} md={5} sm={5} xs={12}  style={{margin: '10px'}}></Grid>
    <Grid item lg={3} md={3} sm={3} xs={12}  style={{margin: '10px'}}></Grid>
 </Grid>
                 <MaterialTable
                 title="Listado de Actualizaciones"
                 columns={ [
                  { title: 'Nombre', field: 'Nombre' },
                  { title: 'Versión', field: 'version' },
                  { title: 'Url', field: 'Url' },
                  { title: 'FechaPublish', field: 'FechaPublish' },
                  { title: 'SO', field: 'SO' },
                 
                 ]
               
              }
                 
                 data={appupdates.data && appupdates.data.map(appupdates =>(appupdates))}
                         
                 options={{
                   filtering: this.state.checkedFilter,
                   exportButton: true,
                   exportAllData: true,
                   actionsColumnIndex: -1,
                   pageSize: 10,
                   pageSizeOptions: [10, 50, 100]
                 }}
                 actions={
                  this.state.idPermisosEliminarAppupdate !="6004" && this.state.idPermisosEditAppupdate !="6002" ?
                  []
                  :[
                  rowData => (
                    {
                      icon: VisibilityIcon,
                      tooltip: 'Detalle Appupdate',
                      onClick:((event, rowData) => this.handleChangeAdd(true, false, rowData.idAppUpdate )),
                      hidden: this.state.idPermisosEditAppupdate !="6002"
                    }),
                    rowData =>({
                      icon: DeleteIcon,
                      tooltip: 'Eliminar Appupdate',
                      onClick: ((event, rowData)  => this.handleChangeDelete(true, false, rowData.idAppUpdate)),
                      hidden: this.state.idPermisosEliminarAppupdate !="6004"
                    }
                  )
                  
                  ]}

                  components={{
                    Toolbar: props => (
                      <div>
                        <MTableToolbar {...props} />
                        <Chip icon={this.state.checkedFilter? <FilterAltOffIcon /> : <FilterAltIcon />} label="Filtrar" color="secondary" onClick={() => this.handleChangeFilter(!this.state.checkedFilter)} style={{marginLeft: 20, marginTop: 10}}/>
                      </div>
                    )
                  }}
               />
              
               
                </Paper>  
                :
                <></>
                 }
                
                  
      </div>
     
      <Dialog disableEnforceFocus

        open={this.state.checkedAdd} 
        onKeyPress={this.onKeyUp}
        onClose={!this.state.checkedAdd} 
        aria-labelledby="form-dialog-title"
      >
        
        <DialogActions style={{paddingLeft: 10, paddingTop:10}}>
              <Grid container >
              <Grid item lg={10} md={10} sm={10} xs={10}  style={{alignContent: "flex-start", alignItems: "flex-start", textAlign: "flex-start"}}>
              <DialogTitle id="form-dialog-title">Detalle Versión</DialogTitle>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Button variant="contained" onClick={() => this.handleChangeAdd(false, false)} color="secondary">
                  <HighlightOff  style={{ fontSize: "30px" }} /> 
                  </Button>
                  </Grid>
                  </Grid>
              </DialogActions>

            <DialogContent style={{padding: "30px 30px 10px 30px"}}>
	
            <Grid container >
            <div style={{width: "100%", justifyContent: 'center'}}>
            <Paper style={{justifyContent: 'center', padding: '10px'}}>

	
                    
              <div style={{justifyContent: 'center',
              '& .MuiTextField-root': {
                  margin: '1px'
              },display: 'flex',
              flexWrap: 'wrap'}}
                  component={Paper} >

<Grid item lg={12} md={12} sm={12} xs={12} style={{margin: '10px'}}>

<FormControlLabel style={{justifyContent: 'start'}} control={<Checkbox checked={this.state.togleedit} disabled={this.state.togleedit ? true: false}
         onChange={this.toggleChange2} />} label="Editar" />

</Grid>
<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="VNombre"
                label="Nombre"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeNombre}
                value={this.state.Nombre}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="VVersion"
                label="Versión"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeVersion}
                value={this.state.version}
                
                />
                 </Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="VURL"
                label="URL"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeUrl}
                value={this.state.Url}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="VFechaPublish"
                label="Fecha de Publicación"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                fullWidth
                type={"date"}
                autoComplete="off"
                onChange={this.onChangeFechaPublish}
                value={this.state.FechaPublish}
                
                />
                 </Grid>




                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="VSO"
                disabled={this.state.togleedit ? false : true}
                label="Sistema Operativo"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeSO}
                value={this.state.SO}
                
                />
                 </Grid>
              </div>
              </Paper>
              </div>
              </Grid>
                    </DialogContent>

              <DialogActions style={{padding: 30}}>
                
                  
              <LoadingButton
                size="small"
                onClick={() => this.handleChangeAdd(false, true, this.state.idAppUpdate)}
                color="secondary"
                loading={this.state.loading}
                loadingPosition="end"
                variant="contained"
              >
                 Actualizar
              </LoadingButton >
              </DialogActions>

              </Dialog>
			</div>
	</React.Fragment>
    );
  }
  
} 

const mapStateToProps = (state) => {
  return {
    appupdates: state.appupdates,
    appupdatesbyid: state.appupdatesbyid,
    permisosbyid: state.permisosbyid
  };
};

export default connect(mapStateToProps, {
 retrieveAppupdate, deleteAppupdate, updateAppupdate, findAppupdateById,findPermisosById
})(AppupdateList);

