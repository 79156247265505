import {
  PAISS_BY_ID
} from "../constants/pais.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];


function pais2Reducer(paissbyid = initialState, action) {
  const { type, payload } = action;


  switch (type) {
    

    case PAISS_BY_ID: 
      return payload;

    default:
      return paissbyid;
  }
};



export default pais2Reducer;