import {
  CREATE_APPUPDATE,
  RETRIEVE_APPUPDATES,
  UPDATE_APPUPDATE,
  DELETE_APPUPDATE,
} from "../constants/appupdate.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];


function appupdateReducer(appupdates = initialState, action) {
  const { type, payload } = action;

  function Refreshdata(){
    window.location.reload(true);
  }

  switch (type) {
    case CREATE_APPUPDATE:
      toast.success('App Update Registrado de manera Éxitosa');
      window.location.reload(true);
     //setInterval(Refreshdata, 1000)
      return [...appupdates, payload];

    case RETRIEVE_APPUPDATES: 
      return payload;

    case UPDATE_APPUPDATE:
      toast.success('App Update Actualizado de manera Éxitosa');
      window.location.reload(true);
      //setInterval(Refreshdata, 1000)
      return {...appupdates, payload};

    case DELETE_APPUPDATE:
      
      //setInterval(Refreshdata, 100)

      toast.success('App Update Eliminado de manera Éxitosa');
      window.location.reload(true);
      return appupdates.filter(({ id }) => id !== payload.id);

    default:
      return appupdates;
  }
};



export default appupdateReducer;