import {
  CREATE_USUARIO,
  RETRIEVE_USUARIOS,
  UPDATE_USUARIO,
  DELETE_USUARIO,
} from "../constants/usuario.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];



function usuarioReducer(usuarios = initialState, action) {
  const { type, payload } = action;

  function Refreshdata(){
    window.location.reload(true);
  }

  switch (type) {
    case CREATE_USUARIO:
      toast.success('Usuario Registrada de manera Éxitosa');
      //window.location.reload(true);
     //setInterval(Refreshdata, 1000)
      return [...usuarios, payload];

    case RETRIEVE_USUARIOS: 
      return payload;

    case UPDATE_USUARIO:
      toast.success('Usuario Actualizado de manera Éxitosa');
      //window.location.reload(true);
      //setInterval(Refreshdata, 1000)
      return {...usuarios, payload};

    case DELETE_USUARIO:
      toast.success('Usuario Eliminada de manera Éxitosa');
     // window.location.reload(true);
      //setInterval(Refreshdata, 100)
      return usuarios.filter(({ id }) => id !== payload.id);

    default:
      return usuarios;
  }
};



export default usuarioReducer;