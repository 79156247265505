
import React, { Component } from "react";
import { connect } from "react-redux";


import MaterialTable,{ MTableToolbar }  from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import jwt from 'jwt-decode'
import {Button, Chip, Icon} from '@material-ui/core';
import {Grid, Divider, Paper} from "@material-ui/core";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from '@mui/material/DialogContentText';

import { Checkbox } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import {TextField} from "@material-ui/core";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
  retrievePaquete, deletePaquete, updatePaquete, findPaquetesById
} from "../../actions/paquete.actions";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  retrievePais
} from "../../actions/pais.actions";

import {
  retrieveMoneda
} from "../../actions/moneda.actions";
import HighlightOff from '@material-ui/icons/HighlightOff';

import SedeDialog from "../Dialog/pais.dialog";
import PaquetesDialog from "../Dialog/paquetes.dialog";
import ReorderIcon from '@mui/icons-material/Reorder';
import {
  findPermisosById
} from "../../actions/permisos.actions";

import {isEmail, isEmpty,isLength, equals} from 'validator';


class PaquetesList extends Component {
  constructor(props) {
    super(props);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleChangeDelete = this.handleChangeDelete.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyUp2 = this.onKeyUp.bind(this);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeDescripcion = this.onChangeDescripcion.bind(this);
    this.onChangePrecio = this.onChangePrecio.bind(this);
    this.onChangeTerminos = this.onChangeTerminos.bind(this);
    this.onChangePais = this.onChangePais.bind(this);
    this.onChangeMoneda = this.onChangeMoneda.bind(this);
    this.onChangeidPaquetes = this.onChangeidPaquetes.bind(this)
    this.handleChangeData = this.handleChangeData.bind(this)
    this.state = {
        checkedAdd: true ? false : true,
        checkedEdit: true ? false : true,
        checkedFilter: true ? false : true,
        checkedDelete: false,
        deleted : false,
        sede: "",
        id: "",
        datapermisosbyid: [],
        varData: false,

        idPaquetes: "",
        nombre: "",
        termino: "",
        precio: "",
        descripcion: "",
        idPais: "",
        idUsuarios: "",
        idMoneda: "",
        vigencia: false,
        togleedit: false,
        PidPaquetes: "",
        Pnombre: "",
        Pprecio: "",
        Pdescripcion: "",
        PidPais: "",
        PidUsuarios: "",
        PidMoneda: "",
        Pvigencia: false,
        loading: false,

        /* ------------------------*/
       
        idPermisosAddPaquetes: "",
        idPermisosEditPaquetes: "",
        idPermisosEliminarPaquetes: "",
        idPermisosListPaquetes: "",

        idUser: "",

       
    }

  }

  componentDidMount() {

    //this.props.retrievePaquete();

    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
    if (!tken) return false
    const decoded = jwt(tken); 
    
    //console.log("idUsuarios", decoded.idUsuarios)
    this.props.findPermisosById(decoded.idUsuarios)

    //if(this.state.idPermisosListPaquetes == '3003') {this.props.retrievePaquete();}
    
  
  }

  componentDidUpdate(prevProps, prevState) {
    //let newName = 'Obaseki Nosa'

    const { permisosbyid} = this.props 
    // Don't forget to compare states
    
    if (prevState && prevState.datapermisosbyid !== permisosbyid) {
      permisosbyid.data && permisosbyid.data.map (permisos => {
      //console.log("permisosbyid", permisosbyid.code)
      if(permisos.code == "3003"){
       
        this.state.idPermisosListPaquetes = permisos.code
        //this.retrievePaquete();
    }
  })
   
  }
  }

  handleChangeData(varData) {
    this.setState({ 
      varData
    });
    if(this.state.idPermisosListPaquetes == "3003"){
      this.props.retrievePaquete();
    }
  }

  


  handleChangeFilter(checkedFilter){
    this.setState({ 
        checkedFilter
      });

      
  }

  toggleChange2 = () => {
    this.setState({
      togleedit: !this.state.togleedit,
    });
  
  }

  onChangeName(e) {
    const nombre = e.target.value.toUpperCase();
    
    this.setState({
      nombre: nombre,
    });
  }
  onChangePrecio(e) {
    const precio = e.target.value;
    
    this.setState({
      precio: precio,
    });
  }
  onChangeDescripcion(e) {
    const descripcion = e.target.value.toUpperCase();
    
    this.setState({
      descripcion: descripcion,
    });
  }
  onChangeidPaquetes(e) {
    const idPaquetes = e.target.value;
    
    this.setState({
      idPaquetes: idPaquetes,
    });
  }

  onKeyUp(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.handleChangeAdd(false, true, this.state.id);
    }
  }

  onKeyUp2(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.handleChangeDelete(false, true, this.state.id);
    }
  }

  onChangePais(e) {
    const idPais = e.target.value;
    
    this.setState({
      idPais: idPais,
    });
  }

  onChangeTerminos(e) {
    const termino = e.target.value;
    
    this.setState({
      termino: termino,
    });
  }
  onChangeMoneda(e) {
    const idMoneda = e.target.value;
    
    this.setState({
      idMoneda: idMoneda,
    });
  }

  toggleChange = () => {
   
      this.setState({
        vigencia: !this.state.vigencia,
      });

  }

  onChangeIdUsuario(e) {
    const idUsuarios = e.target.value;
    
    this.setState({
      idUsuarios: idUsuarios,
    });
  }

  handleChangeAdd(checkedAdd, updated, id){
    this.state.id = id
    this.props.retrieveMoneda();
    this.props.retrievePais();
    this.props.findPaquetesById(id);

    //console.log("id", id)


    if(checkedAdd== true && updated == false){
    
      this.setState({ 
        checkedAdd,
      });
        /*this.setState({ 
          checkedAdd,
          nombre: this.state.Pnombre,
          precio: this.state.Pprecio,
          descripcion: this.state.Pdescripcion,
          idPais: this.state.PidPais,
          idUsuarios: this.state.PidUsuarios,
          idMoneda: this.state.PidMoneda
        });*/
      }
      //console.log("ABIERTO")
    
    if(checkedAdd== false && updated == false){
      this.setState({ 
        checkedAdd,
        togleedit: false
      });
      //console.log("CERRADO")
    }

    if(checkedAdd== false && updated == true){
     /* this.setState({ 
        checkedDelete,
      });
      console.log("ELIMINADO ID", id)
    
    }*/
    if(this.state.nombre == null){
      this.setState({ 
        nombre: this.state.Pnombre
      });
    }

    if(this.state.precio == null){
      this.setState({ 
        precio: this.state.Pprecio
      });
    }

    if(this.state.descripcion == null){
      this.setState({ 
        descripcion: this.state.Pdescripcion
      });
    }

    if(this.state.idPais== null){
      this.setState({ 
        idPais: this.state.PidPais
      });
    }

    if(this.state.idUsuarios == null){
      this.setState({ 
        idUsuarios: this.state.PidUsuarios
      });
    }

    if(this.state.idMoneda == null){
      this.setState({ 
        idMoneda: this.state.PidMoneda
      });
    }

    if(this.state.vigencia == null){
      this.setState({ 
        vigencia: this.state.Pvigencia
      });
    }

    if(this.state.idPaquetes == null){
      this.setState({ 
        idPaquetes: this.state.PidPaquetes
      });
    }

    var dataUp = { 

      idPaquetes: this.state.idPaquetes,
      nombre: this.state.nombre,
        precio: this.state.precio,
        descripcion: this.state.descripcion,
        idPais: this.state.idPais,
        idUsuarios: this.state.idUsuarios,
        idMoneda: this.state.idMoneda,
        vigencia: this.state.vigencia ? 1 : 0,
        terminos: this.state.termino
    };
  

    this.props
   .updatePaquete(this.state.idPaquetes, dataUp)
   .then((data) => {
    this.setState({

      idPaquetes: data.idPaquetes,
      nombre: data.nombre,
        precio: data.precio,
        descripcion: data.descripcion,
        idPais: data.idPais,
        idUsuarios: data.idUsuarios,
        idMoneda: data.idMoneda,
        termino: data.termino
   // this.props.history.push("/tutorials");
    })
    //console.log("err")
  })
  .catch((e) => {
    console.log(e);
  });	 
    
    
}
}
  

  handleChangeDelete(checkedDelete, deleted, id){


    this.state.id = id
    if(checkedDelete== true && deleted == false){
      this.setState({ 
        checkedDelete,
      });
      //console.log("ABIERTO")
    }
    if(checkedDelete== false && deleted == false){
      this.setState({ 
        checkedDelete,
      });
      //console.log("CERRADO")
    }

    if(checkedDelete== false && deleted == true){
     /* this.setState({ 
        checkedDelete,
      });
      console.log("ELIMINADO ID", id)
    }*/
    this.props
   .deletePaquete(id)
   .then(() => {
   // this.props.history.push("/tutorials");
  })
  .catch((e) => {
    console.log(e);
  });	 
   
 }
}
  


  render() {
    const { paquetes} = this.props;
    const { paquetes2} = this.props;
    const { paiss} = this.props;
    const { monedas} = this.props;
    const {permisosbyid} = this.props;

    if(!this.state.togleedit){
      paquetes2.data && paquetes2.data.map (paquete => {
    this.state.idPaquetes= `${paquete.idPaquetes}`
    this.state.nombre= `${paquete.nombre}`
    this.state.precio= `${paquete.precio}`
    this.state.descripcion= `${paquete.descripcion}`
    this.state.idPais= `${paquete.idPais}`
    this.state.idUsuarios= `${paquete.idUsuarios}`
    this.state.idMoneda= `${paquete.idMoneda}`
    this.state.termino= `${paquete.terminos}`

    if(paquetes2.vigencia== 1){
      this.state.vigencia= true
    }
    else{
      this.state.vigencia= false
    }
    })
  }


    if(`${paquetes2.vigencia}`== 1){
      this.state.Pvigencia= true
    }
    else{
      this.state.Pvigencia= false
    }

    permisosbyid.data && permisosbyid.data.map (permisos => {
      
      
       //if(permisos.code= '1003') console.log("permisos list", permisos.code);
      
       if(permisos.code == "3003") {this.state.idPermisosListPaquetes = permisos.code}
       if(permisos.code == "3001") {this.state.idPermisosAddPaquetes = permisos.code}
       if(permisos.code == "3002") {this.state.idPermisosEditPaquetes = permisos.code; console.log("permisos", this.state.idPermisosEditPaquetes)}
       if(permisos.code == "3004") {this.state.idPermisosEliminarPaquetes = permisos.code}

       //if(permisos.code= '1001') console.log("permisos add", permisos.code);
       //if(permisos.code= '1002') console.log("permisos edit", permisos.code);
       //if(permisos.code= '1003') console.log("permisos list", permisos.code);
       //if(permisos.code= '1004') console.log("permisos deleted", permisos.code);
      
     })

    return (
        <React.Fragment >
         
			<div style={{width: "100%", justifyContent: 'center'}}>
      <Grid container >
      { equals(this.state.idPermisosAddPaquetes, "3001") ? 
        <>
   
        <Grid item lg={8} md={8} sm={8} xs={12} >
        <PaquetesDialog/>
        </Grid>
        </>
        :
        <></>
    }
     { equals(this.state.idPermisosListPaquetes, "3003") ? 
        <>
   
    <Grid item lg={4} md={4} sm={4} xs={12} style={{width: "100%", justifyContent: 'end', alignContent: 'flex-end', alignItems: 'flex-end'}}>
    <Button color="primary" onClick={() => this.handleChangeData(true)} >
          <ReorderIcon style={{ fontSize: "40px" }} /> <p style={{ fontSize: "15px" }}>{this.state.varData ? "Volver a listar Paquetes" : "Listar Paquete"}</p>
      </Button>
        </Grid>
        </>
        :
        <></>
    }
        </Grid>
      <Dialog disableEnforceFocus
      onKeyPress={this.onKeyUp2}
          open={this.state.checkedDelete} 
          onClose={!this.state.checkedDelete}
          aria-labelledby="form-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Eliminar Paquete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           ¿Está seguro que quiere eliminar este paquete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button variant="contained" onClick={() => this.handleChangeDelete(false, false)} color="primary">
                      No
          </Button>
          <Button variant="contained" color="secondary" onClick={() => this.handleChangeDelete(false, true, this.state.id)}>Si</Button>
        </DialogActions>
        </Dialog>
      <div className="list row">
      { equals(this.state.idPermisosListPaquetes, "3003") ?
      <Paper style={{justifyContent: 'center', width: "100%", padding: '10px'}}>
 
      <Grid container >
   
   <Grid item lg={5} md={5} sm={5} xs={12}  style={{margin: '10px'}}>
   
     </Grid>
     <Grid item lg={3} md={3} sm={3} xs={12}  style={{margin: '10px'}}>
 
 </Grid>
 </Grid>
 
 <MaterialTable
                 title="Listado de Paquetes"
                 columns={ [
                  { title: 'Pais', field: 'idPais' },
                  { title: 'Nombre', field: 'nombre' },
                  { title: 'Descripcion', field: 'descripcion' },
                  { title: 'Precio', field: 'precio' },
                  { title: 'Vigente', field: 'vigencia'},
                  { title: 'Moneda', field: 'idMoneda' }
                 
                 ]
               
              }
                 
                 data={paquetes.data && paquetes.data.map(paquetes =>(paquetes))}
                         
                 options={{
                   filtering: this.state.checkedFilter,
                   exportButton: true,
                   exportAllData: true,
                   actionsColumnIndex: -1,
                   pageSize: 10,
                   pageSizeOptions: [10, 50, 100]
                 }}
                 actions={
                 (this.state.idPermisosEliminarPaquetes !="3004" && this.state.idPermisosEditPaquetes !="3002") ?
                  []
                  :[
                  rowData => (
                    {
                      icon: VisibilityIcon,
                      tooltip: 'Detalle Paquetes',
                      onClick:((event, rowData) => this.handleChangeAdd(true, false, rowData.idPaquetes )),
                      hidden: (this.state.idPermisosEditPaquetes = "")
                  
                    }),
                    rowData =>({
                      icon: DeleteIcon,
                      tooltip: 'Eliminar Paquetes',
                      onClick: ((event, rowData)  => this.handleChangeDelete(true, false, rowData.idPaquetes)),
                      hidden: (this.state.idPermisosEliminarPaquetes ="")
                    }
                  )
                  
                  ]}

                  components={{
                    Toolbar: props => (
                      <div>
                        <MTableToolbar {...props} />
                        <Chip icon={this.state.checkedFilter? <FilterAltOffIcon /> : <FilterAltIcon />} label="Filtrar" color="secondary" onClick={() => this.handleChangeFilter(!this.state.checkedFilter)} style={{marginLeft: 20, marginTop: 10}}/>
                      </div>
                    )
                  }}
               />
               
                </Paper> 
                 :
                 <></>
                  }   
      </div>
      <Dialog disableEnforceFocus
        onKeyPress={this.onKeyUp}
        open={this.state.checkedAdd} 
        onClose={!this.state.checkedAdd}
        aria-labelledby="form-dialog-title"
      >
        
        <DialogActions style={{paddingLeft: 10, paddingTop:10}}>
              <Grid container >
              <Grid item lg={10} md={10} sm={10} xs={10}  style={{alignContent: "flex-start", alignItems: "flex-start", textAlign: "flex-start"}}>
              <DialogTitle id="form-dialog-title">Detalle Paquete</DialogTitle>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Button variant="contained" onClick={() => this.handleChangeAdd(false, false)} color="secondary">
                  <HighlightOff  style={{ fontSize: "30px" }} /> 
                  </Button>
                  </Grid>
                  </Grid>
              </DialogActions>

            <DialogContent style={{padding: "30px 30px 10px 30px"}}>
	
            <Grid container >
            <div style={{width: "100%", justifyContent: 'center'}}>
            <Paper style={{justifyContent: 'center', padding: '10px'}}>

	
                    
              <div style={{justifyContent: 'center',
              '& .MuiTextField-root': {
                  margin: '1px'
              },display: 'flex',
              flexWrap: 'wrap'}}
                  component={Paper} >

<Grid item lg={12} md={12} sm={12} xs={12} style={{margin: '10px'}}>

<FormControlLabel style={{justifyContent: 'start'}} control={<Checkbox checked={this.state.togleedit} disabled={this.state.togleedit ? true: false}
         onChange={this.toggleChange2} />} label="Editar" />

</Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vnombre"
                label="Nombre"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                fullWidth
                placeholder={this.state.Pnombre}
                autoComplete="off"
                onChange={this.onChangeName}
                value={this.state.nombre}
                
                />
                
                
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vdescripcion"
                label="Descripción"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                placeholder={this.state.Pdescripcion}
                fullWidth
                autoComplete="off"
                onChange={this.onChangeDescripcion}
                value={this.state.descripcion}
                
                />
                
                
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vprecio"
                label="Precio"
                disabled={this.state.togleedit ? false : true}
                placeholder={this.state.Pprecio}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangePrecio}
                value={this.state.precio}
                
                />
                
                
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
               
<FormControl fullWidth>
        <InputLabel id="demo-simple-select-standard-label">Pais</InputLabel>
<Select 
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="Vpais"
          disabled={this.state.togleedit ? false : true}
          label="Pais"
          value={this.state.idPais}
          placeholder={this.state.PidPais}
          onChange={this.onChangePais}
        >
          <MenuItem value="">
            <em>Seleccione un Pais</em>
          </MenuItem>

        
          {this.state.idPermisosEditPaquetes = "3002" ? paiss.data && paiss.data.map(data =>(
         <MenuItem value={data.iso2} >{data.name}</MenuItem>
         )) : null
          }
         
        
        </Select>
        </FormControl>
                 </Grid>

  <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
               
               <FormControl fullWidth>
                       <InputLabel id="demo-simple-select-standard-label">Moneda</InputLabel>
               <Select 
                         labelId="demo-simple-select-standard-label"
                         id="demo-simple-select-standard"
                         name="Vmoneda"
                         disabled={this.state.togleedit ? false : true}
                         label="Moneda"
                         value={this.state.idMoneda}
                         placeholder={this.state.PidMoneda}
                         onChange={this.onChangeMoneda}
                       >
                         <MenuItem value="">
                           <em>Seleccione una Moneda</em>
                         </MenuItem>
               
                       
                         { monedas.data && monedas.data.map(data =>(
         <MenuItem value={data.idMoneda} >{data.descripcion} ({data.simbolo}) ({data.idMoneda})</MenuItem>
         ))
          }
                        
                       
                       </Select>
                       </FormControl>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vtermino"
                label="Términos y Condiciones"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeTerminos}
                value={this.state.termino}
                
                />
                
                
                 </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
<FormControlLabel  control={<Checkbox checked={this.state.vigencia} disabled={this.state.togleedit ? false : true}
          onChange={this.toggleChange} />} label="Vigente" />
</Grid>

              </div>
              </Paper>
              </div>
              </Grid>
                    </DialogContent>

                    <DialogActions style={{padding: 30}}>
                
                  
              <LoadingButton
                size="small"
                onClick={() => this.handleChangeAdd(false, true, this.state.id)}
                color="secondary"
                loading={this.state.loading}
                loadingPosition="end"
                variant="contained"
              >
                 Actualizar
              </LoadingButton >
              </DialogActions>

              </Dialog>
			</div>
	</React.Fragment>
    );
  }
  
}

const mapStateToProps = (state) => {
  return {
    paquetes: state.paquetes,
    paiss: state.paiss,
    monedas: state.monedas,
    paquetes2: state.paquetes2,
    permisosbyid: state.permisosbyid
  };
};

export default connect(mapStateToProps, {
 retrievePaquete, deletePaquete, updatePaquete,
 retrievePais, retrieveMoneda, findPaquetesById,findPermisosById
})(PaquetesList);

