import {
  PERMISOS_BY_ID,
  DELETE_PERMISO_DATA
} from "../constants/permisos.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];


function permisos2Reducer(permisosbyid = initialState, action) {
  const { type, payload } = action;


  switch (type) {
    

    case PERMISOS_BY_ID: 
      return payload;

      case DELETE_PERMISO_DATA:
        toast.success('Permiso Eliminado de manera Éxitosa');
        //toast.success('Permiso Eliminado de manera Éxitosa');
        //window.location.reload(true);
        //setInterval(Refreshdata, 100)
        return permisosbyid.filter(({ id }) => id !== payload.id);
  

    default:
      return permisosbyid;
  }

  
};



export default permisos2Reducer;