
import React, { Component } from "react";
import { connect } from "react-redux";


import MaterialTable,{ MTableToolbar }  from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import jwt from 'jwt-decode'
import {Button, Chip, Icon} from '@material-ui/core';
import {Grid, Divider, Paper} from "@material-ui/core";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle"; 
import DialogContentText from '@mui/material/DialogContentText';
import {TextField} from "@material-ui/core";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { Checkbox, FormControlLabel } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReorderIcon from '@mui/icons-material/Reorder';
import {
  retrievePais, deletePais, updatePais, findPaisById
} from "../../actions/pais.actions";

import {
  findPermisosById
} from "../../actions/permisos.actions";

import {isEmail, isEmpty,isLength, equals} from 'validator';

import PaisDialog from "../Dialog/pais.dialog";

class PaisList extends Component {
  constructor(props) {
    super(props);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleChangeDelete = this.handleChangeDelete.bind(this);
    this.onChangePais = this.onChangePais.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyUp2 = this.onKeyUp.bind(this);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeCurrency = this.onChangeCurrency.bind(this);
    this.onChangeCurrencyCode = this.onChangeCurrencyCode.bind(this);
    this.onChangeIso2 = this.onChangeIso2.bind(this);
    this.onChangeIso3 = this.onChangeIso3.bind(this);
    this.onChangeIsoCode = this.onChangeIsoCode.bind(this);
    this.handleChangeData = this.handleChangeData.bind(this)
    this.state = {
        checkedAdd: true ? false : true,
        checkedEdit: true ? false : true,
        checkedFilter: true ? false : true,
        checkedDelete: false,
        deleted : false,
        updated: false,
        pais: "",

        idUser: "",

        id: "",
        iso2: "",
        name: "",
        iso3: "",
        iso_code: "",
        currency: "",
        togleedit: false,
        currency_code : "",

        Piso2: "",
        Pname: "",
        Piso3: "",
        Piso_code: "",
        Pcurrency: "",
        Pcurrency_code : "",
        loading: false,
        varData: false,

        /* ------------------------*/
       

        idPermisosAddPais: "",
        idPermisosEditPais: "",
        idPermisosEliminarPais: "",
        idPermisosListPais: "",

       
    }

  }

  componentDidMount() {

    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
    if (!tken) return false
    const decoded = jwt(tken); 
    /*this.setState({ 
      idUser: decoded.idUsuarios
    });
    */
    //console.log("idUsuarios", decoded)
    this.props.findPermisosById(decoded.idUsuarios)
  }

  componentDidUpdate(prevProps, prevState) {
    //let newName = 'Obaseki Nosa'

    const { permisosbyid} = this.props 
    // Don't forget to compare states
    
    if (prevState && prevState.datapermisosbyid !== permisosbyid) {
      permisosbyid.data && permisosbyid.data.map (permisos => {
      //console.log("permisosbyid", permisosbyid.code)
      if(permisos.code == "1003"){
        this.state.idPermisosListPais = permisos.code;
    }
  })
   
  }
  /*if(this.state.idPermisosListPais == "1003"){
    this.props.retrievePais();
  }*/

  }
  handleChangeData(varData) {
    this.setState({ 
      varData
    });
    if(this.state.idPermisosListPais == "1003"){
      this.props.retrievePais();
    }
  }

  handleChangeFilter(checkedFilter){
    this.setState({ 
        checkedFilter
      });

      
  }

  toggleChange2 = () => {
    this.setState({
      togleedit: !this.state.togleedit,
    });
  
  }

  onKeyUp(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.handleChangeAdd(false, true, this.state.id);
    }
  }

  onKeyUp2(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.handleChangeDelete(false, true, this.state.id);
    }
  }

  onChangeName(e) {
    const name = e.target.value;
    
    this.setState({
      name: name,
    });
  }

  onChangeIso2(e) {
    const iso2 = e.target.value.toUpperCase();
    
    this.setState({
      iso2: iso2,
    });
  }
  onChangeIso3(e) {
    const iso3 = e.target.value.toUpperCase();
    
    this.setState({
      iso3: iso3,
    });
  }
  onChangeCurrency(e) {
    const currency = e.target.value;
    
    this.setState({
      currency: currency,
    });
  }
  onChangeIsoCode(e) {
    const iso_code = e.target.value.toUpperCase();
    
    this.setState({
      iso_code: iso_code,
    });
  }
  onChangeCurrencyCode(e) {
    const currency_code = e.target.value.toUpperCase();
    
    this.setState({
      currency_code: currency_code,
    });
  }

  onChangePais(e) {
    const pais = e.target.value;
    
    this.setState({
      pais: pais,
    });
  }


  handleChangeAdd(checkedAdd, updated, id){
    this.state.id = id
    this.props.findPaisById(id);
      //console.log("id2: ", id)
      //this.handleChangeEdit(this.state.id)

      if(checkedAdd== true && updated == false){
        this.setState({ 
          checkedAdd,
        });
        //console.log("ABIERTO")
      }
      if(checkedAdd== false && updated == false){
        this.setState({ 
          checkedAdd,
          togleedit: false
        });
        //console.log("CERRADO")
      }
  
      if(checkedAdd== false && updated == true){
       /* this.setState({ 
          checkedDelete,
        });
        console.log("ELIMINADO ID", id)
      
      }*/
      if(this.state.iso2 == null){
        this.setState({ 
          iso2: this.state.Piso2
        });
      }

      if(this.state.name == null){
        this.setState({ 
          name: this.state.Pname
        });
      }

      if(this.state.iso3 == null){
        this.setState({ 
          iso3: this.state.Piso3
        });
      }

      if(this.state.currency== null){
        this.setState({ 
          currency: this.state.Pcurrency
        });
      }

      if(this.state.currency_code == null){
        this.setState({ 
          currency_code: this.state.Pcurrency_code
        });
      }

      if(this.state.iso_code == null){
        this.setState({ 
          iso_code: this.state.Piso_code
        });
      }

      var dataUp = { 
        iso2: this.state.iso2,
        name: this.state.name,
        iso3: this.state.iso3,
        iso_code: this.state.iso_code,
        currency: this.state.currency,
        currency_code : this.state.currency_code,
      };
    

      this.props
     .updatePais(this.state.iso2, dataUp)
     .then((data) => {
      this.setState({
      iso2: data.iso2,
      name: data.name,
      iso3: data.iso3,
      iso_code: data.iso_code,
      currency: data.currency,
      currency_code : data.currency_code,
     // this.props.history.push("/tutorials");
      })
    })
    .catch((e) => {
      console.log(e);
    });	 
      
      
  }
}

  handleChangeDelete(checkedDelete, deleted, id){


    this.state.id = id
    if(checkedDelete== true && deleted == false){
      this.setState({ 
        checkedDelete,
      });
      //console.log("ABIERTO")
    }
    if(checkedDelete== false && deleted == false){
      this.setState({ 
        checkedDelete,
      });
      //console.log("CERRADO")
    }

    if(checkedDelete== false && deleted == true){
     /* this.setState({ 
        checkedDelete,
      });
      console.log("ELIMINADO ID", id)
    }*/
    this.props
   .deletePais(id)
   .then(() => {
   // this.props.history.push("/tutorials");
  })
  .catch((e) => {
    console.log(e);
  });	 
   
 }
}
  


  render() {
    const { paiss} = this.props;
    const { paissbyid} = this.props;
    const {permisosbyid} = this.props;

    console.log("Pais", paissbyid)

    if(!this.state.togleedit){
      paissbyid.data && paissbyid.data.map (pais => {
    this.state.iso2 = `${pais.iso2}`
    this.state.name = `${pais.name}`
    this.state.iso3= `${pais.iso3}`
    this.state.iso_code= `${pais.iso_code}`
    this.state.currency= `${pais.currency}`
    this.state.currency_code= `${pais.currency_code}`
      })
    }
  
    permisosbyid.data && permisosbyid.data.map (permisos => {
      // console.log("permisos", permisos);
       
        //if(permisos.code= '1003') console.log("permisos list", permisos.code);
       
        if(permisos.code == '1003') {this.state.idPermisosListPais = "1003"}
        if(permisos.code == "1001") {this.state.idPermisosAddPais = "1001"}
        if(permisos.code == "1002") {this.state.idPermisosEditPais = "1002"}
        if(permisos.code == "1004") {this.state.idPermisosEliminarPais = "1004"}

        //if(permisos.code= '1001') console.log("permisos add", permisos.code);
        //if(permisos.code= '1002') console.log("permisos edit", permisos.code);
        //if(permisos.code= '1003') console.log("permisos list", permisos.code);
        //if(permisos.code= '1004') console.log("permisos deleted", permisos.code);
       
      })
    return (
        <React.Fragment >
         
			<div style={{width: "100%", justifyContent: 'center'}}>
      <Grid container >
      { equals(this.state.idPermisosAddPais, "1001") ?
       <>
       <Grid item lg={8} md={8} sm={8} xs={12} >
     <PaisDialog/>
      </Grid>
        </>
        :
        <></>
      }
      { equals(this.state.idPermisosListPais, "1003") ? 
        <>
   
    <Grid item lg={4} md={4} sm={4} xs={12} style={{width: "100%", justifyContent: 'end', alignContent: 'flex-end', alignItems: 'flex-end'}}>
    <Button color="primary" onClick={() => this.handleChangeData(true)} >
          <ReorderIcon style={{ fontSize: "40px" }} /> <p style={{ fontSize: "15px" }}>{this.state.varData ? "Volver a listar Pais" : "Listar Pais"}</p>
      </Button>
        </Grid>
        </>
        :
        <></>
    }
    </Grid>
      <Dialog disableEnforceFocus
      onKeyPress={this.onKeyUp2}
          open={this.state.checkedDelete} 
          onClose={!this.state.checkedDelete}
          aria-labelledby="form-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Eliminar Pais
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           ¿Está seguro que quiere eliminar este Pais?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button variant="contained" onClick={() => this.handleChangeDelete(false, false)} color="primary">
                      No
          </Button>
          <Button variant="contained" color="secondary" onClick={() => this.handleChangeDelete(false, true, this.state.id)}>Si</Button>
        </DialogActions>
        </Dialog>
      <div className="list row">
      { equals(this.state.idPermisosListPais, "1003") ?
      <Paper style={{justifyContent: 'center', width: "100%", padding: '10px'}}>
 
      <Grid container >
   <Grid item lg={5} md={5} sm={5} xs={12}  style={{margin: '10px'}}></Grid>
    <Grid item lg={3} md={3} sm={3} xs={12}  style={{margin: '10px'}}></Grid>
 </Grid>
                 <MaterialTable
                 title="Listado de Paises"
                 columns={ [
                  { title: 'Iso 2', field: 'iso2' },
                  { title: 'Nombre', field: 'name' },
                  { title: 'Iso 3', field: 'iso3' },
                  { title: 'Iso Code', field: 'iso_code' },
                  { title: 'Currency', field: 'currency' },
                  { title: 'Currency Code', field: 'currency_code' },
                 
                 ]
               
              }
                 
                 data={paiss.data && paiss.data.map(paiss =>(paiss))}
                         
                 options={{
                   filtering: this.state.checkedFilter,
                   exportButton: true,
                   exportAllData: true,
                   actionsColumnIndex: -1,
                   pageSize: 10,
                   pageSizeOptions: [10, 50, 100]
                 }}
                 actions={
                  this.state.idPermisosEliminarPais !="1004" && this.state.idPermisosEditPais !="1002" ?
                  []
                  :[
                  rowData => (
                    {
                      icon: VisibilityIcon,
                      tooltip: 'Detalle Pais',
                      onClick:((event, rowData) => this.handleChangeAdd(true, false, rowData.iso2 )),
                      hidden: this.state.idPermisosEditPais !="1002"
                    }),
                    rowData =>({
                      icon: DeleteIcon,
                      tooltip: 'Eliminar Pais',
                      onClick: ((event, rowData)  => this.handleChangeDelete(true, false, rowData.iso2)),
                      hidden: this.state.idPermisosEliminarPais !="1004"
                    }
                  )
                  
                  ]}

                  components={{
                    Toolbar: props => (
                      <div>
                        <MTableToolbar {...props} />
                        <Chip icon={this.state.checkedFilter? <FilterAltOffIcon /> : <FilterAltIcon />} label="Filtrar" color="secondary" onClick={() => this.handleChangeFilter(!this.state.checkedFilter)} style={{marginLeft: 20, marginTop: 10}}/>
                      </div>
                    )
                  }}
               />
              
               
                </Paper>  
                :
                <></>
                 }
                
                  
      </div>
     
      <Dialog disableEnforceFocus

        open={this.state.checkedAdd} 
        onKeyPress={this.onKeyUp}
        onClose={!this.state.checkedAdd} 
        aria-labelledby="form-dialog-title"
      >
        
        <DialogActions style={{paddingLeft: 10, paddingTop:10}}>
              <Grid container >
              <Grid item lg={10} md={10} sm={10} xs={10}  style={{alignContent: "flex-start", alignItems: "flex-start", textAlign: "flex-start"}}>
              <DialogTitle id="form-dialog-title">Detalle Pais</DialogTitle>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Button variant="contained" onClick={() => this.handleChangeAdd(false, false)} color="secondary">
                  <HighlightOff  style={{ fontSize: "30px" }} /> 
                  </Button>
                  </Grid>
                  </Grid>
              </DialogActions>

            <DialogContent style={{padding: "30px 30px 10px 30px"}}>
	
            <Grid container >
            <div style={{width: "100%", justifyContent: 'center'}}>
            <Paper style={{justifyContent: 'center', padding: '10px'}}>

	
                    
              <div style={{justifyContent: 'center',
              '& .MuiTextField-root': {
                  margin: '1px'
              },display: 'flex',
              flexWrap: 'wrap'}}
                  component={Paper} >

<Grid item lg={12} md={12} sm={12} xs={12} style={{margin: '10px'}}>

<FormControlLabel style={{justifyContent: 'start'}} control={<Checkbox checked={this.state.togleedit} disabled={this.state.togleedit ? true: false}
         onChange={this.toggleChange2} />} label="Editar" />

</Grid>
<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="Viso2"
                label="Iso 2"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                placeholder={this.state.Piso2}
                fullWidth
                autoComplete="off"
                onChange={this.onChangeIso2}
                value={this.state.iso2}
                
                />
                 </Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Nombre"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                placeholder={this.state.Pname}
                fullWidth
                autoComplete="off"
                onChange={this.onChangeName}
                value={this.state.name}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="Vfechareg"
                label="Iso 3"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                fullWidth
                placeholder={this.state.Piso3}
                autoComplete="off"
                onChange={this.onChangeIso3}
                value={this.state.iso3}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="Vfechareg"
                label="Iso Code"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                placeholder={this.state.Piso_code}
                fullWidth
                autoComplete="off"
                onChange={this.onChangeIsoCode}
                value={this.state.iso_code}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="Vfechareg"
                label="Currency"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                fullWidth
                placeholder={this.state.Pcurrency}
                autoComplete="off"
                onChange={this.onChangeCurrency}
                value={this.state.currency}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="Vfechareg"
                disabled={this.state.togleedit ? false : true}
                label="Currency Code"
                variant= "outlined"
                fullWidth
                placeholder={this.state.Pcurrency_code}
                autoComplete="off"
                onChange={this.onChangeCurrencyCode}
                value={this.state.currency_code}
                
                />
                 </Grid>
              </div>
              </Paper>
              </div>
              </Grid>
                    </DialogContent>

              <DialogActions style={{padding: 30}}>
                
                  
              <LoadingButton
                size="small"
                onClick={() => this.handleChangeAdd(false, true, this.state.id)}
                color="secondary"
                loading={this.state.loading}
                loadingPosition="end"
                variant="contained"
              >
                 Actualizar
              </LoadingButton >
              </DialogActions>

              </Dialog>
			</div>
	</React.Fragment>
    );
  }
  
} 

const mapStateToProps = (state) => {
  return {
    paiss: state.paiss,
    paissbyid: state.paissbyid,
    permisosbyid: state.permisosbyid
  };
};

export default connect(mapStateToProps, {
 retrievePais, deletePais, updatePais, findPaisById,findPermisosById
})(PaisList);

