import React from "react";
import { Grid, withStyles } from '@material-ui/core';
import { Route , withRouter} from 'react-router-dom';

import PageTitle from "../../components/PageTitle/PageTitle";
import PaisTable from "../../components/Table/pais.table";

const styles = theme => ({
    paper: {
        margin: theme.spacing(3),
        padding: theme.spacing(2)
    }
})

const Pais = ({ classes, ...props }) => {
    return (
        <React.Fragment>
            <PageTitle title="Pais" />
            <Grid container>
            <PaisTable/>
            </Grid>
        </React.Fragment>
    );
}

export default withRouter(Pais);

