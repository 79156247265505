

import React, { Component } from "react";
import { connect } from "react-redux"; 

import {
  createAppupdate
} from "../../actions/appupdate.actions";

import {
  retrievePais
} from "../../actions/pais.actions";
import { toast } from 'react-toastify';
import {Grid, Divider, Paper, TextField} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import jwt from 'jwt-decode'
//const DeviceDetector = require('node-device-detector');
import { format } from 'date-fns'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

class AppupdateDialog extends Component {
  constructor(props) {
    super(props);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
   // this.handleChangeSede = this.handleChangeSede.bind(this);
    this.saveAppupdate = this.saveAppupdate.bind(this);

    this.onChangeNombre = this.onChangeNombre.bind(this);
    this.onChangeVersion = this.onChangeVersion.bind(this);
    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onChangeSO = this.onChangeSO.bind(this);
    this.onChangeFechaPublish = this.onChangeFechaPublish.bind(this);
     
    this.state = {
        checkedAdd: false,
        idAppUpdate: "",
        Nombre: "",
        version: "",
        Url: "",
        FechaPublish: "",
        SO: "",
      loading: false,
    }

  }

  componentDidMount() {
    const FechaNow = format(new Date(), 'yyyy-MM-dd')
    this.setState({
     FechaPublish: FechaNow
    })
   // this.props.retrieveIngresos();
   
  }

  
  onChangeNombre(e) {
    const Nombre = e.target.value;
    
    this.setState({
      Nombre: Nombre,
    });
  }

  onChangeVersion(e) {
    const version = e.target.value.toUpperCase();
    
    this.setState({
      version: version,
    });
  }

  onChangeUrl(e) {
    const Url = e.target.value;
    
    this.setState({
      Url: Url,
    });
  }

  onChangeFechaPublish(e) {
    const FechaPublish = e.target.value;
    
    this.setState({
      FechaPublish: FechaPublish,
    });
  }

  onChangeSO(e) {
    const SO = e.target.value.toUpperCase();
    
    this.setState({
      SO: SO,
    });
  }

  onKeyUp(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.saveMoneda(true);
    }
  }

  

  handleChangeAdd(checkedAdd){
    this.props.retrievePais()
    this.setState({ 
        checkedAdd
      });
      

      
  } 
  saveAppupdate(loading) {
    const { Nombre, version, Url, FechaPublish, SO} = this.state;
    if (loading == true) {
      this.setState({
        loading
      });
  this.props
      .createAppupdate(Nombre, version, Url, FechaPublish, SO)
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        
        //console.log(e);
        //this.props.history.push("/admin/cliente")
        /*if(e == "Request failed with status code 441" ){
          toast.error('Error, '+VAL441);
        }*/

        console.log("err: ", e)

        if (e.message == "Sin Autorización") {
          toast.error('Error, Sin Autorización' );
          this.setState({
            loading: false
          });
        }

        if(e == "Request failed with status code 500"){
          toast.error('Error, 500 internal');
          this.setState({
            loading: false
          });
        }
      });
    }
  }

  

  

  render() {
  
   
    const { paiss} = this.props;
    
    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
      if (!tken) return false

      const decoded = jwt(tken); 
    //console.log("user", data.id);

    return (
        <React.Fragment >
          
            <div>

      <Button color="primary" onClick={() => this.handleChangeAdd(true)} >
          <AddCircleIcon style={{ fontSize: "40px" }} /> <p style={{ fontSize: "15px" }}>Nueva Versión</p>
      </Button>
      <Dialog disableEnforceFocus

        open={this.state.checkedAdd} 
        onClose={!this.state.checkedAdd}
        aria-labelledby="form-dialog-title"
        onKeyPress={this.onKeyUp}
      >
        
            <DialogTitle id="form-dialog-title" style={{padding: "10px 10px 0px 10px"}}>Nueva Versión</DialogTitle>

            <DialogContent style={{padding: "30px 30px 10px 30px"}}>
	
            <Grid container >
            <div style={{width: "100%", justifyContent: 'center'}}>
            <Paper style={{justifyContent: 'center', padding: '10px'}}>

	
                    
              <div style={{justifyContent: 'center',
              '& .MuiTextField-root': {
                  margin: '1px'
              },display: 'flex',
              flexWrap: 'wrap'}}
                  component={Paper} >
<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="VNombre"
                label="Nombre"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeNombre}
                value={this.state.Nombre}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="VVersion"
                label="Versión"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeVersion}
                value={this.state.version}
                
                />
                 </Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="VURL"
                label="URL"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeUrl}
                value={this.state.Url}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="VFechaPublish"
                label="Fecha de Publicación"
                variant= "outlined"
                fullWidth
                type={"date"}
                autoComplete="off"
                onChange={this.onChangeFechaPublish}
                value={this.state.FechaPublish}
                
                />
                 </Grid>




                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="VSO"
                label="Sistema Operativo"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeSO}
                value={this.state.SO}
                
                />
                 </Grid>
              </div>
              </Paper>
              </div>
              </Grid>
                    </DialogContent>

              <DialogActions style={{padding: 30}}>
                  <Button variant="contained" onClick={() => this.handleChangeAdd(false)} color="primary">
                      Cancelar
                  </Button>
                  
              <LoadingButton
                size="small"
                onClick={() => this.saveAppupdate(true)}
                endIcon={<SaveIcon />}
                color="secondary"
                loading={this.state.loading}
                loadingPosition="end"
                variant="contained"
              >
                Guardar
              </LoadingButton >
              </DialogActions>

              </Dialog>
              </div>
                </React.Fragment>
                  );
                }
                
              }

const mapStateToProps = (state) => {
  return {
    paiss: state.paiss
  };
};

export default connect(mapStateToProps, {
  retrievePais, createAppupdate
})(AppupdateDialog);

