import React, { useState, useContext } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Button
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon
} from "@material-ui/icons";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import jwt from 'jwt-decode'
import classNames from "classnames";

// styles
import useStyles from "./styles";
import img2 from '../../images/logo2_pt.png'
import styles from './styles.module.css'
// components
import { Typography } from "../Wrappers/Wrappers";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { AuthContext } from "../../context/AuthContext";

export default function Header(props) {
  // global
  const classes = useStyles();
  const { logout} = useContext(AuthContext)
  
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();


  const tken = localStorage.getItem("token")

const decoded = jwt(tken); 

  // local
  var [profileMenu, setProfileMenu] = useState(null);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        
        {/*<Typography variant="h6" weight="medium" className={classes.logotype}>
          MGCourier
  </Typography>*/}

       <div  className={styles.img2}>
        <img className={styles.img} src={img2}></img>
</div> 
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <div className={classes.grow} />


   
        
        {/*<Typography variant="h8" weight="medium" className={classes.logotype}>
        {decoded.roleName}
</Typography>*/}

        <Button
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
           <h1  className={styles.h1}></h1>
          <AccountCircleIcon className={styles.headerIcon}  style={{color: "#E9AEFF"}}/>
        </Button>
        
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h6" weight="medium" color="primary">
             Correo del usuario
            </Typography>
          </div>

          {/*<MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
           /* <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>

          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <VpnKeyIcon className={classes.profileMenuIcon} /> Change Password
            </MenuItem>*/}

          <div className={classes.logoutMenu}>
            <Button
              variant="contained" 
              color="secondary"
              className={classes.logoutMenuButton}
              onClick={() => logout(props.history)}
            >
              Cerrar Sesión
            </Button>
          </div>

        </Menu>
      </Toolbar>
    </AppBar>
  );
}