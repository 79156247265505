import {
  CREATE_USUARIO,
  RETRIEVE_USUARIOS,
  UPDATE_USUARIO,
  DELETE_USUARIO,
  USUARIOS_BY_ID
} from "../constants/usuario.constants";

import { toast } from 'react-toastify';

import UsuarioDataService from "../services/usuario.service";

export const createUsuario = (username, nombres, apellidos, fechaRegistro, telefono, iso2, contraseña, email, idTipoUsuarios, ciudad) => async (dispatch) => {
  try {
    const res = await UsuarioDataService.create({username, nombres, apellidos, fechaRegistro, telefono, iso2, contraseña, email, idTipoUsuarios, ciudad});

    dispatch({
      type: CREATE_USUARIO,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err.message); 
  }
};

export const retrieveUsuario = () => async (dispatch) => {
  try {
    const res = await UsuarioDataService.getAllData();

    dispatch({
      type: RETRIEVE_USUARIOS,
      payload: res.data,
      
    });
    
  } catch (err) {
    console.log(err);
  }
};

export const findUsuariosById = (id) => async (dispatch) => {
  try {
    const res = await UsuarioDataService.get(id);

    dispatch({
      type: USUARIOS_BY_ID,
      payload: res.data,
    }); 
    
  } catch (err) {
    console.log(err);
  }
};

export const updateUsuario = (id, data) => async (dispatch) => {
  try {
    const res = await UsuarioDataService.update(id, data);

    dispatch({
      type: UPDATE_USUARIO,
      payload: data,
    });
   // toast.success('Usuario Actualizado de manera Éxitosa');
    //  window.location.reload(true);
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteUsuario = (id) => async (dispatch) => {
  try {
    await UsuarioDataService.delete(id);

    dispatch({
      type: DELETE_USUARIO,
      payload: { id },
    });

    //toast.success('Usuario Eliminado de manera Éxitosa');
    //  window.location.reload(true);
  } catch (err) {
    //console.log(err);

    /*if (err.message == "Request failed with status code 400") {
      toast.error('No se puede Eliminar este Usuario, el Id está siendo usada por otra tabla', {
        autoClose: 10000,
      });
    }*/
    if (err.message == "Request failed with status code 401") {
      toast.error('Sin Autorización');
    }

    if (err.message == "Request failed with status code 505") {
      toast.error('No tiene permisos suficientes para visitar esta página');
    }

  }
};
