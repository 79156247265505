

import React, { Component } from "react";
import { connect } from "react-redux"; 

import {
  createPaquete
} from "../../actions/paquete.actions";

import { toast } from 'react-toastify';

import { format } from 'date-fns'

import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import {Grid, Divider, Paper, TextField} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
//import UAParser from 'ua-parser-js'
//import  ua  from 'react-useragent';
import DeviceDetector from "device-detector-js";
import {getUA} from 'react-device-detect'
import jwt from 'jwt-decode'
//const DeviceDetector = require('node-device-detector');
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  retrievePais
} from "../../actions/pais.actions";

import {
  retrieveMoneda
} from "../../actions/moneda.actions";

class PaquetesDialog extends Component {
  constructor(props) {
    super(props);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onChangeDescripcion = this.onChangeDescripcion.bind(this);
    this.onChangePrecio = this.onChangePrecio.bind(this);
    this.onChangeTerminos = this.onChangeTerminos.bind(this);
    this.onChangePais = this.onChangePais.bind(this);
    this.onChangeMoneda = this.onChangeMoneda.bind(this);
   // this.handleChangeSede = this.handleChangeSede.bind(this);
    this.savePaquete = this.savePaquete.bind(this);
     
    this.state = {
        checkedAdd: false,
        id: null,
        nombre: "",
        precio: "",
        termino: "",
        descripcion: "",
        idPais: "",
        idUsuarios: "",
        idMoneda: "",
        vigencia: false,
      loading: false,
    }

  }

  componentDidMount() {
    
  }

  onChangeName(e) {
    const nombre = e.target.value.toUpperCase();
    
    this.setState({
      nombre: nombre,
    });
  }
  onChangePrecio(e) {
    const precio = e.target.value;
    
    this.setState({
      precio: precio,
    });
  }
  onChangeTerminos(e) {
    const termino = e.target.value;
    
    this.setState({
      termino: termino,
    });
  }
  onChangeDescripcion(e) {
    const descripcion = e.target.value.toUpperCase();
    
    this.setState({
      descripcion: descripcion,
    });
  }
  onChangePais(e) {
    const idPais = e.target.value;
    
    this.setState({
      idPais: idPais,
    });
  }


  onChangeMoneda(e) {
    const idMoneda = e.target.value;
    
    this.setState({
      idMoneda: idMoneda,
    });
  }

  toggleChange = () => {
      this.setState({
        vigencia: !this.state.vigencia 
      });

  }

  onKeyUp(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.savePaquete(true);
    }
  }

  onChangeIdUsuario(e) {
    const idUsuarios = e.target.value;
    
    this.setState({
      idUsuarios: idUsuarios,
    });
  }

  handleChangeAdd(checkedAdd){
    this.props.retrieveMoneda();
    this.props.retrievePais();
    this.setState({ 
        checkedAdd
      });
      

      
  } 
  savePaquete(loading) {
    const { nombre, precio, descripcion, idPais, idUsuarios, idMoneda, vigencia, termino} = this.state;
    if (loading == true) {
      this.setState({
        loading
      });
     
  this.props
      .createPaquete(nombre, precio, descripcion, idPais, idUsuarios, idMoneda, vigencia ? 1 : 0, termino)
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        
        //console.log(e);
        //this.props.history.push("/admin/cliente")
        /*if(e == "Request failed with status code 441" ){
          toast.error('Error, '+VAL441);
        }*/
        if(e == "Request failed with status code 500"){
          toast.error('Error, 500 internal');
          this.setState({
            loading: false
          });
        }
      });
    }
  }

  handleChangeEdit(id){
    //this.props.history.push(`/admin/ingreso/edit/${id}`)
    window.location.replace(`/#/admin/ingresos/edit/${id}`);
   //toast.success('Cliente Registrado de manera Éxitosa');
  }
  

  

  render() {
  
   
    const { paiss} = this.props;
    const { monedas} = this.props;

    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
      if (!tken) return false

      const decoded = jwt(tken); 

      this.state.idUsuarios = `${decoded.idUsuarios}`

    //console.log("user",  decoded);

    return (
        <React.Fragment >
          
            <div>

      <Button color="primary" onClick={() => this.handleChangeAdd(true)} >
          <AddCircleIcon style={{ fontSize: "40px" }} /> <p style={{ fontSize: "15px" }}>Nuevo Paquete</p>
      </Button>
      <Dialog disableEnforceFocus
        onKeyPress={this.onKeyUp}
        open={this.state.checkedAdd} 
        onClose={!this.state.checkedAdd}
        aria-labelledby="form-dialog-title"
      >
        
            <DialogTitle id="form-dialog-title" style={{padding: "10px 10px 0px 10px"}}>Nuevo Paquete</DialogTitle>

            <DialogContent style={{padding: "30px 30px 10px 30px"}}>
	
            <Grid container >
            <div style={{width: "100%", justifyContent: 'center'}}>
            <Paper style={{justifyContent: 'center', padding: '10px'}}>
                    
              <div style={{justifyContent: 'center',
              '& .MuiTextField-root': {
                  margin: '1px'
              },display: 'flex',
              flexWrap: 'wrap'}}
                  component={Paper}>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vnombre"
                label="Nombre"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeName}
                value={this.state.nombre}
                />
                
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vdescripcion"
                label="Descripción"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeDescripcion}
                value={this.state.descripcion}
                
                />
                
                
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vprecio"
                label="Precio"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangePrecio}
                value={this.state.precio}
                
                />
                
                
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
               
<FormControl fullWidth>
        <InputLabel id="demo-simple-select-standard-label">Pais</InputLabel>
<Select 
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="Vpais"
          label="Pais"
          value={this.state.idPais}
          onChange={this.onChangePais}
        >
          <MenuItem value="">
            <em>Seleccione un Pais</em>
          </MenuItem>

        
          {this.state.idPermisosAddPaquetes = "3001" ? paiss.data && paiss.data.map(data =>(
         <MenuItem value={data.iso2} >{data.name}</MenuItem>
         )) : null
          }
         
        
        </Select>
        </FormControl>
                 </Grid>

  <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
               
               <FormControl fullWidth>
                       <InputLabel id="demo-simple-select-standard-label">Moneda</InputLabel>
               <Select 
                         labelId="demo-simple-select-standard-label"
                         id="demo-simple-select-standard"
                         name="Vmoneda"
                         label="Moneda"
                         value={this.state.idMoneda}
                         onChange={this.onChangeMoneda}
                       >
                         <MenuItem value="">
                           <em>Seleccione una Moneda</em>
                         </MenuItem>
               
                       
                         { monedas.data && monedas.data.map(data =>(
         <MenuItem value={data.idMoneda} >{data.descripcion} ({data.simbolo}) ({data.idMoneda})</MenuItem>
         ))
          }
                        
                       
                       </Select>
                       </FormControl>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vdescripcion"
                label="Términos y Condiciones"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeTerminos}
                value={this.state.termino}
                
                />
                
                
                 </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
<FormControlLabel  control={<Checkbox checked={this.state.vigencia}
          onChange={this.toggleChange} />} label="Vigente" />
</Grid>

              </div>
              </Paper>
              </div>
              </Grid>
                    </DialogContent>

              <DialogActions style={{padding: 30}}>
                  <Button variant="contained" onClick={() => this.handleChangeAdd(false)} color="primary">
                      Cancelar
                  </Button>
                  
              <LoadingButton
                size="small"
                onClick={() => this.savePaquete(true)}
                endIcon={<SaveIcon />}
                color="secondary"
                loading={this.state.loading}
                loadingPosition="end"
                variant="contained"
              >
                Guardar
              </LoadingButton >
              </DialogActions>

              </Dialog>
              </div>
                </React.Fragment>
                  );
                }
                
              }

const mapStateToProps = (state) => {
  return {
    paiss: state.paiss,
    monedas: state.monedas
  };
};

export default connect(mapStateToProps, {
  createPaquete,
  retrievePais, retrieveMoneda
})(PaquetesDialog);

