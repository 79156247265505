import {
  MONEDAS_BY_ID
} from "../constants/moneda.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];



function monedas2Reducer(monedasbyid = initialState, action) {
  const { type, payload } = action;

  function Refreshdata(){
    window.location.reload(true);
  }

  switch (type) {
    
    case MONEDAS_BY_ID: 
      return payload; 

    default:
      return monedasbyid;
  }
};



export default monedas2Reducer;