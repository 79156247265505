import {http} from "../utils/api";

const config = {
  headers: {
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }
};

class AppupdateDataService {


  getDataAll() {
    return http.get("/api/private/appupdate", config);
  }

  get(id) {
    return http.get(`/api/private/appupdate/${id}`, config);
  }

  create(data) {
    return http.post("/api/private/appupdate/register", data, config);
  } 

  update(id, data) {
    return http.put(`/api/private/appupdate/${id}`, data, config);
  }

  delete(id) {
    return http.delete(`/api/private/appupdate/${id}`, config);
  }

  
}

export default new AppupdateDataService();