import {
  CREATE_CLIENTE,
  RETRIEVE_CLIENTES,
  UPDATE_CLIENTE,
  DELETE_CLIENTE,
  CLIENTES_BY_ID
} from "../constants/cliente.constants";

import { toast } from 'react-toastify';

import ClienteDataService from "../services/cliente.service";

export const createCliente = (Login, status,MobilePhone, Name, Lastname, EMail, City, Country, password) => async (dispatch) => {
  try {
    const res = await ClienteDataService.create({Login, status,MobilePhone, Name, Lastname, EMail, City, Country, password});

    dispatch({
      type: CREATE_CLIENTE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err.message); 
  }
};

export const retrieveCliente = () => async (dispatch) => {
  try {
    const res = await ClienteDataService.getAllData();

    dispatch({
      type: RETRIEVE_CLIENTES,
      payload: res.data,
      
    });
    
  } catch (err) {
    console.log(err);
  }
};

export const findClientesById = (id) => async (dispatch) => {
  try {
    const res = await ClienteDataService.get(id);

    dispatch({
      type: CLIENTES_BY_ID,
      payload: res.data,
    }); 
    
  } catch (err) {
    console.log(err);
  }
};

export const updateCliente = (id, data) => async (dispatch) => {
  try {
    const res = await ClienteDataService.update(id, data);

    dispatch({
      type: UPDATE_CLIENTE,
      payload: data,
    });
    //toast.success('Usuario Actualizado de manera Éxitosa');
     // window.location.reload(true);
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteCliente = (id) => async (dispatch) => {
  try {
    await ClienteDataService.delete(id);

    dispatch({
      type: DELETE_CLIENTE,
      payload: { id },
    });
  } catch (err) {
    //console.log(err);

    /*if (err.message == "Request failed with status code 400") {
      toast.error('No se puede Eliminar este Usuario, el Id está siendo usada por otra tabla', {
        autoClose: 10000,
      });
    }*/
    if (err.message == "Request failed with status code 401") {
      toast.error('Sin Autorización');
    }

    if (err.message == "Request failed with status code 505") {
      toast.error('No tiene permisos suficientes para visitar esta página');
    }

  }
};
