
import React, { Component } from "react";
import { connect } from "react-redux";


import MaterialTable,{ MTableToolbar }  from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import jwt from 'jwt-decode'
import {Button, Chip, Icon} from '@material-ui/core';
import {Grid, Divider, Paper} from "@material-ui/core";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from '@mui/material/DialogContentText';
import { Checkbox } from "@mui/material";
import {TextField} from "@material-ui/core";
import InputLabel from '@mui/material/InputLabel';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel'; 
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import HighlightOff from '@material-ui/icons/HighlightOff';
import {
  retrieveUsuario, deleteUsuario, updateUsuario, findUsuariosById
} from "../../actions/usuario.actions"; 

import VisibilityIcon from '@mui/icons-material/Visibility';
import UsuariosDialog from "../Dialog/usuarios.dialog";

import {
  createPermisos, deletePermisos, findPermisosById, deletePermisosData
} from "../../actions/permisos.actions";
import ReorderIcon from '@mui/icons-material/Reorder';
import {equals} from 'validator';

import {
  retrievePais
} from "../../actions/pais.actions";

import {
  Tabs,
  Tab
} from "@mui/material";

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

class UsuariosList extends Component {
  constructor(props) {
    super(props);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleChangeDelete = this.handleChangeDelete.bind(this);
    this.onChangeUsuario = this.onChangeUsuario.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeNombres = this.onChangeNombres.bind(this);
    this.onChangeApellidos = this.onChangeApellidos.bind(this);
    this.onChangeContraseña = this.onChangeContraseña.bind(this);
    this.onChangeTelefono = this.onChangeTelefono.bind(this);
    this.onChangeFechaRegistro = this.onChangeFechaRegistro.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeCiudad = this.onChangeCiudad.bind(this);
    this.handleChangeData = this.handleChangeData.bind(this)
    this.deletePermisos = this.deletePermisos.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);

    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyUp2 = this.onKeyUp.bind(this);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);

    this.onChangePais = this.onChangePais.bind(this);
    this.onChangeIdTipoUsuario = this.onChangeIdTipoUsuario.bind(this);

    this.toggleChangeAddMoneda = this.toggleChangeAddMoneda.bind(this)
    this.toggleChangeAddPais = this.toggleChangeAddPais.bind(this)
    this.toggleChangeAddPaquetes = this.toggleChangeAddPaquetes.bind(this)
    this.toggleChangeAddUsuarios = this.toggleChangeAddUsuarios.bind(this)
    this.toggleChangeAddClientes = this.toggleChangeAddClientes.bind(this)
    this.toggleChangeAddAppupdate = this.toggleChangeAddAppupdate.bind(this)
     
    this.toggleChangeListarMoneda = this.toggleChangeListarMoneda.bind(this)
    this.toggleChangeListarPais = this.toggleChangeListarPais.bind(this)
    this.toggleChangeListarPaquetes = this.toggleChangeListarPaquetes.bind(this)
    this.toggleChangeListarUsuarios = this.toggleChangeListarUsuarios.bind(this)
    this.toggleChangeListarClientes = this.toggleChangeListarClientes.bind(this)
    this.toggleChangeListarAppupdate = this.toggleChangeListarAppupdate.bind(this)

    this.toggleChangeEditarMoneda = this.toggleChangeEditarMoneda.bind(this)
    this.toggleChangeEditarPais = this.toggleChangeEditarPais.bind(this)
    this.toggleChangeEditarPaquetes = this.toggleChangeEditarPaquetes.bind(this)
    this.toggleChangeEditarUsuarios = this.toggleChangeEditarUsuarios.bind(this)
    this.toggleChangeEditarClientes = this.toggleChangeEditarClientes.bind(this)
    this.toggleChangeEditarAppupdate = this.toggleChangeEditarAppupdate.bind(this)

    this.toggleChangeEliminarMoneda = this.toggleChangeEliminarMoneda.bind(this)
    this.toggleChangeEliminarPais = this.toggleChangeEliminarPais.bind(this)
    this.toggleChangeEliminarPaquetes = this.toggleChangeEliminarPaquetes.bind(this)
    this.toggleChangeEliminarUsuarios = this.toggleChangeEliminarUsuarios.bind(this)
    this.toggleChangeEliminarClientes = this.toggleChangeEliminarClientes.bind(this)
    this.toggleChangeEliminarAppupdate = this.toggleChangeEliminarAppupdate.bind(this)
   
    this.state = {
        checkedAdd: true ? false : true,
        checkedEdit: true ? false : true,
        checkedFilter: true ? false : true,
        checkedDelete: false,
        deleted : false,
        usuario: "",
        varData: false,
        id: "",
        username:"",
        nombres: "",
        apellidos: "",
        telefono: "",
        email: "",
        username: "",
        fechaRegistro: "",
        Data2TipoUsuarios: "",
        iso2: "",
        togleedit: false,
        idTipoUsuarios: 1,
        ciudad: "",
        contraseña: "",

        Pnombres: "",
        Papellidos: "",
        Ptelefono: "",
        Pemail: "",
        PfechaRegistro: "",
        Piso2: "",
        PidTipoUsuarios: 1,
        Pciudad: "",
        Pcontraseña: "",
        tabId: 0,

        /* ------------------------*/
       
        idPermisosAddMoneda: "",
        idPermisosEditMoneda: "",
        idPermisosEliminarMoneda: "",
        idPermisosListMoneda: "",
        
        checkedAddMoneda: false,
        checkedEditarMoneda: false,
        checkedListarMoneda: false,
        checkedEliminarMoneda: false,
      
       idPermisosAddPais: "",
        idPermisosEditPais: "",
        idPermisosEliminarPais: "",
        idPermisosListPais: "",

        checkedAddPais: false,
        checkedEditarPais: false,
        checkedListarPais: false,
        checkedEliminarPais: false,

        checkedAddUsuarios: false,
        checkedEditarUsuarios: false,
        checkedListarUsuarios: false,
        checkedEliminarUsuarios: false,

        idPermisosAddUsuarios: "",
        idPermisosEditUsuarios: "",
        idPermisosEliminarUsuarios: "",
        idPermisosListUsuarios: "",
        
        idPermisosAddClientes: "",
        idPermisosEditClientes: "",
        idPermisosEliminarClientes: "",
        idPermisosListClientes: "",

        checkedAddClientes: false,
        checkedEditarClientes: false,
        checkedListarClientes: false,
        checkedEliminarClientes: false,

        idPermisosAddPaquetes: "",
        idPermisosEditPaquetes: "",
        idPermisosEliminarPaquetes: "",
        idPermisosListPaquetes: "",

        checkedAddPaquetes: false,
        checkedEditarPaquetes: false,
        checkedListarPaquetes: false,
        checkedEliminarPaquetes: false,

        idPermisosAddAppupdate: "",
        idPermisosEditAppupdate: "",
        idPermisosEliminarAppupdate: "",
        idPermisosListAppupdate: "",

        checkedAddAppupdate: false,
        checkedEditarAppupdate: false,
        checkedListarAppupdate: false,
        checkedEliminarAppupdate: false,

        idUser: "",

    }

  }

  componentDidMount() {
   // this.props.retrieveUsuario();

    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
    if (!tken) return false
    const decoded = jwt(tken); 

    //console.log("idUsuarios", decoded.idUsuarios)
    this.props.findPermisosById(decoded.idUsuarios)
  
  
  }

   componentDidUpdate(prevProps, prevState) {
    //let newName = 'Obaseki Nosa'

    const { permisosbyid} = this.props 
    // Don't forget to compare states
    
    if (prevState && prevState.datapermisosbyid !== permisosbyid) {
      permisosbyid.data && permisosbyid.data.map (permisos => {
      //console.log("permisosbyid", permisosbyid.code)
      if(permisos.code == "4003"){
       
        this.state.idPermisosListUsuarios = permisos.code
    }
  })
   
  }
  }

  handleChangeFilter(checkedFilter){
    this.setState({ 
        checkedFilter
      });   
  }
  toggleChange2 = () => {
    this.setState({
      togleedit: !this.state.togleedit,
    });
  
  }

  //
  toggleChangeAddMoneda = () => {
    // const { label, code, idUsuarios, link, icon, type} = this.state;
     this.setState({
       checkedAddMoneda: !this.state.checkedAddMoneda,
     });
 
 }
 
 toggleChangeEditarMoneda = () => {
  // const { label, code, idUsuarios, link, icon, type} = this.state;
   this.setState({
     checkedEditarMoneda: !this.state.checkedEditarMoneda,
   });
 }
 
 toggleChangeEliminarMoneda = () => {
   //const { label, code, idUsuarios, link, icon, type} = this.state;
   this.setState({
     checkedEliminarMoneda: !this.state.checkedEliminarMoneda,
   });
 }
 
 toggleChangeListarMoneda = () => {
  // const { label, code, idUsuarios, link, icon, type} = this.state;
   this.setState({
     checkedListarMoneda: !this.state.checkedListarMoneda
   });
 
 }

 onChangeUsername(e) {
  const username = e.target.value;
  
  this.setState({
    username: username,
  });
}

 handleChangeData(varData) {
  this.setState({ 
    varData
  });
  if(this.state.idPermisosListUsuarios == "4003"){
    this.props.retrieveUsuario();
  }
}
 //

 //*********************** */

  //
  toggleChangeAddAppupdate = () => {
    // const { label, code, idUsuarios, link, icon, type} = this.state;
     this.setState({
       checkedAddAppupdate: !this.state.checkedAddAppupdate
     });
  }
  
  toggleChangeEditarAppupdate = () => {
  // const { label, code, idUsuarios, link, icon, type} = this.state;
   this.setState({
     checkedEditarAppupdate: !this.state.checkedEditarAppupdate
   });
  }
  
  toggleChangeEliminarAppupdate = () => {
   //const { label, code, idUsuarios, link, icon, type} = this.state;
   this.setState({
     checkedEliminarAppupdate: !this.state.checkedEliminarAppupdate
   });
  }
  
  toggleChangeListarAppupdate = () => {
  // const { label, code, idUsuarios, link, icon, type} = this.state;
   this.setState({
     checkedListarAppupdate: !this.state.checkedListarAppupdate
   });
   
  }
  //

 //************************ */
 
  //
  toggleChangeAddPais = () => {
   // const { label, code, idUsuarios, link, icon, type} = this.state;
    this.setState({
      checkedAddPais: !this.state.checkedAddPais
    });
 
 }
 
 toggleChangeEditarPais = () => {
 // const { label, code, idUsuarios, link, icon, type} = this.state;
  this.setState({
    checkedEditarPais: !this.state.checkedEditarPais
  });
 }
 
 toggleChangeEliminarPais = () => {
  //const { label, code, idUsuarios, link, icon, type} = this.state;
  this.setState({
    checkedEliminarPais: !this.state.checkedEliminarPais
  });
 }
 
 toggleChangeListarPais = () => {
 // const { label, code, idUsuarios, link, icon, type} = this.state;
  this.setState({
    checkedListarPais: !this.state.checkedListarPais
  });
 }
 //
 
 //
 toggleChangeAddUsuarios = () => {
   // const { label, code, idUsuarios, link, icon, type} = this.state;
    this.setState({
      checkedAddUsuarios: !this.state.checkedAddUsuarios
    });
 }
 
 toggleChangeEditarUsuarios = () => {
 // const { label, code, idUsuarios, link, icon, type} = this.state;
  this.setState({
    checkedEditarUsuarios: !this.state.checkedEditarUsuarios
  });
 }
 
 toggleChangeEliminarUsuarios = () => {
  //const { label, code, idUsuarios, link, icon, type} = this.state;
  this.setState({
    checkedEliminarUsuarios: !this.state.checkedEliminarUsuarios
  });
 }
 
 toggleChangeListarUsuarios = () => {
 // const { label, code, idUsuarios, link, icon, type} = this.state;
  this.setState({
    checkedListarUsuarios: !this.state.checkedListarUsuarios
  });
  
 }
 //
 
 //
 toggleChangeAddPaquetes = () => {
   // const { label, code, idUsuarios, link, icon, type} = this.state;
    this.setState({
      checkedAddPaquetes: !this.state.checkedAddPaquetes
    });
 }
 
 toggleChangeEditarPaquetes = () => {
 // const { label, code, idUsuarios, link, icon, type} = this.state;
  this.setState({
    checkedEditarPaquetes: !this.state.checkedEditarPaquetes
  });
 }
 
 toggleChangeEliminarPaquetes = () => {
  //const { label, code, idUsuarios, link, icon, type} = this.state;
  this.setState({
    checkedEliminarPaquetes: !this.state.checkedEliminarPaquetes
  });
 }
 
 toggleChangeListarPaquetes = () => {
 // const { label, code, idUsuarios, link, icon, type} = this.state;
  this.setState({
    checkedListarPaquetes: !this.state.checkedListarPaquetes
  });
 }
//

 //
 toggleChangeAddClientes = () => {
  // const { label, code, idUsuarios, link, icon, type} = this.state;
   this.setState({
     checkedAddClientes: !this.state.checkedAddClientes
   });
}

toggleChangeEditarClientes = () => {
// const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedEditarClientes: !this.state.checkedEditarClientes
 });
}

toggleChangeEliminarClientes = () => {
 //const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedEliminarClientes: !this.state.checkedEliminarClientes
 });
}

toggleChangeListarClientes = () => {
// const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedListarClientes: !this.state.checkedListarClientes
 });
}
//

  onChangeNombres(e) {
    const nombres = e.target.value.toUpperCase();
    
    this.setState({
      nombres: nombres,
    });
  }

  deletePermisos(id, code){

    this.props
   .deletePermisosData(id, code)
   .then(() => {
   // this.props.history.push("/tutorials");
  })
  .catch((e) => {
    console.log(e);
  });	 
   
  }

  onChangeApellidos(e) {
    const apellidos = e.target.value.toUpperCase();
    
    this.setState({
      apellidos: apellidos,
    });
  }

  savePermisos() {
  
    if(this.state.checkedAddMoneda){
     this.props
      .createPermisos("Add Moneda", "2001", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    }
    if(!this.state.checkedAddMoneda){
      this.deletePermisos(this.state.idUsuarios, "2001");
    }
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedEditarMoneda){
      this.props
      .createPermisos("Editar Moneda", "2002", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    }
    if(!this.state.checkedEditarMoneda){
      this.props.deletePermisosData(this.state.idUsuarios, "2002");
    }
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedListarMoneda){
      this.props
      .createPermisos("Listar Moneda", "2003", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedListarMoneda){ this.props.deletePermisosData(this.state.idUsuarios, "2003");}
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedEliminarMoneda){
      //console.log("usereliminarMoneda", this.state.idUsuarios);
      this.props
      .createPermisos("Eliminar Moneda", "2004", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    }
    if(!this.state.checkedEliminarMoneda){
      this.props.deletePermisosData(this.state.idUsuarios, "2004");
    }
    //////////////////////////////////////////////////////
  
    //////////////////////////////////////////////////////
    if(this.state.checkedAddPais){
      this.props
      .createPermisos("Add Pais","1001", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedAddPais){ this.props.deletePermisosData(this.state.idUsuarios, "1001");}
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedEditarPais){
      this.props
      .createPermisos("Editar Pais", "1002", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedEditarPais){ this.props.deletePermisosData(this.state.idUsuarios, "1002");}
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedListarPais){
      this.props
      .createPermisos("Listar Pais", "1003", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedListarPais){ this.props.deletePermisosData(this.state.idUsuarios, "1003");}
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedEliminarPais){
      this.props
      .createPermisos("Eliminar Pais", "1004", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedEliminarPais){ this.props.deletePermisosData(this.state.idUsuarios, "1004"); }
    //////////////////////////////////////////////////////
  
    //////////////////////////////////////////////////////
    if(this.state.idPermisosEditUsuarios == 1){
      this.props
      .createPermisos("Add Usuarios", "4001", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(this.state.idPermisosEditUsuarios == 2){ this.props.deletePermisosData(this.state.idUsuarios, "4001"); }
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedEditarUsuarios){
      this.props
      .createPermisos("Editar Usuarios", "4002", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedEditarUsuarios){ this.props.deletePermisosData(this.state.idUsuarios, "4002");}
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedListarUsuarios){
      this.props
      .createPermisos("Listar Usuarios", "4003", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedListarUsuarios){ this.props.deletePermisosData(this.state.idUsuarios, "4003");}
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedEliminarUsuarios){
      this.props
      .createPermisos("Eliminar Usuarios", "4004", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedEliminarUsuarios){ this.props.deletePermisosData(this.state.idUsuarios, "4004");}
    //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
     if(this.state.checkedAddPaquetes){
      //console.log("usereliminarMoneda", this.state.idUsuarios);
      this.props
      .createPermisos("Add Paquetes", "3001", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
  
    } if(!this.state.checkedAddPaquetes){this.props.deletePermisosData(this.state.idUsuarios, "3001"); }
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedEditarPaquetes){
  
      this.props
      .createPermisos("Editar Paquetes", "3002", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedEditarPaquetes){this.props.deletePermisosData(this.state.idUsuarios, "3002");}
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedListarPaquetes){
      this.props
      .createPermisos("Listar Paquetes", "3003", this.state.idUsuarios,"/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedListarPaquetes){this.props.deletePermisosData(this.state.idUsuarios, "3003");}
     //////////////////////////////////////////////////////

     //////////////////////////////////////////////////////
    if(this.state.checkedAddAppupdate){
      this.props
      .createPermisos("Add Appupdate","6001", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedAddAppupdate){ this.props.deletePermisosData(this.state.idUsuarios, "6001");}
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedEditarAppupdate){
      this.props
      .createPermisos("Editar Appupdate", "6002", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedEditarAppupdate){ this.props.deletePermisosData(this.state.idUsuarios, "6002");}
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedListarAppupdate){
      this.props
      .createPermisos("Listar Appupdate", "6003", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedListarAppupdate){ this.props.deletePermisosData(this.state.idUsuarios, "6003");}
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedEliminarAppupdate){
      this.props
      .createPermisos("Eliminar Appupdate", "6004", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedEliminarAppupdate){ this.props.deletePermisosData(this.state.idUsuarios, "6004"); }
    //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedEliminarPaquetes){
      this.props
      .createPermisos("Eliminar Paquetes", "3004", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
  
    } if(!this.state.checkedEliminarPaquetes){ this.props.deletePermisosData(this.state.idUsuarios, "3004");}
  
    if(this.state.checkedAddClientes){
      //console.log("usereliminarMoneda", this.state.idUsuarios);
      this.props
      .createPermisos("Add Clientes", "5001", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
  
    }  if(!this.state.checkedAddClientes){this.props.deletePermisosData(this.state.idUsuarios, "5001"); }
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedEditarClientes){
  
      this.props
      .createPermisos("Editar Clientes", "5002", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedEditarClientes){this.props.deletePermisosData(this.state.idUsuarios, "5002");}
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedListarClientes){
      this.props
      .createPermisos("Listar Clientes", "5003", this.state.idUsuarios,"/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
    } if(!this.state.checkedListarClientes){this.props.deletePermisosData(this.state.idUsuarios, "5003");}
     //////////////////////////////////////////////////////
  
     //////////////////////////////////////////////////////
    if(this.state.checkedEliminarClientes){
      this.props
      .createPermisos("Eliminar Clientes", "5004", this.state.idUsuarios, "/", "/", "/")
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        console.log(e);
      });
  
    } if(!this.state.checkedEliminarClientes){ this.props.deletePermisosData(this.state.idUsuarios, "5004");}
  

    //window.location.reload(true); 
  }

  handleChangeTab(tabId){
    this.setState({ 
       tabId
      });
  } 


  onKeyUp(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.handleChangeAdd(false, true, this.state.id);
    }
  }

  onKeyUp2(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.handleChangeDelete(false, true, this.state.id);
    }
  }

  onChangeIdTipoUsuario(e) {
    const idTipoUsuarios = e.target.value;
    
    this.setState({
      idTipoUsuarios: idTipoUsuarios,
    });
  }

  onChangePais(e) {
    const iso2 = e.target.value;
    
    this.setState({
      iso2: iso2,
    });
  }

  onChangeCiudad(e) {
    const ciudad = e.target.value;
    
    this.setState({
      ciudad: ciudad,
    });
  }
  onChangeTelefono(e) {
    const telefono = e.target.value.toUpperCase();
    
    this.setState({
      telefono: telefono,
    });
  }
  onChangeUsername(e) {
    const username = e.target.value;
    
    this.setState({
      username: username,
    });
  }
  onChangeEmail(e) {
    const email = e.target.value;
    
    this.setState({
      email: email,
    });
  }
  onChangeFechaRegistro(e) {
    const fechaRegistro = e.target.value.toUpperCase();
    
    this.setState({
      fechaRegistro: fechaRegistro,
    });
  }
  onChangeContraseña(e) {
    const contraseña = e.target.value;
    
    this.setState({
      contraseña: contraseña,
    });
  }


  onChangeUsuario(e) {
    const usuario = e.target.value;
    
    this.setState({
      usuario: usuario,
    });
  }

  handleChangeAdd(checkedAdd, updated, id){
    this.props.retrievePais();
    this.state.id = id
    this.props.findUsuariosById(id);
      //console.log("id2: ", id)
      //this.handleChangeEdit(this.state.id)

      if(checkedAdd== true && updated == false){
        this.setState({ 
          checkedAdd,
        });
        //console.log("ABIERTO")
      }
      if(checkedAdd== false && updated == false){
        this.setState({ 
          checkedAdd,
          togleedit: false
        });
        //console.log("CERRADO")
      }
  
      if(checkedAdd== false && updated == true){
       /* this.setState({ 
          checkedDelete,
        });
        console.log("ELIMINADO ID", id)
      
      }*/
      if(this.state.descripcion == null){
        this.setState({ 
          descripcion: this.state.Pdescripcion
        });
      }

      if(this.state.simbolo == null){
        this.setState({ 
          simbolo: this.state.Psimbolo
        });
      }

      if(this.state.idmoneda == null){
        this.setState({ 
          idMoneda: this.state.PidMoneda
        });
      }
      
      var dataUp = { 
        idUsuarios: this.state.idUsuarios,
        username: this.state.username,
        nombres: this.state.nombres, 
        apellidos: this.state.apellidos, 
        fechaRegistro: this.state.fechaRegistro, 
        telefono: this.state.telefono, 
        iso2:this.state.iso2, 
        contraseña: this.state.contraseña, 
        email: this.state.email, 
        idTipoUsuarios: this.state.idTipoUsuarios, 
        ciudad:this.state.ciudad
      };

      
    

      this.props
     .updateUsuario(this.state.idUsuarios, dataUp)
     .then((data) => {
      this.setState({
        username: data.username,
        nombres: data.nombres, 
        apellidos: data.apellidos, 
        fechaRegistro: data.fechaRegistro, 
        telefono: data.telefono, 
        iso2: data.iso2, 
        contraseña: data.contraseña, 
        email: data.email, 
        idTipoUsuarios: data.idTipoUsuarios, 
        ciudad: data.ciudad
     // this.props.history.push("/tutorials");
      })
    })
    .catch((e) => {
      console.log(e);
    });	 
      
      
  }
}

  handleChangeEdit(id){
    //this.props.history.push(`/admin/ingreso/edit/${id}`)
    window.location.replace(`/#/admin/usuarios/edit/:id${id}`);
   //toast.success('Cliente Registrado de manera Éxitosa');
  }

  handleChangeDelete(checkedDelete, deleted, id){


    this.state.id = id
    if(checkedDelete== true && deleted == false){
      this.setState({ 
        checkedDelete,
      });
      //console.log("ABIERTO")
    }
    if(checkedDelete== false && deleted == false){
      this.setState({ 
        checkedDelete,
      });
      //console.log("CERRADO")
    }

    if(checkedDelete== false && deleted == true){
     /* this.setState({ 
        checkedDelete,
      });
      console.log("ELIMINADO ID", id)
    }*/
    this.props.deletePermisos(id)
    .then(() => {
      // this.props.history.push("/tutorials");
      this.props.deleteUsuario(id)
   .then(() => {
   // this.props.history.push("/tutorials");
  })
  .catch((e) => {
    console.log(e);
  });
     })
     .catch((e) => {
       console.log(e);
     });	
    	 
   
 }
}
  


  render() {
    const { usuarios} = this.props;
    const { usuariosbyId} = this.props;
    const { paiss} = this.props;
    const { tipo_usuarios} = this.props;
    const {permisosbyid} = this.props;

    if(!this.state.togleedit){

      usuariosbyId.data && usuariosbyId.data.map (usuario => {
    this.state.idUsuarios= `${usuario.idUsuarios}`
    this.state.username= `${usuario.username}`
    this.state.nombres= `${usuario.nombres}`
    this.state.apellidos= `${usuario.apellidos}`
    this.state.fechaRegistro= `${usuario.fechaRegistro}`
    this.state.telefono= `${usuario.telefono}`
    this.state.iso2= `${usuario.iso2}`
    this.state.contraseña= `${usuario.contraseña}`
    this.state.email= `${usuario.email}`
    this.state.idTipoUsuarios= `${usuario.idTipoUsuarios}`
    this.state.ciudad= `${usuario.ciudad}`
      })

    permisosbyid.data && permisosbyid.data.map (permisos => {

    if(permisos.code == '1003') {this.state.checkedListartPais = true}
    if(permisos.code == "1001") {this.state.checkedAddPais = true}
    if(permisos.code == "1002") {this.state.checkedEditarPais = true}
    if(permisos.code == "1004") {this.state.checkedEliminarPais =true}

    if(permisos.code == "2003") {this.state.checkedListarMoneda = true}
    if(permisos.code == "2001") {this.state.checkedAddMoneda = true}
    if(permisos.code == "2002") {this.state.checkedEditarMoneda = true}
    if(permisos.code == "2004") {this.state.checkedEliminarMoneda = true}

    if(permisos.code == "3003") {this.state.checkedListarPaquetes = true}
    if(permisos.code == "3001") {this.state.checkedAddPaquetes = true}
    if(permisos.code == "3002") {this.state.checkedEditarPaquetes = true}
    if(permisos.code == "3004") {this.state.checkedEliminarPaquetes = true}

    if(permisos.code == "4003") {this.state.checkedListarUsuarios = true}
    if(permisos.code == "4001") {this.state.checkedAddUsuarios = true}
    if(permisos.code == "4002") {this.state.checkedEditarUsuarios = true}
    if(permisos.code == "4004") {this.state.checkedEliminarUsuarios = true}

    if(permisos.code == "5003") {this.state.checkedListarClientes = true}
    if(permisos.code == "5001") {this.state.checkedAddClientes = true}
    if(permisos.code == "5002") {this.state.checkedEditarClientes = true}
    if(permisos.code == "5004") {this.state.checkedEliminarClientes = true}

    if(permisos.code == "6003") {this.state.checkedListarAppupdate = true}
    if(permisos.code == "6001") {this.state.checkedAddAppupdate = true}
    if(permisos.code == "6002") {this.state.checkedEditarAppupdate = true}
    if(permisos.code == "6004") {this.state.checkedEliminarAppupdate = true}
  })
    }


    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
    if (!tken) return false

    const decoded = jwt(tken); 
    this.state.Data2TipoUsuarios = decoded.tipoUsuarios;

    permisosbyid.data && permisosbyid.data.map (permisos => {
      //console.log("permisos", permisos),
      
       //if(permisos.code= '1003') console.log("permisos list", permisos.code);
      
       if(permisos.code == '4003') {this.state.idPermisosListUsuarios = "4003"}
       if(permisos.code == "4001") {this.state.idPermisosAddUsuarios = "4001"}
       if(permisos.code == "4002") {this.state.idPermisosEditUsuarios = "4002"}
       if(permisos.code == "4004") {this.state.idPermisosEliminarUsuarios = "4004"}

       //if(permisos.code= '1001') console.log("permisos add", permisos.code);
       //if(permisos.code= '1002') console.log("permisos edit", permisos.code);
       //if(permisos.code= '1003') console.log("permisos list", permisos.code);
       //if(permisos.code= '1004') console.log("permisos deleted", permisos.code);
      
     })

   console.log("tabid", this.state.tabId)


    return (
        <React.Fragment >
         
			<div style={{width: "100%", justifyContent: 'center'}}>
      <Grid container >
      { equals(this.state.idPermisosAddUsuarios, "4001") && this.state.Data2TipoUsuarios != 2 ? 
        <>
   
        <Grid item lg={8} md={8} sm={8} xs={12} >
        <UsuariosDialog/>
        </Grid>
        </>
        :
        <></>
    }
     { equals(this.state.idPermisosListUsuarios, "4003") ? 
        <>
   
    <Grid item lg={4} md={4} sm={4} xs={12} style={{width: "100%", justifyContent: 'end', alignContent: 'flex-end', alignItems: 'flex-end'}}>
    <Button color="primary" onClick={() => this.handleChangeData(true)} >
          <ReorderIcon style={{ fontSize: "40px" }} /> <p style={{ fontSize: "15px" }}>{this.state.varData ? "Volver a listar Usuarios" : "Listar Usuarios"}</p>
      </Button>
        </Grid>
        </>
        :
        <></>
    }
    </Grid>
      <Dialog disableEnforceFocus
          open={this.state.checkedDelete} 
          onClose={!this.state.checkedDelete}
          onKeyPress={this.onKeyUp2}
          aria-labelledby="form-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Eliminar Usuario
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           ¿Está seguro que quiere eliminar este Usuario?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button variant="contained" onClick={() => this.handleChangeDelete(false, false)} color="primary">
                      No
          </Button>
          <Button variant="contained" color="secondary" onClick={() => this.handleChangeDelete(false, true, this.state.id)}>Si</Button>
        </DialogActions>
        </Dialog>
      <div className="list row">
      { equals(this.state.idPermisosListUsuarios, "4003") ?
      <Paper style={{justifyContent: 'center', width: "100%", padding: '10px'}}>
 
      <Grid container >
   
   <Grid item lg={5} md={5} sm={5} xs={12}  style={{margin: '10px'}}>
   
     </Grid>
     <Grid item lg={3} md={3} sm={3} xs={12}  style={{margin: '10px'}}>
 
 </Grid>
 </Grid>

                 <MaterialTable
                 title="Listado de Usuarios"
                 columns={ [
                  { title: 'Username', field: 'username' },
                  { title: 'Nombres', field: 'nombres' },
                  { title: 'Apellidos', field: 'apellidos' },
                  { title: 'Teléfonos', field: 'telefono' },
                  { title: 'Correo Electrónico', field: 'email' },
                 ]
               
              }
                 
                 data={equals(this.state.idPermisosListUsuarios, "4003") ? usuarios.data && usuarios.data.map(usuarios =>(usuarios)): null}
                         
                 options={{
                   filtering: this.state.checkedFilter,
                   exportButton: true,
                   exportAllData: true,
                   actionsColumnIndex: -1,
                   pageSize: 10,
                   pageSizeOptions: [10, 50, 100]
                 }}
                 actions={this.state.idPermisosEliminarUsuarios !="4004" && this.state.idPermisosEditUsuarios !="4002" ?
                 []
                 :[
                  rowData => (
                    {
                      icon: VisibilityIcon,
                      tooltip: 'Detalle Usuario',
                      onClick:((event, rowData) => this.handleChangeAdd(true, false, rowData.idUsuarios)),
                      hidden: this.state.idPermisosEditUsuarios !="4002"
                    }),
                    rowData =>({
                      icon: DeleteIcon,
                      tooltip: 'Eliminar Usuario',
                      onClick: ((event, rowData)  => this.handleChangeDelete(true, false, rowData.idUsuarios)),
                      hidden: this.state.idPermisosEliminarUsuarios !="4004"
                    }
                  )
                  
                  ]}

                  components={{
                    Toolbar: props => (
                      <div>
                        <MTableToolbar {...props} />
                        <Chip icon={this.state.checkedFilter? <FilterAltOffIcon /> : <FilterAltIcon />} label="Filtrar" color="secondary" onClick={() => this.handleChangeFilter(!this.state.checkedFilter)} style={{marginLeft: 20, marginTop: 10}}/>
                      </div>
                    )
                  }}
               />
            
                </Paper>   
                   :
                   <></>
                    } 
      </div>
      <Dialog disableEnforceFocus
        onKeyPress={this.onKeyUp}
        open={this.state.checkedAdd} 
        onClose={!this.state.checkedAdd}
        aria-labelledby="form-dialog-title"
      >
        
        <DialogActions style={{paddingLeft: 10, paddingTop:10}}>
              <Grid container >
              <Grid item lg={10} md={10} sm={10} xs={10}  style={{alignContent: "flex-start", alignItems: "flex-start", textAlign: "flex-start"}}>
              <DialogTitle id="form-dialog-title">Detalle Usuarios</DialogTitle>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Button variant="contained" onClick={() => this.handleChangeAdd(false, false)} color="secondary">
                  <HighlightOff  style={{ fontSize: "30px" }} /> 
                  </Button>
                  </Grid>
                  </Grid>
              </DialogActions>

              <Tabs
            value={this.state.tabId}
            onChange={(e, id) => this.handleChangeTab(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Datos Personales" value={0}/>
            <Tab label="Permisos" value={1} />
         </Tabs>

              {this.state.tabId != 1 ?
          <>


            <DialogContent style={{padding: "30px 30px 10px 30px"}}>
	
            <Grid container >
            <div style={{width: "100%", justifyContent: 'center'}}>
            <Paper style={{justifyContent: 'center', padding: '10px'}}>

	
                    
              <div style={{justifyContent: 'center',
              '& .MuiTextField-root': {
                  margin: '1px'
              },display: 'flex',
              flexWrap: 'wrap'}}
                  component={Paper} >
<Grid item lg={12} md={12} sm={12} xs={12} style={{margin: '10px'}}>

<FormControlLabel style={{justifyContent: 'start'}} control={<Checkbox checked={this.state.togleedit} disabled={this.state.togleedit ? true: false}
         onChange={this.toggleChange2} />} label="Editar" />

</Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Username"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeUsername}
                value={this.state.username}
                
                />
                
                
                 </Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Nombres"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeNombres}
                value={this.state.nombres}
                
                />
                
                
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Apellidos"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeApellidos}
                value={this.state.apellidos}
                
                />
                
                
                 </Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Teléfono"
                variant= "outlined"
                disabled={this.state.togleedit ? false : true}
                fullWidth
                autoComplete="off"
                onChange={this.onChangeTelefono}
                value={this.state.telefono}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Correo electrónico"
                variant= "outlined"
                disabled={this.state.togleedit ? false : true}
                fullWidth
                autoComplete="off"
                onChange={this.onChangeEmail}
                value={this.state.email}
                />
                 </Grid>


                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
               
<FormControl fullWidth>
        <InputLabel id="demo-simple-select-standard-label">Pais</InputLabel>
<Select 
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="Vsede"
          disabled={this.state.togleedit ? false : true}
          label="Pais"
          value={this.state.iso2}
          onChange={this.onChangePais}
        >
          <MenuItem value="">
            <em>Seleccione un Pais</em>
          </MenuItem>

        
          { paiss.data && paiss.data.map(data =>(
         <MenuItem value={data.iso2} >{data.name}</MenuItem>
         ))
          }
         
        </Select>
        </FormControl>
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
               
                 { this.state.Data2TipoUsuarios != 1 ?
                  <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-standard-label">Tipo Usuarios</InputLabel>
                  <Select 
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            name="Vsede"
                            label="Tipo Usuarios"
                            value={this.state.idTipoUsuarios}
                            onChange={this.onChangeIdTipoUsuario}
                          >
                            <MenuItem value="">
                              <em>Seleccione un Tipo de Usuario</em>
                            </MenuItem>
                   
                            <MenuItem value={2} >Usuario</MenuItem>
                      
                            
                          
          {/* tipo_usuarios && tipo_usuarios.map(data =>(
                     <MenuItem value={data.idTipoUsuarios} >{data.nombre}</MenuItem>
       ))
          */}
                            
                          
                  </Select>
                          </FormControl>
                          :

                         
                            <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Tipo Usuarios</InputLabel>
                            <Select 
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      name="Vsede"
                                      label="Tipo Usuarios"
                                      value={this.state.idTipoUsuarios}
                                      onChange={this.onChangeIdTipoUsuario}
                                    >
                                      <MenuItem value="">
                                        <em>Seleccione un Tipo de Usuario</em>
                                      </MenuItem>
                            
                                     
                                      <MenuItem value={1} >Super Admin</MenuItem>
                                      <MenuItem value={2} >Usuario</MenuItem>
                                      
                                      
                                    
                    {/* tipo_usuarios && tipo_usuarios.map(data =>(
                               <MenuItem value={data.idTipoUsuarios} >{data.nombre}</MenuItem>
                 ))
                    */}
                                      
                                    
                            </Select>
                                    </FormControl>
}
                </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Ciudad"
                variant= "outlined"
                disabled={this.state.togleedit ? false : true}
                fullWidth
                autoComplete="off"
                onChange={this.onChangeCiudad}
                value={this.state.ciudad}
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Contraseña"
                disabled={this.state.togleedit ? false : true}
                variant= "outlined"
                fullWidth
                type="password"
                autoComplete="off"
                onChange={this.onChangeContraseña}
                value={this.state.contraseña}
                />
                 </Grid>


              </div>
              </Paper>
              </div>
              </Grid>
                    </DialogContent>

              <DialogActions style={{padding: 30}}>
                 
                  
              <LoadingButton
                size="small"
                onClick={() => this.handleChangeAdd(false, true, this.state.id)}
                color="secondary"
                loading={this.state.loading}
                loadingPosition="end"
                variant="contained"
              >
                 Actualizar
              </LoadingButton >
              </DialogActions>
              </>
              :
              <div style={{margin: '10px', paddingTop: "20px"}}>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{margin: '10px'}}>

<FormControlLabel style={{justifyContent: 'start'}} control={<Checkbox checked={this.state.togleedit} disabled={this.state.togleedit ? true: false}
         onChange={this.toggleChange2} />} label="Editar" />

</Grid>
                             <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                              <div>
                              <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ minHeight: 200, flexGrow: 1, minWidth: 400, overflowY: 'auto' }}
    >
      <TreeItem nodeId="1" label="Ubicación" style={{margin: '10px'}}>

      <TreeItem nodeId="2" label="Pais" style={{margin: '10px'}}>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedAddPais}
          onChange={this.toggleChangeAddPais} />} label="Registrar Pais" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedListarPais}
          onChange={this.toggleChangeListarPais} />} label="Listar Pais" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEliminarPais}
          onChange={this.toggleChangeEliminarPais} />} label="Eliminar Pais" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEditarPais}
          onChange={this.toggleChangeEditarPais} />} label="Editar Pais" />
      </Grid>
      </TreeItem>
      
      <TreeItem nodeId="3" label="Moneda" style={{margin: '10px'}}>
      
      
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedAddMoneda}
          onChange={this.toggleChangeAddMoneda} />} label="Registrar Moneda" />
      </Grid>
      
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedListarMoneda}
          onChange={this.toggleChangeListarMoneda} />} label="Listar Moneda" />
      </Grid>
  
  
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEliminarMoneda}
          onChange={this.toggleChangeEliminarMoneda} />} label="Eliminar Moneda" />
      </Grid>
  
  
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEditarMoneda}
          onChange={this.toggleChangeEditarMoneda} />} label="Editar Moneda" />
      </Grid>
  
      </TreeItem>

      </TreeItem>

      <TreeItem nodeId="4" label="Usuarios" style={{margin: '10px'}}>

  
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedListarUsuarios}
          onChange={this.toggleChangeListarUsuarios} />} label="Listar Usuario" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEliminarUsuarios}
          onChange={this.toggleChangeEliminarUsuarios} />} label="Eliminar Usuario" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEditarUsuarios}
          onChange={this.toggleChangeEditarUsuarios} />} label="Editar Usuario" />
      </Grid>
     {/* <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEditarUsuarios}
          onChange={this.toggleChange} />} label="Editar Contraseña" />
</Grid>*/}

</TreeItem>

<TreeItem nodeId="5" label="Clientes" style={{margin: '10px'}}>

<Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedAddClientes}
          onChange={this.toggleChangeAddClientes} />} label="Registrar Clientes" />
</Grid>
<Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
    <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedListarClientes}
    onChange={this.toggleChangeListarClientes} />} label="Listar Clientes" />
</Grid>
<Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
    <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEliminarClientes}
    onChange={this.toggleChangeEliminarClientes} />} label="Eliminar Clientes" />
</Grid>
<Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
    <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEditarClientes}
    onChange={this.toggleChangeEditarClientes} />} label="Editar Clientes" />
</Grid>
{/* <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
    <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEditarUsuarios}
    onChange={this.toggleChange} />} label="Editar Contraseña" />
</Grid>*/}

</TreeItem>

<TreeItem nodeId="7" label="App Update" style={{margin: '10px'}}>

<Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedAddAppupdate}
          onChange={this.toggleChangeAddAppupdate} />} label="Registrar App Update" />
</Grid>
<Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
    <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedListarAppupdate}
    onChange={this.toggleChangeListarAppupdate} />} label="Listar App Update" />
</Grid>
<Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
    <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEliminarAppupdate}
    onChange={this.toggleChangeEliminarAppupdate} />} label="Eliminar App Update" />
</Grid>
<Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
    <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEditarAppupdate}
    onChange={this.toggleChangeEditarAppupdate} />} label="Editar App Update" />
</Grid>
{/* <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
    <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEditarUsuarios}
    onChange={this.toggleChange} />} label="Editar Contraseña" />
</Grid>*/}

</TreeItem>

<TreeItem nodeId="6" label="Paquetes" style={{margin: '10px'}}>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedAddPaquetes}
          onChange={this.toggleChangeAddPaquetes} />} label="Registrar Paquetes" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedListarPaquetes}
          onChange={this.toggleChangeListarPaquetes} />} label="Listar Paquetes" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEliminarPaquetes}
          onChange={this.toggleChangeEliminarPaquetes} />} label="Eliminar Paquetes" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox disabled={this.state.togleedit ? false : true} checked={this.state.checkedEditarPaquetes}
          onChange={this.toggleChangeEditarPaquetes} />} label="Editar Paquetes" />
      </Grid>
      </TreeItem>
    </TreeView>
            </div>
               </Grid>  
               <DialogActions style={{padding: 30}}>
                  
              <LoadingButton
                size="small"
                onClick={() => this.savePermisos()}
                endIcon={<SaveIcon />}
                color="secondary"
                loading={this.state.loading}
                loadingPosition="end"
                variant="contained"
              >
                Actualizar
              </LoadingButton >
              </DialogActions>        
                  </div>
         }
              </Dialog>
			</div>
	</React.Fragment>
    );
  }
  
}

const mapStateToProps = (state) => {
  return {
    usuarios: state.usuarios,
    usuariosbyId: state.usuariosbyId,
    paiss: state.paiss,
    tipo_usuarios: state.tipo_usuarios,
    permisosbyid: state.permisosbyid
  };
};

export default connect(mapStateToProps, {
 retrieveUsuario, deleteUsuario, updateUsuario, findUsuariosById, deletePermisos,findPermisosById, retrievePais, deletePermisos, createPermisos, deletePermisosData
})(UsuariosList);

