import React,{ useContext } from "react";
import {
  Route,
  Switch,
  withRouter,
  Redirect
  
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "../themes/styles";

// components
import Header from "../components/Header/Header.components";
import Sidebar from "../components/Sidebar/Sidebar";
import { UserProvider } from "../context/UserContext";
 
// pages
import Dashboard from "../pages/dashboard/Dashboard";


// map with redux
import Error from "../pages/error/Error";


//envios


// context
import { useLayoutState } from "../context/LayoutContext";

import Usuarios from "../pages/usuarios/usuarios/Usuarios";
import Tipo_Usuario from "../pages/usuarios/tipo_usuarios/Tipo_Usuario";
import Editar_Usuario from "../pages/usuarios/usuarios/Editar_Usuario";

import Pais from "../pages/ubicacion/Pais";
import Moneda from "../pages/ubicacion/Moneda";
import Paquetes from "../pages/paquetes/Paquetes";
import Clientes from "../pages/clientes/Cliente";
import Appupdate from "../pages/appupdate/Appupdate";

import Denied from "../pages/denied/Denied";
import { AuthContext } from "../context/AuthContext";
//import { UserProvider } from "../context/UserContext";
import jwt from 'jwt-decode'

//const tken = localStorage.getItem("token")
     
      
      //const decoded =tken != undefined ? jwt(tken) : null

function AdminLayout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

      

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
              <Switch>

                <Route path="/admin/dashboard" component={Dashboard} />           
                  
                   <Route exact path="/admin/system/pais" component={Pais} />
                  <Route exact path="/admin/system/moneda" component={Moneda} />

                  <Route exact path="/admin/usuarios/usuarios" component={Usuarios} />
                  <Route exact path="/admin/clientes/clientes" component={Clientes} />
                  <Route exact path="/admin/system/appupdate" component={Appupdate} />
                  {/*<Route exact path="/admin/usuarios/tipousuario" component={Tipo_Usuario} />
                 <Route exact path="/admin/usuarios/edit/:id" component={Editar_Usuario} />*/}

                  <Route exact path="/admin/paquetes" component={Paquetes} />

                 

                 
                {/*  decoded.role == `629158f43f7d3c4a44b4bca4` ||
                   decoded.role == `629162dd7f0f3e1ca4f883e2` ?
                   <>
                  <Route exact path="/admin/personal/list" component={isSuperAdmin !== false ? ListPersonal : Denied} />
                  <Route exact path="/admin/personal/create" component={isSuperAdmin !== false ? AddPersonal : Denied} />
                  <Route exact path="/admin/personal/edit/:id" component={isSuperAdmin !== false ? EditPersonal : Denied} />
                  <Route exact path="/admin/sede" component={isSuperAdmin !== false ? Sede : Denied} />
                  </>
                  :
          <Route exact component={Denied} />*/}
                
              
              </Switch>
          </div>
        </>
    </div>
  );
}

export default withRouter(AdminLayout);


