import {
  CREATE_PAQUETE,
  RETRIEVE_PAQUETES,
  UPDATE_PAQUETE,
  DELETE_PAQUETE,
} from "../constants/paquete.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];



function paqueteReducer(paquetes = initialState, action) {
  const { type, payload } = action;

  function Refreshdata(){
    window.location.reload(true);
  }

  switch (type) {
    case CREATE_PAQUETE:
      toast.success('Paquete Registrado de manera Éxitosa');
      window.location.reload(true);
     //setInterval(Refreshdata, 1000)
      return [...paquetes, payload];

    case RETRIEVE_PAQUETES: 
      return payload;

    case UPDATE_PAQUETE:
      toast.success('Paquete Actualizado de manera Éxitosa');
      window.location.reload(true);
      ////setInterval(Refreshdata, 1000)
      return {...paquetes, payload};

    case DELETE_PAQUETE:
      toast.success('Paquete Eliminado de manera Éxitosa');
      window.location.reload(true);
      //setInterval(Refreshdata, 100)
      return paquetes.filter(({ id }) => id !== payload.id);

    default:
      return paquetes;
  }
};



export default paqueteReducer;