import {
  CREATE_PERMISO,
  RETRIEVE_PERMISOS,
  UPDATE_PERMISO,
  DELETE_PERMISO,
  PERMISOS_BY_ID,
  DELETE_PERMISO_DATA
} from "../constants/permisos.constants";

import { toast } from 'react-toastify';

import PermisosDataService from "../services/permisos.service";

export const createPermisos = (label, code, idUsuarios, link, icon, type) => async (dispatch) => {
  try {
    const res = await PermisosDataService.create({label, code, idUsuarios, link, icon, type});

    dispatch({
      type: CREATE_PERMISO,
      payload: res.data,
    });
    

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err.message);
  }
};

export const retrievePermisos = () => async (dispatch) => {
  try {
    const res = await PermisosDataService.getAllData();

    dispatch({
      type: RETRIEVE_PERMISOS,
      payload: res.data,
      
    });
    
  } catch (err) {
    console.log(err);
  }
};

export const findPermisosById = (id) => async (dispatch) => {
  try {
    const res = await PermisosDataService.get(id);

    dispatch({
      type: PERMISOS_BY_ID,
      payload: res.data,
    }); 
    
  } catch (err) {
    console.log(err);
  }
};

export const updatePermisos = (id, data) => async (dispatch) => {
  try {
    const res = await PermisosDataService.update(id, data);

    dispatch({
      type: UPDATE_PERMISO,
      payload: data,
    });
    //toast.success('Usuario Actualizado de manera Éxitosa');
     // window.location.reload(true);
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deletePermisos = (id) => async (dispatch) => {
  try {
    await PermisosDataService.delete(id);

    dispatch({
      type: DELETE_PERMISO,
      payload: { id },
    });
  } catch (err) {
    //console.log(err);

    /*if (err.message == "Request failed with status code 400") {
      toast.error('No se puede Eliminar este Usuario, el Id está siendo usada por otra tabla', {
        autoClose: 10000,
      });
    }
    if (err.message == "Request failed with status code 401") {
      toast.error('Sin Autorización');
    }

    if (err.message == "Request failed with status code 505") {
      toast.error('No tiene permisos suficientes para visitar esta página');
    }*/

  }
};

export const deletePermisosData = (id, code) => async (dispatch) => {
  try {
    await PermisosDataService.delete2(id, code);

    dispatch({
      type: DELETE_PERMISO_DATA,
      payload: { id },
    });
  } catch (err) {
    //console.log(err);

   /* if (err.message == "Request failed with status code 400") {
      toast.error('No se puede Eliminar este Usuario, el Id está siendo usada por otra tabla', {
        autoClose: 10000,
      });
    }
    if (err.message == "Request failed with status code 401") {
      toast.error('Sin Autorización');
    }

    if (err.message == "Request failed with status code 505") {
      toast.error('No tiene permisos suficientes para visitar esta página');
    }*/

  }
};
