import React, { Component } from "react";
import { connect } from "react-redux"; 

import {
  createMoneda
} from "../../actions/moneda.actions";

import { toast } from 'react-toastify';

import { format } from 'date-fns'

import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import {Grid, Divider, Paper, TextField} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
//import UAParser from 'ua-parser-js'
//import  ua  from 'react-useragent';
import DeviceDetector from "device-detector-js";
import {getUA} from 'react-device-detect'
import jwt from 'jwt-decode'
//const DeviceDetector = require('node-device-detector');
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';


class MonedaForm extends Component {
  constructor(props) {
    super(props);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.onChangeIdMoneda = this.onChangeIdMoneda.bind(this);
    this.onChangeDescripcion = this.onChangeDescripcion.bind(this);
    this.onChangeSimbolo = this.onChangeSimbolo.bind(this);
   // this.handleChangeSede = this.handleChangeSede.bind(this);
    this.saveMoneda = this.saveMoneda.bind(this);
     
    this.state = {
        checkedAdd: false,
        id: null,
        simbolo: "",
        idmoneda: "",
        descripcion: "",
        Psimbolo: "",
        Pidmoneda: "",
        Pdescripcion: "",
      loading: false,
    }

  }

  componentDidMount() {

    const { Datasimbolo} = this.props 
    const {Datadescripcion} = this.props 
    const {DataidMoneda} = this.props 

    this.setState({
        idmoneda: this.props.DataidMoneda,
        simbolo: this.props.Datasimbolo,
        descripcion: this.props.Datadescripcion,
      });
   // this.props.retrieveIngresos();
   
  }

  componentDidUpdate(prevProps, prevState) {
    //let newName = 'Obaseki Nosa'

    const { Datasimbolo} = this.props 
    const {Datadescripcion} = this.props 
    const {DataidMoneda} = this.props 
    // Don't forget to compare states
    if (prevState && prevState.simbolo !== Datasimbolo) {
      this.setState({simbolo: Datasimbolo});
    }

    if (prevState && prevState.descripcion !== Datadescripcion) {
        this.setState({descripcion: Datadescripcion});
      }

      if (prevState && prevState.idmoneda !== DataidMoneda) {
        this.setState({idmoneda: DataidMoneda});
      }

  }

  onChangeIdMoneda(e) {
    const idmoneda = e.target.value.toUpperCase();
    
    this.setState({
      idmoneda: idmoneda,
    });
  }

  onChangeDescripcion(e) {
    const descripcion = e.target.value.toUpperCase();
    
    this.setState({
      descripcion: descripcion,
    });
  }

  onChangeSimbolo(e) {
    const simbolo = e.target.value.toUpperCase();
    
    this.setState({
      simbolo: simbolo,
    });
  }

  handleChangeAdd(checkedAdd){
    this.setState({ 
        checkedAdd
      });
      

      
  } 
  saveMoneda(loading) {
    const { idmoneda, descripcion, simbolo} = this.state;
    if (loading == true) {
      this.setState({
        loading
      });
  this.props
      .createMoneda(idmoneda, descripcion, simbolo)
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        
        //console.log(e);
        //this.props.history.push("/admin/cliente")
        /*if(e == "Request failed with status code 441" ){
          toast.error('Error, '+VAL441);
        }*/

        console.log("err: ", e)

        if (e.message == "Sin Autorización") {
          toast.error('Error, Sin Autorización' );
          this.setState({
            loading: false
          });
        }

        if(e == "Request failed with status code 500"){
          toast.error('Error, 500 internal');
          this.setState({
            loading: false
          });
        }
      });
    }
  }

  

  

  render() {
  
    const { Datasimbolo} = this.props 
    const {Datadescripcion} = this.props 
    const {DataidMoneda} = this.props 

    this.state.Pdescripcion = Datadescripcion
    this.state.Psimbolo = Datasimbolo
    this.state.Pidmoneda = DataidMoneda
    
    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
      if (!tken) return false

      const decoded = jwt(tken); 
    //console.log("user", data.id);

    return (
        <React.Fragment >
          
            <div>  

<DialogContent style={{padding: "0px 30px 10px 30px"}}>

            <Grid container >
            <div style={{width: "100%", justifyContent: 'center'}}>
            <Paper style={{justifyContent: 'center', padding: '10px'}}>

	
                    
              <div style={{justifyContent: 'center',
              '& .MuiTextField-root': {
                  margin: '1px'
              },display: 'flex',
              flexWrap: 'wrap'}}
                  component={Paper} >

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Código"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeIdMoneda}
                value={this.state.idmoneda}
                
                />
                
                
                 </Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Descripción"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeDescripcion}
                value={this.state.descripcion}
                
                />
                
                
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Simbolo"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeSimbolo}
                value={this.state.simbolo}
                
                />
                
                 </Grid>
              </div>
              </Paper>
              </div>
              </Grid>
              </DialogContent>

              <DialogActions style={{padding: 30}}>
                
                  
              <LoadingButton
                size="small"
                onClick={() => this.saveMoneda(true)}
                color="secondary"
                loading={this.state.loading}
                loadingPosition="end"
                variant="contained"
              >
                Actualizar
              </LoadingButton >
              </DialogActions>

              </div>
                </React.Fragment>
                  );
                }
                
              }

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {
  createMoneda
})(MonedaForm);

