import {
  APPUPDATES_BY_ID
} from "../constants/appupdate.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];


function appupdate2Reducer(appupdatesbyid = initialState, action) {
  const { type, payload } = action;


  switch (type) {
    

    case APPUPDATES_BY_ID: 
      return payload;

    default:
      return appupdatesbyid;
  }
};



export default appupdate2Reducer;