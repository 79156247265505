import {http} from "../utils/api";

const config = {
  headers: {
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }
};

class TipoUsuarioDataService { 

  getAllData() {
    return http.get("/tipousuario", config);
  }

  get(id) {
    return http.get(`/tipousuario/${id}`, config);
  }

  create(data) {
    return http.post("/tipousuario/register", data, config);
  }

  update(id, data) {
    return http.put(`/tipousuario/${id}`, data, config);
  }

  delete(id) {
    return http.delete(`/tipousuario/${id}`,config);
  }
}

export default new TipoUsuarioDataService();