
import React, { Component } from "react";
import { connect } from "react-redux";


import MaterialTable,{ MTableToolbar }  from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import jwt from 'jwt-decode'
import {Button, Chip, Icon, Checkbox} from '@material-ui/core';
import {Grid, Divider, Paper} from "@material-ui/core";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from 'react-toastify';
import DialogContentText from '@mui/material/DialogContentText';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {TextField} from "@material-ui/core";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import HighlightOff from '@material-ui/icons/HighlightOff';
import ReorderIcon from '@mui/icons-material/Reorder';
import InputLabel from '@mui/material/InputLabel';
import {
  retrieveCliente, deleteCliente, updateCliente, findClientesById
} from "../../actions/cliente.actions"; 
import {
  findPermisosById
} from "../../actions/permisos.actions";

import MonedaDialog from "../Dialog/moneda.dialog";
import MonedaForm from "../form/moneda.form";
import {isEmail, isEmpty,isLength, equals} from 'validator';
import ClienteDialog from "../Dialog/cliente.dialog";

class ClienteList extends Component {
  constructor(props) {
    super(props);

    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyUp2 = this.onKeyUp.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleChangeDelete = this.handleChangeDelete.bind(this);

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeMobilePhone = this.onChangeMobilePhone.bind(this);
    this.onChangeEMail = this.onChangeEMail.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangeLogin = this.onChangeLogin.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.handleChangeData = this.handleChangeData.bind(this)
     
    this.state = {
        checkedAdd: true ? false : true,
        checkedEdit: true ? false : true,
        checkedFilter: true ? false : true,
        checkedDelete: false,
        deleted : false,
        updated: false,
        id: "",
        MobilePhone: "", 
        Name: "", 
        Lastname: "", 
        Status: "",
        EMail: "", 
        City: "", 
        Login: "",
        Country: "", 
        password: "",
        togleedit: false,
        varData: false,

        /* ------------------------*/
        idPermisosAddCliente: "",
        idPermisosEditCliente: "",
        idPermisosEliminarCliente: "",
        idPermisosListCliente: "",

    }

  }

  componentDidMount() {


    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
    if (!tken) return false
    const decoded = jwt(tken); 
    
    
    //console.log("idUsuarios", decoded.idUsuarios)
    this.props.findPermisosById(decoded.idUsuarios)
  
  }

  componentDidUpdate(prevProps, prevState) {
    //let newName = 'Obaseki Nosa'

    const { permisosbyid} = this.props 
    // Don't forget to compare states
    
    if (prevState && prevState.datapermisosbyid !== permisosbyid) {
      permisosbyid.data && permisosbyid.data.map (permisos => {
      //console.log("permisosbyid", permisosbyid.code)
      if(permisos.code == "5003"){
       
        this.state.idPermisosListCliente = permisos.code;
    }
  })
    
  }
  }

  handleChangeData(varData) {
    this.setState({ 
      varData
    });
    if(this.state.idPermisosListCliente == "5003"){
      this.props.retrieveCliente();
    }
  }


  handleChangeFilter(checkedFilter){
    this.setState({ 
        checkedFilter
      });

      
  }

  onChangeName(e) {
    const Name = e.target.value.toUpperCase();
    
    this.setState({
      Name: Name,
    });
  }

  onChangeStatus(e) {
    const Status = e.target.value;
    
    this.setState({
      Status: Status,
    });
  }

  onChangeLastName(e) {
    const Lastname = e.target.value.toUpperCase();
    
    this.setState({
      Lastname: Lastname,
    });
  }

  onChangeEMail(e) {
    const EMail = e.target.value;
    
    this.setState({
      EMail: EMail,
    });
  }

  onChangeMobilePhone(e) {
    const MobilePhone = e.target.value;
    
    this.setState({
      MobilePhone: MobilePhone,
    });
  }

  onChangeCity(e) {
    const City = e.target.value;
    
    this.setState({
      City: City,
    });
  }

  onChangeLogin(e) {
    const Login = e.target.value;
    
    this.setState({
      Login: Login,
    });
  }

  onChangeCountry(e) {
    const Country = e.target.value;
    
    this.setState({
      Country: Country,
    });
  }

  onChangePassword(e) {
    const password = e.target.value;
    
    this.setState({
      password: password,
    });
  }
 
  onKeyUp(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.handleChangeAdd(false, true, this.state.id);
    }
  }

  onKeyUp2(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.handleChangeDelete(false, true, this.state.id);
    }
  }

  handleChangeAdd(checkedAdd, updated, id){
    this.state.id = id
    if(this.state.id){
    this.props.findClientesById(id);}
    else{
      console.log("cerrado")
    }
    
      //console.log("id2: ", id)
      //this.handleChangeEdit(this.state.id)

      if(checkedAdd== true && updated == false){
       
          this.setState({
            checkedAdd, 
          })


        //console.log("ABIERTO")
      }
      if(checkedAdd== false && updated == false){
        this.setState({ 
          checkedAdd,
          togleedit: false
        });
        
        //console.log("CERRADO")
      }
  
      if(checkedAdd== false && updated == true){
       /* this.setState({ 
          checkedDelete,
        });
        console.log("ELIMINADO ID", id)
      
      }*/

     
      var dataUp = { 
        id: this.state.id,
        Status: this.state.Status,
        MobilePhone: this.state.MobilePhone, 
        Name: this.state.Name, 
        Lastname: this.state.Lastname, 
        EMail: this.state.EMail, 
        City: this.state.City, 
        Country: this.state.Country, 
        Login: this.state.Login, 
        password: this.state.password
      };
    
      this.props
     .updateCliente(id, dataUp)
     .then((data) => {
      this.setState({
        id: data.id,
        Status: data.Status,
        MobilePhone: data.MobilePhone, 
        Name: data.Name, 
        Lastname: data.Lastname, 
        EMail: data.EMail, 
        City: data.City, 
        Country: data.Country, 
        Login: data.Login,
        password: data.password
     // this.props.history.push("/tutorials");
      })
    })
    .catch((e) => {
      console.log(e);
    });	 
       
  }
}


toggleChange2 = () => {
  this.setState({
    togleedit: !this.state.togleedit,
  });

}

  handleChangeDelete(checkedDelete, deleted, id){


    this.state.id = id
    if(checkedDelete== true && deleted == false){
      this.setState({ 
        checkedDelete,
      });
      //this.toggleChange2(true);
      //console.log("ABIERTO")
    }
    if(checkedDelete== false && deleted == false){
      this.setState({ 
        checkedDelete,
      });
      //console.log("CERRADO")
    }

    if(checkedDelete== false && deleted == true){
     /* this.setState({ 
        checkedDelete,
      });
      console.log("ELIMINADO ID", id)
    }*/
    this.props.deleteCliente(id)
   .then(() => {
   // this.props.history.push("/tutorials");
  })
  .catch((e) => {
    //console.log(e);

    console.log("err: ", e)
        
  });	 
   
 }
}
  


  render() {
    const {clientes} = this.props;
    const {clientesbyId} = this.props;
    const {permisosbyid} = this.props;
    if(!this.state.togleedit)
    {

      clientesbyId.data && clientesbyId.data.map (clientes => {
    this.state.MobilePhone = `${clientes.MobilePhone}` 
    this.state.Name = `${clientes.Name}`
    this.state.Lastname = `${clientes.LastName}`
    this.state.EMail = `${clientes.EMail}`
    this.state.City = `${clientes.City}`
    this.state.Country = `${clientes.Country}` 
    this.state.password = `${clientes.password}`
    this.state.Login = `${clientes.Login}`
    this.state.Status = `${clientes.status}`
    })
  }
      

    permisosbyid.data && permisosbyid.data.map (permisos => {
      if(permisos.code == "5003") {this.state.idPermisosListCliente = "5003"}
        if(permisos.code == "5001") {this.state.idPermisosAddCliente = "5001"}
        if(permisos.code == "5002") {this.state.idPermisosEditCliente = "5002"}
        if(permisos.code == "5004") {this.state.idPermisosEliminarCliente = "5004"}
    }
     // console.log("permisos", permisos)
  
)
   this.state.togle = true
    return (
        <React.Fragment >
         
			<div style={{width: "100%", justifyContent: 'center'}}>
      <Grid container >
     
       { equals(this.state.idPermisosAddCliente, "5001") ?
       <>
       <Grid item lg={8} md={8} sm={8} xs={12} >
       <ClienteDialog/>
      </Grid>
        </>
        :
        <></>
      }
        { equals(this.state.idPermisosListCliente, "5003") ? 
        <>
   
    <Grid item lg={4} md={4} sm={4} xs={12} style={{width: "100%", justifyContent: 'end', alignContent: 'flex-end', alignItems: 'flex-end'}}>
    <Button color="primary" onClick={() => this.handleChangeData(true)} >
          <ReorderIcon style={{ fontSize: "40px" }} /> <p style={{ fontSize: "15px" }}>{this.state.varData ? "Volver a listar Cliente" : "Listar Cliente"}</p>
      </Button>
        </Grid>
        </>
        :
        <></>
    }
     </Grid>
      <Dialog disableEnforceFocus
      onKeyPress={this.onKeyUp2}
          open={this.state.checkedDelete} 
          onClose={!this.state.checkedDelete}
          aria-labelledby="form-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Eliminar Cliente
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           ¿Está seguro que quiere eliminar este cliente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button variant="contained" onClick={() => this.handleChangeDelete(false, false)} color="primary">
                      No
          </Button>
          <Button variant="contained" color="secondary" onClick={() => this.handleChangeDelete(false, true, this.state.id)}>Si</Button>
        </DialogActions>
        </Dialog>
      <div className="list row">

      { equals(this.state.idPermisosListCliente, "5003") ?
      <Paper style={{justifyContent: 'center', width: "100%", padding: '10px'}}>
 
      <Grid container >
   
   <Grid item lg={5} md={5} sm={5} xs={12}  style={{margin: '10px'}}>
   
     </Grid>
     <Grid item lg={3} md={3} sm={3} xs={12}  style={{margin: '10px'}}>
 
 </Grid>
 </Grid>

<MaterialTable
                 title="Listado de Clientes"
                 columns={ [
                  { title: 'Login', field: 'Login' },
                  { title: 'Contraseña', field: 'password' },
                  { title: 'Celular', field: 'MobilePhone' },
                  { title: 'Correo Electrónico', field: 'EMail' },
                  { title: 'Ciudad', field: 'City' },
                  { title: 'Pais', field: 'Country' },
                  { title: 'Estatus', field: 'status' }
                 ]
               
              }
                 
                 data={clientes.data && clientes.data.map(clientes =>(clientes))}
                         
                 options={{
                   filtering: this.state.checkedFilter,
                   exportButton: true,
                   exportAllData: true,
                   actionsColumnIndex: -1,
                   pageSize: 10,
                   pageSizeOptions: [10, 50, 100]
                 }}
                 
                 actions={ this.state.idPermisosEliminarCliente !="5004" && this.state.idPermisosEditCliente !="5002" ?
                 []
                 :[

                  rowData => (
                    {
                      icon: VisibilityIcon,
                      tooltip: 'Detalle Cliente',
                      onClick:((event, rowData) => this.handleChangeAdd(true, false, rowData.Login )),
                      hidden: this.state.idPermisosEditCliente !="5002"
                  }),
                        
                    rowData =>({
                      icon: DeleteIcon,
                      tooltip: 'Eliminar Cliente',
                      onClick: ((event, rowData)  => this.handleChangeDelete(true, false, rowData.Login)),
                      hidden: this.state.idPermisosEliminarCliente !="5004"
                    }
                  )
                  
                   
                  ]}

                  components={{
                    Toolbar: props => (
                      <div>
                        <MTableToolbar {...props} />
                        <Chip icon={this.state.checkedFilter? <FilterAltOffIcon /> : <FilterAltIcon />} label="Filtrar" color="secondary" onClick={() => this.handleChangeFilter(!this.state.checkedFilter)} style={{marginLeft: 20, marginTop: 10}}/>
                      </div>
                    )
                  }}
               />
              
               
                </Paper> 
                 :
                 <></>
  
                 }   
      </div>
      <Dialog disableEnforceFocus
        onKeyPress={this.onKeyUp}
        open={this.state.checkedAdd} 
        onClose={!this.state.checkedAdd}
        aria-labelledby="form-dialog-title"
      >
        
        <DialogActions style={{paddingLeft: 10, paddingTop:10}}>
              <Grid container >
              <Grid item lg={10} md={10} sm={10} xs={10}  style={{alignContent: "flex-start", alignItems: "flex-start", textAlign: "flex-start"}}>
              <DialogTitle id="form-dialog-title">Detalle Cliente</DialogTitle>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Button variant="contained" onClick={() => this.handleChangeAdd(false, false)} color="secondary">
                  <HighlightOff  style={{ fontSize: "30px" }} /> 
                  </Button>
                  </Grid>
                  </Grid>
              </DialogActions>

            <DialogContent style={{padding: "30px 30px 10px 30px"}}>
	
            <Grid container >
            <div style={{width: "100%", justifyContent: 'center'}}>
            <Paper style={{justifyContent: 'center', padding: '10px'}}>

	
                    
              <div style={{justifyContent: 'center',
              '& .MuiTextField-root': {
                  margin: '1px'
              },display: 'flex',
              flexWrap: 'wrap'}}
                  component={Paper} >

<Grid item lg={12} md={12} sm={12} xs={12} style={{margin: '10px'}}>

<FormControlLabel style={{justifyContent: 'start'}} control={<Checkbox checked={this.state.togleedit} disabled={this.state.togleedit ? true: false}
         onChange={this.toggleChange2} />} label="Editar" />

</Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Nombres"
                disabled={this.state.togleedit && this.state.Status != 0 ? false : true}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeName}
                value={this.state.Name}
                />   
</Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                disabled={this.state.togleedit && this.state.Status != 0 ? false : true}
                name="Vfechareg"
                label="Apellido"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeLastName}
                value={this.state.Lastname}
                
                />
                 </Grid>
                 

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Celular"
                disabled={this.state.togleedit && this.state.Status != 0 ? false : true}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeMobilePhone}
                value={this.state.MobilePhone}
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Correo Electrónico"
                disabled={this.state.togleedit && this.state.Status != 0 ? false : true}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeEMail}
                value={this.state.EMail}
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Ciudad"
                disabled={this.state.togleedit && this.state.Status != 0? false : true}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeCity}
                value={this.state.City}
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Pais"
                disabled={true}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeCountry}
                value={this.state.Country}
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
               
               <FormControl fullWidth>
                       <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
               <Select 
                         labelId="demo-simple-select-standard-label"
                         id="demo-simple-select-standard"
                         name="Vstatus"
                         label="Status"
                         value={this.state.Status}
                         onChange={this.onChangeStatus}
                       >
                         <MenuItem value="">
                           <em>Seleccione un Status</em>
                         </MenuItem>
               
                       
                         
                        <MenuItem value="0" >Inactivo</MenuItem>
                        <MenuItem value="1" >Activo</MenuItem>
                      
                        
                       
                       </Select>
                       </FormControl>
                                </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Usuario"
                disabled={true}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeLogin}
                value={this.state.Login}
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Contraseña"
                disabled={this.state.togleedit && this.state.Status != 0? false : true}
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangePassword}
                value={this.state.password}
                />
                 </Grid>
              </div>
              </Paper>
              </div>
              </Grid>
            </DialogContent>
            

            
              <DialogActions style={{padding: 30}}>
              <LoadingButton
                size="small"
                onClick={() => this.handleChangeAdd(false, true, this.state.id)}
                color="secondary"
                loading={this.state.loading}
                loadingPosition="end"
                variant="contained"
                
              >
                Actualizar
          </LoadingButton >
            </DialogActions>

            

           {/* <MonedaForm Datasimbolo={this.state.Psimbolo} Datadescripcion={this.state.Pdescripcion} DataidMoneda={this.state.Pidmoneda}/>
            */}

              </Dialog> 
			</div>
	</React.Fragment>
    );
  }
  
}

const mapStateToProps = (state) => {
  return {
    clientes: state.clientes,
    clientesbyId: state.clientesbyId,
    permisosbyid: state.permisosbyid
  };
};

export default connect(mapStateToProps, {
 retrieveCliente, deleteCliente, updateCliente, findClientesById, findPermisosById
})(ClienteList);

