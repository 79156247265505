

import React, { Component } from "react";
import { connect } from "react-redux"; 

import {
  createCliente
} from "../../actions/cliente.actions";

import {
  retrievePais
} from "../../actions/pais.actions";
import { toast } from 'react-toastify';
import {Grid, Divider, Paper, TextField} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import jwt from 'jwt-decode'
//const DeviceDetector = require('node-device-detector');
import 'date-fns';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

class ClienteDialog extends Component {
  constructor(props) {
    super(props);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
   // this.handleChangeSede = this.handleChangeSede.bind(this);
    this.saveCliente = this.saveCliente.bind(this);

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeMobilePhone = this.onChangeMobilePhone.bind(this);
    this.onChangeEMail = this.onChangeEMail.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangeLogin = this.onChangeLogin.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
     
    this.state = {
        checkedAdd: false,
        id: "",
        MobilePhone: "", 
        Name: "", 
        Lastname: "", 
        Status: "",
        EMail: "", 
        City: "", 
        Login: "",
        Country: "", 
        password: "",
      loading: false,
    }

  }

  componentDidMount() {
   // this.props.retrieveIngresos();
   
  }

  onChangeName(e) {
    const Name = e.target.value;
    
    this.setState({
      Name: Name,
    });
  }

  onChangeStatus(e) {
    const Status = e.target.value;
    
    this.setState({
      Status: Status,
    });
  }

  onChangeLastName(e) {
    const Lastname = e.target.value;
    
    this.setState({
      Lastname: Lastname,
    });
  }

  onChangeEMail(e) {
    const EMail = e.target.value;
    
    this.setState({
      EMail: EMail,
    });
  }

  onChangeMobilePhone(e) {
    const MobilePhone = e.target.value;
    
    this.setState({
      MobilePhone: MobilePhone,
    });
  }

  onChangeCity(e) {
    const City = e.target.value;
    
    this.setState({
      City: City,
    });
  }

  onChangeLogin(e) {
    const Login = e.target.value;
    
    this.setState({
      Login: Login,
    });
  }

  onChangeCountry(e) {
    const Country = e.target.value;
    
    this.setState({
      Country: Country,
    });
  }

  

  onChangePassword(e) {
    const password = e.target.value;
    
    this.setState({
      password: password,
    });
  }
 

  onKeyUp(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.saveMoneda(true);
    }
  }

  

  handleChangeAdd(checkedAdd){
    this.props.retrievePais()
    this.setState({ 
        checkedAdd
      });
      

      
  } 
  saveCliente(loading) {
    const { Login, Status,MobilePhone, Name, Lastname, EMail, City, Country, password} = this.state;
    if (loading == true) {
      this.setState({
        loading
      });
  this.props
      .createCliente(Login, Status,MobilePhone, Name, Lastname, EMail, City, Country, password)
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        
        //console.log(e);
        //this.props.history.push("/admin/cliente")
        /*if(e == "Request failed with status code 441" ){
          toast.error('Error, '+VAL441);
        }*/

        console.log("err: ", e)

        if (e.message == "Sin Autorización") {
          toast.error('Error, Sin Autorización' );
          this.setState({
            loading: false
          });
        }

        if(e == "Request failed with status code 500"){
          toast.error('Error, 500 internal');
          this.setState({
            loading: false
          });
        }
      });
    }
  }

  

  

  render() {
  
   
    const { paiss} = this.props;
    
    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
      if (!tken) return false

      const decoded = jwt(tken); 
    //console.log("user", data.id);

    return (
        <React.Fragment >
          
            <div>

      <Button color="primary" onClick={() => this.handleChangeAdd(true)} >
          <AddCircleIcon style={{ fontSize: "40px" }} /> <p style={{ fontSize: "15px" }}>Nueva Cliente</p>
      </Button>
      <Dialog disableEnforceFocus

        open={this.state.checkedAdd} 
        onClose={!this.state.checkedAdd}
        aria-labelledby="form-dialog-title"
        onKeyPress={this.onKeyUp}
      >
        
            <DialogTitle id="form-dialog-title" style={{padding: "10px 10px 0px 10px"}}>Nueva Cliente</DialogTitle>

            <DialogContent style={{padding: "30px 30px 10px 30px"}}>
	
            <Grid container >
            <div style={{width: "100%", justifyContent: 'center'}}>
            <Paper style={{justifyContent: 'center', padding: '10px'}}>

	
                    
              <div style={{justifyContent: 'center',
              '& .MuiTextField-root': {
                  margin: '1px'
              },display: 'flex',
              flexWrap: 'wrap'}}
                  component={Paper} >


<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Nombres"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeName}
                value={this.state.Name}
                />   
</Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Apellido"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeLastName}
                value={this.state.Lastname}
                
                />
                 </Grid>
                 

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Celular"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeMobilePhone}
                value={this.state.MobilePhone}
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Correo Electrónico"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeEMail}
                value={this.state.EMail}
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Ciudad"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeCity}
                value={this.state.City}
                />
                 </Grid>

             

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
               
               <FormControl fullWidth>
                       <InputLabel id="demo-simple-select-standard-label">Pais</InputLabel>
               <Select 
                         labelId="demo-simple-select-standard-label"
                         id="demo-simple-select-standard"
                         name="Vpais"
                         label="Pais"
                         value={this.state.Country}
                         onChange={this.onChangeCountry}
                       >
                         <MenuItem value="">
                           <em>Seleccione un Pais</em>
                         </MenuItem>
               
                       
                         { paiss.data && paiss.data.map(data =>(
                        <MenuItem value={data.name} >{data.name}</MenuItem>
                        ))
                         }
                        
                       
                       </Select>
                       </FormControl>
                                </Grid>


                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
               
               <FormControl fullWidth>
                       <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
               <Select 
                         labelId="demo-simple-select-standard-label"
                         id="demo-simple-select-standard"
                         name="Vstatus"
                         label="Status"
                         value={this.state.Status}
                         onChange={this.onChangeStatus}
                       >
                         <MenuItem value="">
                           <em>Seleccione un Status</em>
                         </MenuItem>
               
                       
                         
                        <MenuItem value="0" >Inactivo</MenuItem>
                        <MenuItem value="1" >Activo</MenuItem>
                      
                        
                       
                       </Select>
                       </FormControl>
                                </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Usuario"
                
               
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeLogin}
                value={this.state.Login}
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Contraseña"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangePassword}
                value={this.state.password}
                />
                 </Grid>
              </div>
              </Paper>
              </div>
              </Grid>
                    </DialogContent>

              <DialogActions style={{padding: 30}}>
                  <Button variant="contained" onClick={() => this.handleChangeAdd(false)} color="primary">
                      Cancelar
                  </Button>
                  
              <LoadingButton
                size="small"
                onClick={() => this.saveCliente(true)}
                endIcon={<SaveIcon />}
                color="secondary"
                loading={this.state.loading}
                loadingPosition="end"
                variant="contained"
              >
                Guardar
              </LoadingButton >
              </DialogActions>

              </Dialog>
              </div>
                </React.Fragment>
                  );
                }
                
              }

const mapStateToProps = (state) => {
  return {
    paiss: state.paiss
  };
};

export default connect(mapStateToProps, {
  retrievePais, createCliente
})(ClienteDialog);

