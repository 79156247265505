import {
  CREATE_APPUPDATE,
  RETRIEVE_APPUPDATES,
  UPDATE_APPUPDATE,
  DELETE_APPUPDATE,
  APPUPDATES_BY_ID
} from "../constants/appupdate.constants";

import { toast } from 'react-toastify';
 
import AppupdateDataService from "../services/appupdate.service";

export const createAppupdate = (Nombre, version, Url, FechaPublish, SO) => async (dispatch) => {
  try {
    const res = await AppupdateDataService.create({ Nombre, version, Url, FechaPublish, SO});

    dispatch({
      type: CREATE_APPUPDATE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err.message);
  }
};

export const retrieveAppupdate = () => async (dispatch) => {
  try {
    const res = await AppupdateDataService.getDataAll();

    dispatch({ 
      type: RETRIEVE_APPUPDATES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const findAppupdateById = (id) => async (dispatch) => {
  try {
    const res = await AppupdateDataService.get(id);

    dispatch({
      type: APPUPDATES_BY_ID,
      payload: res.data,
    }); 
    
  } catch (err) {
    console.log(err);
  }
};

export const updateAppupdate = (id, data) => async (dispatch) => {
  try {
    const res = await AppupdateDataService.update(id, data);

    dispatch({
      type: UPDATE_APPUPDATE,
      payload: data,
    });
    //toast.success('Appupdate Actualizada de manera Éxitosa');
    //window.location.reload(true);
    return Promise.resolve(res.data);
  } catch (err) {
    console.log("err", err)
    return Promise.reject(err);
  }
};

export const deleteAppupdate = (id) => async (dispatch) => {
  try {
    await AppupdateDataService.delete(id);
    
    dispatch({
      type: DELETE_APPUPDATE,
      payload: { id },
    });
    toast.success('Appupdate Eliminado de manera Éxitosa');
    window.location.reload(true);
  } catch (err) {

    if (err.message == "Request failed with status code 400") {
      toast.error('No se puede Eliminar este Appupdate, el Id está siendo usada por otra tabla', {
        autoClose: 10000,
      });
    }
    if (err.message == "Request failed with status code 401") {
      toast.error('Sin Autorización');
    }

    if (err.message == "Request failed with status code 505") {
      toast.error('No tiene permisos suficientes para visitar esta página');
    }

    //console.log(err);
  }
};
