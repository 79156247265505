import {
  CREATE_TIPO_USUARIO,
  RETRIEVE_TIPO_USUARIOS,
  UPDATE_TIPO_USUARIO,
  DELETE_TIPO_USUARIO
} from "../constants/tipo_usuario.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];



function tipo_usuarioReducer(tipo_usuarios = initialState, action) {
  const { type, payload } = action;

  function Refreshdata(){
    window.location.reload(true);
  }

  switch (type) {
    case CREATE_TIPO_USUARIO:
      toast.success('tipo usuario Registrada de manera Éxitosa');
      window.location.reload(true);
     //setInterval(Refreshdata, 1000)
      return [...tipo_usuarios, payload];

    case RETRIEVE_TIPO_USUARIOS: 
      return payload;

    case UPDATE_TIPO_USUARIO:
      toast.success('tipo usuario Actualizada de manera Éxitosa');
      window.location.reload(true);
      //setInterval(Refreshdata, 1000)
      return {...tipo_usuarios, payload};

    case DELETE_TIPO_USUARIO:
      toast.success('tipo usuario Eliminada de manera Éxitosa');
      window.location.reload(true);
      //setInterval(Refreshdata, 100)
      return tipo_usuarios.filter(({ id }) => id !== payload.id);

    default:
      return tipo_usuarios;
  }
};



export default tipo_usuarioReducer;