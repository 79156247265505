import React, { Component} from "react";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { TextField, Paper, Grid, Button} from "@material-ui/core";

import {
  retrieveUsuario, deleteUsuario, updateUsuario, findUsuariosById
} from "../../../actions/usuario.actions"; 

/*
import { 
  retrieveDataSede
} from "../../../actions/pais.actions"; */
import jwt from 'jwt-decode'
/*import {
  retrieveRole
} from "../../../actions/role.actions";*/
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import PageTitle from "../../../components/PageTitle/PageTitle";


  const Label = ({children}) => <span style={{fontSize: 12}}>{children}</span>;

  const nodes = [
    {
      value: 'mars',
      label: <Label>Mars</Label>,
      children: [
          { value: 'phobos', label: <Label>Phobos</Label> },
          { value: 'deimos', label: <Label>Deimos</Label> },
      ],
    },
    {
      value: 'earth',
      label: <Label>Earth</Label>,
      children: [
          { 
            value: 'USA', 
            label: <Label>USA</Label>,
            children: [
                { value: 'newyork', label: <Label>New York</Label> },
                { value: 'sanfran', label: <Label>San Francisco</Label> },
            ],
          },
          { value: 'China', label: <Label>China</Label> },
      ],
    }
  ];
  

class EditUsuarios extends Component {

  //static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.onChangeNombres = this.onChangeNombres.bind(this);
    this.onChangeApellidos = this.onChangeApellidos.bind(this);
    this.onChangeContraseña = this.onChangeContraseña.bind(this);
    this.onChangeTelefono = this.onChangeTelefono.bind(this);
    this.onChangeFechaRegistro = this.onChangeFechaRegistro.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeCiudad = this.onChangeCiudad.bind(this);
    this.onChangePais = this.onChangePais.bind(this);
    this.onChangeIdTipoUsuario = this.onChangeIdTipoUsuario.bind(this);

    this.state = {
      checkedAdd: true ? false : true,
      id: "",
      nombres: "",
      apellidos: "",
      telefono: "",
      email: "",
      fechaRegistro: "",
      iso2: "",
      idTipoUsuarios: 1,
      ciudad: "",

      Pid: "",
      Pnombres: "",
      Papellidos: "",
      Ptelefono: "",
      Pemail: "",
      PfechaRegistro: "",
      Piso2: "",
      PidTipoUsuarios: 1,
      Pciudad: "",

    };
  }

  componentDidMount() {

    this.findBy(this.props.match.params.id);

  }

  findBy(id) {
    this.props.findUsuariosById(id);
  }

  

  onChangeNombres(e) {
    const nombres = e.target.value.toUpperCase();
    
    this.setState({
      nombres: nombres,
    });
  }

  onChangeApellidos(e) {
    const apellidos = e.target.value.toUpperCase();
    
    this.setState({
      apellidos: apellidos,
    });
  }

  onChangeIdTipoUsuario(e) {
    const idTipoUsuarios = e.target.value;
    
    this.setState({
      idTipoUsuarios: idTipoUsuarios,
    });
  }

  onChangePais(e) {
    const iso2 = e.target.value;
    
    this.setState({
      iso2: iso2,
    });
  }

  onChangeCiudad(e) {
    const ciudad = e.target.value;
    
    this.setState({
      ciudad: ciudad,
    });
  }
  onChangeTelefono(e) {
    const telefono = e.target.value.toUpperCase();
    
    this.setState({
      telefono: telefono,
    });
  }
  onChangeEmail(e) {
    const email = e.target.value;
    
    this.setState({
      email: email,
    });
  }
  onChangeFechaRegistro(e) {
    const fechaRegistro = e.target.value.toUpperCase();
    
    this.setState({
      fechaRegistro: fechaRegistro,
    });
  }
  onChangeContraseña(e) {
    const contraseña = e.target.value.toUpperCase();
    
    this.setState({
      contraseña: contraseña,
    });
  }

  toggleChange = () => {
    this.setState({
      checkedPassPort: !this.state.checkedPassPort,
    });

  }

  toggleChange2 = () => {
    this.setState({
     
      checkedDNI: !this.state.checkedDNI,
    });

  }

  updateUsuario(loading) {
	
    if(this.state.nombres == null){
      this.setState({ 
        nombres: this.state.Pdescripcion
      });
    }

    if(this.state.simbolo == null){
      this.setState({ 
        simbolo: this.state.Psimbolo
      });
    }

    if(this.state.idmoneda == null){
      this.setState({ 
        idMoneda: this.state.PidMoneda
      });
    }

	  var dataUp = { 
      nombres: this.state.nombres, 
      apellidos: this.state.apellidos, 
      fechaRegistro: this.state.fechaRegistro, 
      telefono: this.state.telefono, 
      iso2:this.state.iso2, 
      contraseña: this.state.contraseña, 
      email: this.state.email, 
      idTipoUsuarios: this.state.idTipoUsuarios, 
      ciudad:this.state.ciudad
    };

    this.props
    .updateUsuario(this.props.match.params.id, dataUp)
    .then((data) => {
     this.setState({
       nombres: data.nombres, 
       apellidos: data.apellidos, 
       fechaRegistro: data.fechaRegistro, 
       telefono: data.telefono, 
       iso2: data.iso2, 
       contraseña: data.contraseña, 
       email: data.email, 
       idTipoUsuarios: data.idTipoUsuarios, 
       ciudad: data.ciudad
    // this.props.history.push("/tutorials");
     })
   })
   .catch((e) => {
     console.log(e);
   });	

  }

  render() {
   // const { RUC} = this.state;
   const { usuarios} = this.props;
    const { usuariosbyId} = this.props;
    const { paiss} = this.props;
    const { tipo_usuarios} = this.props;

    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
    if (!tken) return false
   const decoded = jwt(tken); 

      this.state.Pnombres = `${usuariosbyId.nombres}`
      this.state.Papellidos = `${usuariosbyId.apellidos}`
      this.state.Ptelefono = `${usuariosbyId.telefono}`
      this.state.Pemail = `${usuariosbyId.email}`
      this.state.PfechaRegistro = `${usuariosbyId.fechaRegistro}`
      this.state.Piso2 = `${usuariosbyId.iso2}`
      this.state.PidTipoUsuarios = `${usuariosbyId.idTipoUsuarios}`
      this.state.Pciudad = `${usuariosbyId.ciudad}`
    
  

    return (
      
		<React.Fragment >
     <PageTitle title="Editar Personal" />
		<Grid container >

			<div style={{width: "100%", justifyContent: 'center'}}>
      
			<Paper style={{justifyContent: 'center', width: "100%", padding: '10px'}}>

       <div style={{justifyContent: 'center',
        '& .MuiTextField-root': {
            margin: '1px'
        },display: 'flex',
        flexWrap: 'wrap'}}
					 component={Paper} >
                     
                     <Grid container >
            <div style={{width: "100%", justifyContent: 'center'}}>
            <Paper style={{justifyContent: 'center', padding: '10px'}}>

	
                    
              <div style={{justifyContent: 'center',
              '& .MuiTextField-root': {
                  margin: '1px'
              },display: 'flex',
              flexWrap: 'wrap'}}
                  component={Paper} >

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Nombres"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeNombres}
                value={this.state.nombres}
                
                />
                
                
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Apellidos"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeApellidos}
                value={this.state.apellidos}
                
                />
                
                
                 </Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Teléfono"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeTelefono}
                value={this.state.telefono}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Correo electrónico"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeEmail}
                value={this.state.email}
                />
                 </Grid>


                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
               
<FormControl fullWidth>
        <InputLabel id="demo-simple-select-standard-label">Pais</InputLabel>
<Select 
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="Vsede"
          label="Pais"
          value={this.state.iso2}
          onChange={this.onChangePais}
        >
          <MenuItem value="">
            <em>Seleccione un Pais</em>
          </MenuItem>

        
          { paiss.data && paiss.data.map(data =>(
         <MenuItem value={data.iso2} >{data.name}</MenuItem>
         ))
          }
         
        
        </Select>
        </FormControl>
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
               
                    <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Tipo Usuarios</InputLabel>
                    <Select 
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name="Vsede"
                              label="Tipo Usuarios"
                              value={this.state.idTipoUsuarios}
                              onChange={this.onChangeIdTipoUsuario}
                            >
                              <MenuItem value="">
                                <em>Seleccione un Tipo de Usuario</em>
                              </MenuItem>
                    
                            
            { tipo_usuarios && tipo_usuarios.map(data =>(
                       <MenuItem value={data.idTipoUsuarios} >{data.nombre}</MenuItem>
         ))
          }
                              
                    </Select>
                            </FormControl>
                </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Ciudad"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeCiudad}
                value={this.state.ciudad}
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Contraseña"
                variant= "outlined"
                fullWidth 
                type="password"
                autoComplete="off"
                onChange={this.onChangeContraseña}
                value={this.state.contraseña}
                />
                 </Grid>


              </div>
              </Paper>
              </div>
              </Grid>
              
			</div>
			</Paper>
			</div>
		</Grid>
	</React.Fragment>
	
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //clientes: state.clientes,
    
    personals: state.personals,
	  sedes: state.sedes,
    roles: state.roles,
    clienteByDNI: state.clienteByDNI,
  };
};

export default connect(mapStateToProps, {
  //retrieveTutorials,
})(EditUsuarios);