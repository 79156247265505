

import React, { Component } from "react";
import { connect } from "react-redux"; 

import {
  retrievePais
} from "../../actions/pais.actions";

import {
  createUsuario
} from "../../actions/usuario.actions";

import {
  createPermisos, deletePermisos, findPermisosById, deletePermisosData
} from "../../actions/permisos.actions";

import { toast } from 'react-toastify';

import { format } from 'date-fns' 

import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import {Grid, Divider, Paper, TextField} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Button,Tabs,Tab, FormControlLabel, Checkbox } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import DeviceDetector from "device-detector-js";
import {getUA} from 'react-device-detect'
import jwt from 'jwt-decode'
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import {isEmail, isEmpty,isLength, equals} from 'validator';

import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import TreeItem from '@mui/lab/TreeItem';


class UsuariosDialog extends Component {
  constructor(props) {
    super(props);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onChangeNombres = this.onChangeNombres.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeApellidos = this.onChangeApellidos.bind(this);
    this.onChangeContraseña = this.onChangeContraseña.bind(this);
    this.onChangeTelefono = this.onChangeTelefono.bind(this);
    this.onChangeFechaRegistro = this.onChangeFechaRegistro.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeCiudad = this.onChangeCiudad.bind(this);
    this.onChangePais = this.onChangePais.bind(this);
    this.onChangeIdTipoUsuario = this.onChangeIdTipoUsuario.bind(this);
    this.saveUsuarios = this.saveUsuarios.bind(this);
    this.savePermisos = this.savePermisos.bind(this);
    this.deletePermisos = this.deletePermisos.bind(this);

   this.toggleChangeAddMoneda = this.toggleChangeAddMoneda.bind(this)
    this.toggleChangeAddPais = this.toggleChangeAddPais.bind(this)
    this.toggleChangeAddPaquetes = this.toggleChangeAddPaquetes.bind(this)
    this.toggleChangeAddUsuarios = this.toggleChangeAddUsuarios.bind(this)
    this.toggleChangeAddAppupdate = this.toggleChangeAddAppupdate.bind(this)
     
    this.toggleChangeListarMoneda = this.toggleChangeListarMoneda.bind(this)
    this.toggleChangeListarPais = this.toggleChangeListarPais.bind(this)
    this.toggleChangeListarPaquetes = this.toggleChangeListarPaquetes.bind(this)
    this.toggleChangeListarUsuarios = this.toggleChangeListarUsuarios.bind(this)
    this.toggleChangeListarAppupdate = this.toggleChangeListarAppupdate.bind(this)

    this.toggleChangeEditarMoneda = this.toggleChangeEditarMoneda.bind(this)
    this.toggleChangeEditarPais = this.toggleChangeEditarPais.bind(this)
    this.toggleChangeEditarPaquetes = this.toggleChangeEditarPaquetes.bind(this)
    this.toggleChangeEditarUsuarios = this.toggleChangeEditarUsuarios.bind(this)
    this.toggleChangeEditarAppupdate = this.toggleChangeEditarAppupdate.bind(this)

    this.toggleChangeEliminarMoneda = this.toggleChangeEliminarMoneda.bind(this)
    this.toggleChangeEliminarPais = this.toggleChangeEliminarPais.bind(this)
    this.toggleChangeEliminarPaquetes = this.toggleChangeEliminarPaquetes.bind(this)
    this.toggleChangeEliminarUsuarios = this.toggleChangeEliminarUsuarios.bind(this)
    this.toggleChangeEliminarAppupdate = this.toggleChangeEliminarAppupdate.bind(this)

    
    

    this.state = {
        id: null,
        nombres: "",
        apellidos: "",
        telefono: "",
        email: "",
        tabId: 0,
        fechaRegistro: "",
        iso2: "",
        idTipoUsuarios: 0,
        ciudad: "",
        username:"",

        DataTipoUsuarios: "",
        Data2TipoUsuarios: "",

        idUsuarios: 0, 
        next: false,

        idPermisosAddMoneda: "",
        idPermisosEditMoneda: "",
        idPermisosEliminarMoneda: "",
        idPermisosListMoneda: "",
        checkedAddMoneda: false,
        checkedEditarMoneda: false,
        checkedListarMoneda: false,
        checkedEliminarMoneda: false,
      
       idPermisosAddPais: "",
        idPermisosEditPais: "",
        idPermisosEliminarPais: "",
        idPermisosListPais: "",

        idPermisosAddAppupdate: "",
        idPermisosEditAppupdate: "",
        idPermisosEliminarAppupdate: "",
        idPermisosListAppupdate: "",

        checkedAddPais: false,
        checkedEditarPais: false,
        checkedListarPais: false,
        checkedEliminarPais: false,

        checkedAddUsuarios: false,
        checkedEditarUsuarios: false,
        checkedListarUsuarios: false,
        checkedEliminarUsuarios: false,

        checkedAddPaquetes: false,
        checkedEditarPaquetes: false,
        checkedListarPaquetes: false,
        checkedEliminarPaquetes: false,

        checkedAddAppupdate: false,
        checkedEditarAppupdate: false,
        checkedListarAppupdate: false,
        checkedEliminarAppupdate: false,

        checkedAddClientes: false,
        checkedEditarClientes: false,
        checkedListarClientes: false,
        checkedEliminarClientes: false,

        idPermisosAddUsuarios: "",
        idPermisosEditUsuarios: "",
        idPermisosEliminarUsuarios: "",
        idPermisosListUsuarios: "",

        idPermisosAddClientes: "",
        idPermisosEditClientes: "",
        idPermisosEliminarClientes: "",
        idPermisosListClientes: "",
        

        idPermisosAddPaquetes: "",
        idPermisosEditPaquetes: "",
        idPermisosEliminarPaquetes: "",
        idPermisosListPaquetes: "",
        checkedAddPaquetes: false,
        checkedEditarPaquetes: false,
        checkedListarPaquetes: false,
        checkedEliminarPaquetes: false,

        expanded: [],
        
      loading: false,
    }

  }

 
  componentDidMount() {
    //this.props.retrieveTipoUsuario();

    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
    if (!tken) return false
    const decoded = jwt(tken); 

    //console.log("idUsuarios", decoded.idUsuarios)
   // this.props.findPermisosById(decoded.idUsuarios)
   
  }



  onChangeNombres(e) {
    const nombres = e.target.value.toUpperCase();
    
    this.setState({
      nombres: nombres,
    });
  }

  onChangeUsername(e) {
    const username = e.target.value;
    
    this.setState({
      username: username,
    });
  }
  //
  toggleChangeAddMoneda = () => {
   // const { label, code, idUsuarios, link, icon, type} = this.state;
    this.setState({
      checkedAddMoneda: !this.state.checkedAddMoneda,
    });

}

toggleChangeEditarMoneda = () => {
 // const { label, code, idUsuarios, link, icon, type} = this.state;
  this.setState({
    checkedEditarMoneda: !this.state.checkedEditarMoneda,
  });
}

toggleChangeEliminarMoneda = () => {
  //const { label, code, idUsuarios, link, icon, type} = this.state;
  this.setState({
    checkedEliminarMoneda: !this.state.checkedEliminarMoneda,
  });
}

toggleChangeListarMoneda = () => {
 // const { label, code, idUsuarios, link, icon, type} = this.state;
  this.setState({
    checkedListarMoneda: !this.state.checkedListarMoneda
  });

}
//

 //
 toggleChangeAddPais = () => {
  // const { label, code, idUsuarios, link, icon, type} = this.state;
   this.setState({
     checkedAddPais: !this.state.checkedAddPais
   });

}

toggleChangeEditarPais = () => {
// const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedEditarPais: !this.state.checkedEditarPais
 });
}

toggleChangeEliminarPais = () => {
 //const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedEliminarPais: !this.state.checkedEliminarPais
 });
}

toggleChangeListarPais = () => {
// const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedListarPais: !this.state.checkedListarPais
 });
}
//

 //
 toggleChangeAddAppupdate = () => {
  // const { label, code, idUsuarios, link, icon, type} = this.state;
   this.setState({
     checkedAddAppupdate: !this.state.checkedAddAppupdate
   });

}

toggleChangeEditarAppupdate = () => {
// const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedEditarAppupdate: !this.state.checkedEditarAppupdate
 });
}

toggleChangeEliminarAppupdate = () => {
 //const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedEliminarAppupdate: !this.state.checkedEliminarAppupdate
 });
}

toggleChangeListarAppupdate = () => {
// const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedListarAppupdate: !this.state.checkedListarAppupdate
 });
}
//

//
toggleChangeAddUsuarios = () => {
  // const { label, code, idUsuarios, link, icon, type} = this.state;
   this.setState({
     checkedAddUsuarios: !this.state.checkedAddUsuarios
   });
}

toggleChangeEditarUsuarios = () => {
// const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedEditarUsuarios: !this.state.checkedEditarUsuarios
 });
}

toggleChangeEliminarUsuarios = () => {
 //const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedEliminarUsuarios: !this.state.checkedEliminarUsuarios
 });
}

toggleChangeListarUsuarios = () => {
// const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedListarUsuarios: !this.state.checkedListarUsuarios
 });
 
}
//

//
toggleChangeAddPaquetes = () => {
  // const { label, code, idUsuarios, link, icon, type} = this.state;
   this.setState({
     checkedAddPaquetes: !this.state.checkedAddPaquetes
   });
}

toggleChangeEditarPaquetes = () => {
// const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedEditarPaquetes: !this.state.checkedEditarPaquetes
 });
}

toggleChangeEliminarPaquetes = () => {
 //const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedEliminarPaquetes: !this.state.checkedEliminarPaquetes
 });
}

toggleChangeListarPaquetes = () => {
// const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedListarPaquetes: !this.state.checkedListarPaquetes
 });
}

toggleChangeAddClientes = () => {
  // const { label, code, idUsuarios, link, icon, type} = this.state;
   this.setState({
     checkedAddClientes: !this.state.checkedAddClientes
   });
}

toggleChangeEditarClientes = () => {
// const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedEditarClientes: !this.state.checkedEditarClientes
 });
}

toggleChangeEliminarClientes = () => {
 //const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedEliminarClientes: !this.state.checkedEliminarClientes
 });
}

toggleChangeListarClientes = () => {
// const { label, code, idUsuarios, link, icon, type} = this.state;
 this.setState({
   checkedListarClientes: !this.state.checkedListarClientes
 });
}


deletePermisos(id, code){

  this.props
 .deletePermisosData(id, code)
 .then(() => {
 // this.props.history.push("/tutorials");
})
.catch((e) => {
  console.log(e);
});	 
 
}



savePermisos() {
  
  if(this.state.checkedAddMoneda){
   this.props
    .createPermisos("Add Moneda", "2001", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  if(this.state.checkedEditarMoneda){
    this.props
    .createPermisos("Editar Moneda", "2002", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  if(this.state.checkedListarMoneda){
    this.props
    .createPermisos("Listar Moneda", "2003", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  if(this.state.checkedEliminarMoneda){
    //console.log("usereliminarMoneda", this.state.idUsuarios);
    this.props
    .createPermisos("Eliminar Moneda", "2004", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  //////////////////////////////////////////////////////

  //////////////////////////////////////////////////////
  if(this.state.checkedAddPais){
    this.props
    .createPermisos("Add Pais","1001", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  if(this.state.checkedEditarPais){
    this.props
    .createPermisos("Editar Pais", "1002", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  if(this.state.checkedListarPais){
    this.props
    .createPermisos("Listar Pais", "1003", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  if(this.state.checkedEliminarPais){
    this.props
    .createPermisos("Eliminar Pais", "1004", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  //////////////////////////////////////////////////////

  //////////////////////////////////////////////////////
  if(this.state.checkedAddUsuarios){
    this.props
    .createPermisos("Add Usuarios", "4001", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  if(this.state.checkedEditarUsuarios){
    this.props
    .createPermisos("Editar Usuarios", "4002", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  if(this.state.checkedListarUsuarios){
    this.props
    .createPermisos("Listar Usuarios", "4003", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  if(this.state.checkedEliminarUsuarios){
    this.props
    .createPermisos("Eliminar Usuarios", "4004", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  //////////////////////////////////////////////////////

   //////////////////////////////////////////////////////
   if(this.state.checkedAddPaquetes){
    //console.log("usereliminarMoneda", this.state.idUsuarios);
    this.props
    .createPermisos("Add Paquetes", "3001", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });

  }
  if(this.state.checkedEditarPaquetes){

    this.props
    .createPermisos("Editar Paquetes", "3002", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  if(this.state.checkedListarPaquetes){
    this.props
    .createPermisos("Listar Paquetes", "3003", this.state.idUsuarios,"/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  if(this.state.checkedEliminarPaquetes){
    this.props
    .createPermisos("Eliminar Paquetes", "3004", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });

  }

   //////////////////////////////////////////////////////
   if(this.state.checkedAddClientes){
    this.props
    .createPermisos("Add Clientes", "5001", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  if(this.state.checkedEditarClientes){
    this.props
    .createPermisos("Editar Clientes", "5002", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  if(this.state.checkedListarClientes){
    this.props
    .createPermisos("Listar Clientes", "5003", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  if(this.state.checkedEliminarClientes){
    this.props
    .createPermisos("Eliminar Clientes", "5004", this.state.idUsuarios, "/", "/", "/")
    .then((data) => {
      this.setState({
      });
   // }
    })
    .catch((e) => {
      console.log(e);
    });
  }
  //////////////////////////////////////////////////////


  //window.location.reload(true); 
}

  onChangeApellidos(e) {
    const apellidos = e.target.value.toUpperCase();
    
    this.setState({
      apellidos: apellidos,
    });
  }

  onChangeIdTipoUsuario(e) {
    const idTipoUsuarios = e.target.value;
    
    this.setState({
      idTipoUsuarios: idTipoUsuarios,
    });
  }

  onChangePais(e) {
    const iso2 = e.target.value;
    
    this.setState({
      iso2: iso2,
    });
  }

  onKeyUp(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.saveUsuarios(true);
    }
  }

  onChangeCiudad(e) {
    const ciudad = e.target.value;
    
    this.setState({
      ciudad: ciudad,
    });
  }
  onChangeTelefono(e) {
    const telefono = e.target.value.toUpperCase();
    
    this.setState({
      telefono: telefono,
    });
  }
  onChangeEmail(e) {
    const email = e.target.value;
    
    this.setState({
      email: email,
    });
  }
  onChangeFechaRegistro(e) {
    const fechaRegistro = e.target.value.toUpperCase();
    
    this.setState({
      fechaRegistro: fechaRegistro,
    });
  }
  onChangeContraseña(e) {
    const contraseña = e.target.value;
    
    this.setState({
      contraseña: contraseña,
    });
  }

  handleChangeTab(tabId){
    this.setState({ 
       tabId
      });
  } 

  handleChangeAdd(checkedAdd){
    this.props.retrievePais()
    this.setState({ 
        checkedAdd
      });
  } 

  
  saveUsuarios(loading) {
    const {username, nombres, apellidos, fechaRegistro, telefono, iso2, contraseña, email, idTipoUsuarios, ciudad} = this.state;
    if (loading == true) {
      this.setState({
        loading
      });
  this.props
      .createUsuario(username, nombres, apellidos, fechaRegistro, telefono, iso2, contraseña, email, idTipoUsuarios, ciudad)
      .then((data) => {
        this.setState({
          loading: false,
          next: true,
          idUsuarios: data.user.idUsuarios
        });

        if(this.state.idTipoUsuarios == 1){
          this.setState({
            checkedAddMoneda: true,
            checkedEditarMoneda: true,
            checkedListarMoneda: true,
            checkedEliminarMoneda: true,

            checkedAddPais: true,
            checkedEditarPais: true,
            checkedListarPais: true,
            checkedEliminarPais: true,
    
            checkedAddUsuarios: true,
            checkedEditarUsuarios: true,
            checkedListarUsuarios: true,
            checkedEliminarUsuarios: true,
    
            checkedAddPaquetes: true,
            checkedEditarPaquetes: true,
            checkedListarPaquetes: true,
            checkedEliminarPaquetes: true,

            checkedAddAppupdate: true,
            checkedEditarAppupdate: true,
            checkedListarAppupdate: true,
            checkedEliminarAppupdate: true,

          })
         /* this.savePermisos()

          this.handleChangeAdd(false)

          this.setState({
          nombres: "",
        apellidos: "",
        telefono: "",
        email: "",
        tabId: 0,
        fechaRegistro: "",
        iso2: "",
        idTipoUsuarios: 0,
        ciudad: ""
          })*/
          //////////////////////////////////////////////////////
          /*if(this.state.checkedAddMoneda){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeAddMoneda()
          }
          if(this.state.checkedEditarMoneda){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeEditarMoneda()
          }
          if(this.state.checkedListarMoneda){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeListarMoneda()
          }
          if(this.state.checkedEliminarMoneda){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeEliminarMoneda()
          }
          //////////////////////////////////////////////////////

          //////////////////////////////////////////////////////
          if(this.state.checkedAddPais){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeAddPais()
          }
          if(this.state.checkedEditarPais){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeEditarPais()
          }
          if(this.state.checkedListarPais){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeListarPais()
          }
          if(this.state.checkedEliminarPais){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeEliminarPais()
          }
          //////////////////////////////////////////////////////

          //////////////////////////////////////////////////////
          if(this.state.checkedAddUsuarios){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeAddUsuarios()
          }
          if(this.state.checkedEditarUsuarios){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeEditarUsuarios()
          }
          if(this.state.checkedListarUsuarios){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeListarUsuarios()
          }
          if(this.state.checkedEliminarUsuarios){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeEliminarUsuarios()
          }
          //////////////////////////////////////////////////////

           //////////////////////////////////////////////////////
           if(this.state.checkedAddPaquetes){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeAddPaquetes()
          }
          if(this.state.checkedEditarPaquetes){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeEditarPaquetes()
          }
          if(this.state.checkedListarPaquetes){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeListarPaquetes()
          }
          if(this.state.checkedEliminarPaquetes){
            //console.log("usereliminarMoneda", this.state.idUsuarios);
           this.toggleChangeEliminarPaquetes()
          }*/
          //////////////////////////////////////////////////////
          

          
        }

        //console.log("datos usuarios", data.user.idUsuarios)
     // }
      })
      .catch((e) => {
        
        //console.log(e);
        //this.props.history.push("/admin/cliente")
      
        if(e == "Request failed with status code 500"){
          toast.error('Error, 500 internal');
          this.setState({
            loading: false
          });
        }
      });
    }
  }


  

  

  render() {
  
    const { paiss} = this.props;
   // const { tipo_usuarios} = this.props;

    this.state.fechaRegistro = new Date();
    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
      if (!tken) return false

      const decoded = jwt(tken); 

      this.state.Data2TipoUsuarios = decoded.tipoUsuarios;
    //console.log("userc", this.state.Data2TipoUsuarios);

    return (
        <React.Fragment >
          
            <div>

      <Button color="primary" onClick={() => this.handleChangeAdd(true)} >
          <AddCircleIcon style={{ fontSize: "40px" }} /> <p style={{ fontSize: "15px" }}>Nuevo Usuario</p>
      </Button>
      <Dialog disableEnforceFocus
      maxWidth="sm"
        onKeyPress={this.onKeyUp}
        open={this.state.checkedAdd} 
        onClose={!this.state.checkedAdd}
        aria-labelledby="form-dialog-title"
      >
        <DialogActions style={{paddingLeft: 10, paddingTop:10}}>
              <Grid container >
              <Grid item lg={10} md={10} sm={10} xs={10}  style={{alignContent: "flex-start", alignItems: "flex-start", textAlign: "flex-start"}}>
              <DialogTitle id="form-dialog-title">Nuevo Usuario</DialogTitle>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Button variant="contained" onClick={() => this.handleChangeAdd(false, false)} color="secondary">
                  <HighlightOff  style={{ fontSize: "30px" }} /> 
                  </Button>
                  </Grid>
                  </Grid>
              </DialogActions>
        {!this.state.next ?
        <>
            <DialogContent style={{padding: "30px 30px 10px 30px"}}>
	
            <Grid container >
            <div style={{width: "100%", justifyContent: 'center'}}>
            <Paper style={{justifyContent: 'center', padding: '10px'}}>

	
                    
              <div style={{justifyContent: 'center',
              '& .MuiTextField-root': {
                  margin: '1px'
              },display: 'flex',
              flexWrap: 'wrap'}}
                  component={Paper} >


<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Username"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeUsername}
                value={this.state.username}
                
                />
                
                
                 </Grid>
<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Nombres"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeNombres}
                value={this.state.nombres}
                
                />
                
                
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Apellidos"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeApellidos}
                value={this.state.apellidos}
                
                />
                
                
                 </Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Teléfono"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeTelefono}
                value={this.state.telefono}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Correo electrónico"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeEmail}
                value={this.state.email}
                />
                 </Grid>


                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
               
<FormControl fullWidth>
        <InputLabel id="demo-simple-select-standard-label">Pais</InputLabel>
<Select 
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="Vsede"
          label="Pais"
          value={this.state.iso2}
          onChange={this.onChangePais}
        >
          <MenuItem value="">
            <em>Seleccione un Pais</em>
          </MenuItem>

        
          { paiss.data && paiss.data.map(data =>(
         <MenuItem value={data.iso2} >{data.name}</MenuItem>
         ))
          }
         
        
        </Select>
        </FormControl>
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
               
                 { this.state.Data2TipoUsuarios != 1 ?
                    <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Tipo Usuarios</InputLabel>
                    <Select 
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name="Vsede"
                              label="Tipo Usuarios"
                              value={this.state.idTipoUsuarios}
                              onChange={this.onChangeIdTipoUsuario}
                            >
                              <MenuItem value="">
                                <em>Seleccione un Tipo de Usuario</em>
                              </MenuItem>
                     
                              <MenuItem value={2} >Usuario</MenuItem>
                        
                              
                            
            {/* tipo_usuarios && tipo_usuarios.map(data =>(
                       <MenuItem value={data.idTipoUsuarios} >{data.nombre}</MenuItem>
         ))
            */}
                              
                            
                    </Select>
                            </FormControl>
                            :

                           
                              <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-standard-label">Tipo Usuarios</InputLabel>
                              <Select 
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        name="Vsede"
                                        label="Tipo Usuarios"
                                        value={this.state.idTipoUsuarios}
                                        onChange={this.onChangeIdTipoUsuario}
                                      >
                                        <MenuItem value="">
                                          <em>Seleccione un Tipo de Usuario</em>
                                        </MenuItem>
                              
                                       
                                        <MenuItem value={1} >Super Admin</MenuItem>
                                        <MenuItem value={2} >Usuario</MenuItem>
                                        
                                        
                                      
                      {/* tipo_usuarios && tipo_usuarios.map(data =>(
                                 <MenuItem value={data.idTipoUsuarios} >{data.nombre}</MenuItem>
                   ))
                      */}
                                        
                                      
                              </Select>
                                      </FormControl>
  }

                </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Ciudad"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeCiudad}
                value={this.state.ciudad}
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Contraseña"
                variant= "outlined"
                fullWidth
                type="password"
                autoComplete="off"
                onChange={this.onChangeContraseña}
                value={this.state.contraseña}
                />
                 </Grid>

              </div>
  
              </Paper>
              </div>
              </Grid>
                    </DialogContent>
                   

              <DialogActions style={{padding: 30}}>
                  <Button variant="contained" onClick={() => this.handleChangeAdd(false)} color="primary">
                      Cancelar
                  </Button>
                  
              <LoadingButton
                size="small"
                onClick={() => this.saveUsuarios(true)}
                endIcon={<SaveIcon />}
                color="secondary"
                loading={this.state.loading}
                loadingPosition="end"
                variant="contained"
              >
                Guardar
              </LoadingButton >
              </DialogActions>
              </>
:

                             <div style={{margin: '10px', paddingTop: "20px"}}>
                             <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                              <div>
                              <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ minHeight: 100, flexGrow: 1, minWidth: 400, overflowY: 'auto' }}
    >
      <TreeItem nodeId="1" label="Ubicación" style={{margin: '10px'}}>

      <TreeItem nodeId="2" label="Pais" style={{margin: '10px'}}>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedAddPais}
          onChange={this.toggleChangeAddPais} />} label="Registrar Pais" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedListarPais}
          onChange={this.toggleChangeListarPais} />} label="Listar Pais" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedEliminarPais}
          onChange={this.toggleChangeEliminarPais} />} label="Eliminar Pais" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedEditarPais}
          onChange={this.toggleChangeEditarPais} />} label="Editar Pais" />
      </Grid>
      </TreeItem>
      
      <TreeItem nodeId="3" label="Moneda" style={{margin: '10px'}}>
      
      
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedAddMoneda}
          onChange={this.toggleChangeAddMoneda} />} label="Registrar Moneda" />
      </Grid>
      
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedListarMoneda}
          onChange={this.toggleChangeListarMoneda} />} label="Listar Moneda" />
      </Grid>
  
  
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedEliminarMoneda}
          onChange={this.toggleChangeEliminarMoneda} />} label="Eliminar Moneda" />
      </Grid>
  
  
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedEditarMoneda}
          onChange={this.toggleChangeEditarMoneda} />} label="Editar Moneda" />
      </Grid>
  
      </TreeItem>

      </TreeItem>

      <TreeItem nodeId="4" label="Usuarios" style={{margin: '10px'}}>

  
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedListarUsuarios}
          onChange={this.toggleChangeListarUsuarios} />} label="Listar Usuario" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedEliminarUsuarios}
          onChange={this.toggleChangeEliminarUsuarios} />} label="Eliminar Usuario" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedEditarUsuarios}
          onChange={this.toggleChangeEditarUsuarios} />} label="Editar Usuario" />
      </Grid>
     {/* <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedEditarUsuarios}
          onChange={this.toggleChange} />} label="Editar Contraseña" />
</Grid>*/}

</TreeItem>

<TreeItem nodeId="5" label="Paquetes" style={{margin: '10px'}}>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedAddPaquetes}
          onChange={this.toggleChangeAddPaquetes} />} label="Registrar Paquetes" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedListarPaquetes}
          onChange={this.toggleChangeListarPaquetes} />} label="Listar Paquetes" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedEliminarPaquetes}
          onChange={this.toggleChangeEliminarPaquetes} />} label="Eliminar Paquetes" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedEditarPaquetes}
          onChange={this.toggleChangeEditarPaquetes} />} label="Editar Paquetes" />
      </Grid>
      </TreeItem>

      <TreeItem nodeId="6" label="Clientes" style={{margin: '10px'}}>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedAddClientes}
          onChange={this.toggleChangeAddClientes} />} label="Registrar Clientes" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedListarClientes}
          onChange={this.toggleChangeListarClientes} />} label="Listar Clientes" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedEliminarClientes}
          onChange={this.toggleChangeEliminarClientes} />} label="Eliminar Clientes" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedEditarClientes}
          onChange={this.toggleChangeEditarClientes} />} label="Editar Clientes" />
      </Grid>
      </TreeItem>

      <TreeItem nodeId="5" label="App Update" style={{margin: '10px'}}>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedAddAppupdate}
          onChange={this.toggleChangeAddAppupdate} />} label="Registrar Appupdate" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedListarAppupdate}
          onChange={this.toggleChangeListarAppupdate} />} label="Listar Appupdate" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedEliminarAppupdate}
          onChange={this.toggleChangeEliminarAppupdate} />} label="Eliminar Appupdate" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px', marginBotton: '10px' , marginLeft: "40px"}}>
          <FormControlLabel  control={<Checkbox checked={this.state.checkedEditarAppupdate}
          onChange={this.toggleChangeEditarAppupdate} />} label="Editar Appupdate" />
      </Grid>
      </TreeItem>

    </TreeView>
            </div>
               </Grid>  
               <DialogActions style={{padding: 30}}>
                  <Button variant="contained" onClick={() => this.handleChangeAdd(false)} color="primary">
                      Cancelar
                  </Button>
                  
              <LoadingButton
                size="small"
                onClick={() => this.savePermisos()}
                endIcon={<SaveIcon />}
                color="secondary"
                loading={this.state.loading}
                loadingPosition="end"
                variant="contained"
              >
                Guardar
              </LoadingButton >
              </DialogActions>        
                  </div>
                       
}

              </Dialog>
              </div>
                </React.Fragment>
                  );
                }
                
              }


const mapStateToProps = (state) => {
  return {
    paiss: state.paiss
  };
};

export default connect(mapStateToProps, {
  retrievePais, createUsuario, createPermisos,  findPermisosById, deletePermisos, deletePermisosData
})(UsuariosDialog);

