import {
  TIPO_USUARIOS_BY_ID
} from "../constants/tipo_usuario.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];



function tipo_usuarioReducer(tipo_usuariosbyId = initialState, action) {
  const { type, payload } = action;

  function Refreshdata(){
    window.location.reload(true);
  }

  switch (type) {
   
    case TIPO_USUARIOS_BY_ID: 
      return payload;

    default:
      return tipo_usuariosbyId;
  }
};



export default tipo_usuarioReducer;