import {
  CREATE_MONEDA,
  RETRIEVE_MONEDAS,
  UPDATE_MONEDA,
  DELETE_MONEDA
} from "../constants/moneda.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];



function paisReducer(monedas = initialState, action) {
  const { type, payload } = action;

  function Refreshdata(){
    window.location.reload(true);
  }

  switch (type) {
    case CREATE_MONEDA:
     toast.success('Moneda Registrada de manera Éxitosa');
     window.location.reload(true);
     //setInterval(Refreshdata, 1000)
      return [...monedas, payload];

    case RETRIEVE_MONEDAS: 
      return payload;

    case UPDATE_MONEDA:
      toast.success('Moneda Actualizada de manera Éxitosa');
      window.location.reload(true);
      //setInterval(Refreshdata, 1000)
      return {...monedas, payload};

    case DELETE_MONEDA:
      toast.success('Moneda Eliminada de manera Éxitosa');
      window.location.reload(true);
      //setInterval(Refreshdata, 100)
      return monedas.filter(({ id }) => id !== payload.id);

    default:
      return monedas;
  }
};



export default paisReducer;