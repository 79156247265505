import React from "react";
import { Grid, withStyles } from '@material-ui/core';
import { Route , withRouter} from 'react-router-dom';

import PageTitle from "../../../components/PageTitle/PageTitle";
import PaisTable from "../../../components/Table/pais.table";
import Tipo_usuarioTable from "../../../components/Table/tipo_usuario.table";

const styles = theme => ({
    paper: {
        margin: theme.spacing(3),
        padding: theme.spacing(2) 
    }
}) 

const Tipo_Usuario = ({ classes, ...props }) => {
    return (
        <React.Fragment>
            <PageTitle title="Tipo Usuarios" />
            <Grid container>
            <Tipo_usuarioTable/>
            </Grid>
        </React.Fragment>
    );
}

export default withRouter(Tipo_Usuario);

