import {
  CREATE_PAQUETE,
  RETRIEVE_PAQUETES,
  UPDATE_PAQUETE,
  DELETE_PAQUETE,
  PAQUETES_BY_ID
} from "../constants/paquete.constants";

import { toast } from 'react-toastify';

import PaqueteDataService from "../services/paquetes.service";

export const createPaquete = (nombre, precio, descripcion, idPais, idUsuarios, idMoneda, vigencia, terminos) => async (dispatch) => {
  try {
    const res = await PaqueteDataService.create({nombre, precio, descripcion, idPais, idUsuarios, idMoneda, vigencia, terminos});

    dispatch({
      type: CREATE_PAQUETE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err.message);
  }
};

export const retrievePaquete = () => async (dispatch) => {
  try {
    const res = await PaqueteDataService.getAllData();

    dispatch({
      type: RETRIEVE_PAQUETES,
      payload: res.data,
      
    });
    
  } catch (err) {
    console.log(err);
  }
};

export const findPaquetesById = (id) => async (dispatch) => {
  try {
    const res = await PaqueteDataService.get(id);

    dispatch({
      type: PAQUETES_BY_ID,
      payload: res.data,
    }); 
    
  } catch (err) {
    console.log(err);
  }
};
  
export const updatePaquete = (id, data) => async (dispatch) => {
  console.log("id", data)
  try {
    const res = await PaqueteDataService.update(id, data);

    dispatch({
      type: UPDATE_PAQUETE,
      payload: data,
    });
    
    return Promise.resolve(res.data);
  } catch (err) {
    console.log(err)
    return Promise.reject(err);
  }
};

export const deletePaquete = (id) => async (dispatch) => {
  try {
    await PaqueteDataService.delete(id);

    dispatch({
      type: DELETE_PAQUETE,
      payload: { id },
    });
  } catch (err) {

    if (err.message == "Request failed with status code 400") {
      toast.error('No se puede Eliminar este Paquete, el Id está siendo usada por otra tabla', {
        autoClose: 10000,
      });
    }
    if (err.message == "Request failed with status code 401") {
      toast.error('Sin Autorización');
    }

    if (err.message == "Request failed with status code 505") {
      toast.error('No tiene permisos suficientes para visitar esta página');
    }
    //console.log(err);
  }
};
