import {
  CREATE_PERMISO,
  RETRIEVE_PERMISOS,
  UPDATE_PERMISO,
  DELETE_PERMISO,
} from "../constants/permisos.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];

function permisosReducer(permisos = initialState, action) {
  const { type, payload } = action;

  function Refreshdata(){
    window.location.reload(true);
  }

  switch (type) {
    case CREATE_PERMISO:
      toast.success('Permiso Registrado de manera Éxitosa');
      //window.location.reload(true);
     //setInterval(Refreshdata, 1000)
      return [...permisos, payload];

    case RETRIEVE_PERMISOS: 
      return payload;
 
    case UPDATE_PERMISO:
      toast.success('Permiso Actualizado de manera Éxitosa');
      window.location.reload(true);
      //setInterval(Refreshdata, 1000)
      return {...permisos, payload};

    case DELETE_PERMISO:
      toast.success('Permiso Eliminado de manera Éxitosa');
      //window.location.reload(true);
      //setInterval(Refreshdata, 100)
      return permisos.filter(({ id }) => id !== payload.id);

    default:
      return permisos;
  }
};



export default permisosReducer;