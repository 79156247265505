import {http} from "../utils/api";

const config = {
  headers: {
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }
};

//console.log("envio token", config)

class UsuarioDataService { 

  getAllData() {
    return http.get("/api/private/users", config);
  }

  get(id) {
    return http.get(`/api/private/users/${id}`, config);
  }

  create(data) {
    return http.post("/api/private/auth/register", data, config);
  }

  update(id, data) {
    return http.put(`/api/private/users/${id}`, data, config);
  }

  delete(id) {
    return http.delete(`/api/private/users/${id}`,config);
  }
}

export default new UsuarioDataService();