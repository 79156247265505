import React from "react";
import { Grid, withStyles } from '@material-ui/core';
import { Route , withRouter} from 'react-router-dom';

import PageTitle from "../../components/PageTitle/PageTitle";
import PaquetesTable from "../../components/Table/paquetes.table";

const styles = theme => ({
    paper: {
        margin: theme.spacing(3),
        padding: theme.spacing(2)
    }
})

const Paquetes = ({ classes, ...props }) => {
    return (
        <React.Fragment>
            <PageTitle title="Paquetes" />
            <Grid container>
                <PaquetesTable/>
            </Grid>
        </React.Fragment>
    );
}

export default withRouter(Paquetes);

