

import React, { Component } from "react";
import { connect } from "react-redux"; 

import {
  createPais
} from "../../actions/pais.actions";

import { toast } from 'react-toastify';

import { format } from 'date-fns'

import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import {Grid, Divider, Paper, TextField} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
//import UAParser from 'ua-parser-js'
//import  ua  from 'react-useragent';
import DeviceDetector from "device-detector-js";
import {getUA} from 'react-device-detect'
import jwt from 'jwt-decode'
//const DeviceDetector = require('node-device-detector');
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

class PaisDialog extends Component {
  constructor(props) {
    super(props);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onChangeCurrency = this.onChangeCurrency.bind(this);
    this.onChangeCurrencyCode = this.onChangeCurrencyCode.bind(this);
    this.onChangeIso2 = this.onChangeIso2.bind(this);
    this.onChangeIso3 = this.onChangeIso3.bind(this);
    this.onChangeIsoCode = this.onChangeIsoCode.bind(this);
   // this.handleChangeSede = this.handleChangeSede.bind(this);
    this.savePais = this.savePais.bind(this);
     
    this.state = {
        checkedAdd: false,
        id: null,
        iso2: "",
        name: "",
        iso3: "",
        iso_code: "",
        currency: "",
        currency_code : "",
      loading: false,
    }

  }

  
  componentDidMount() {
   // this.props.retrieveIngresos();
   
  }

  onChangeName(e) {
    const name = e.target.value;
    
    this.setState({
      name: name,
    });
  }

  onChangeIso2(e) {
    const iso2 = e.target.value.toUpperCase();
    
    this.setState({
      iso2: iso2,
    });
  }

  onKeyUp(event) {
    if (event.charCode == 13 || event.charCode == 8 || event.charCode == 9) {
      this.savePais(true);
    }
  }

  onChangeIso3(e) {
    const iso3 = e.target.value.toUpperCase();
    
    this.setState({
      iso3: iso3,
    });
  }
  onChangeCurrency(e) {
    const currency = e.target.value;
    
    this.setState({
      currency: currency,
    });
  }
  onChangeIsoCode(e) {
    const iso_code = e.target.value.toUpperCase();
    
    this.setState({
      iso_code: iso_code,
    });
  }
  onChangeCurrencyCode(e) {
    const currency_code = e.target.value.toUpperCase();
    
    this.setState({
      currency_code: currency_code,
    });
  }
  
  handleChangeAdd(checkedAdd){
    this.setState({ 
        checkedAdd
      });
      
  } 
  savePais(loading) {
    const { iso2, name, iso3, iso_code, currency, currency_code} = this.state;
    if (loading == true) {
      this.setState({
        loading
      });
  this.props
      .createPais(iso2, name, iso3, iso_code, currency, currency_code)
      .then((data) => {
        this.setState({
        });
     // }
      })
      .catch((e) => {
        
        //console.log(e);
        //this.props.history.push("/admin/cliente")
        /*if(e == "Request failed with status code 441" ){
          toast.error('Error, '+VAL441);
        }*/
        if(e == "Request failed with status code 500"){
          toast.error('Error, 500 internal');
          this.setState({
            loading: false
          });
        }
      });
    }
  }
  

  

  render() {
  
   
    const { paiss} = this.props;


    
    const tken = this.state.token ? this.state.token : localStorage.getItem("token")
      if (!tken) return false

      const decoded = jwt(tken); 
    //console.log("user", data.id);

    return (
        <React.Fragment >
          
            <div>

      <Button color="primary" onClick={() => this.handleChangeAdd(true)} >
          <AddCircleIcon style={{ fontSize: "40px" }} /> <p style={{ fontSize: "15px" }}>Nuevo Pais</p>
      </Button>
      <Dialog disableEnforceFocus
        onKeyPress={this.onKeyUp}
        open={this.state.checkedAdd} 
        onClose={!this.state.checkedAdd}
        aria-labelledby="form-dialog-title"
      >
        
            <DialogTitle id="form-dialog-title" style={{padding: "10px 10px 0px 10px"}}>Nuevo Pais</DialogTitle>

            <DialogContent style={{padding: "30px 30px 10px 30px"}}>
	
            <Grid container >
            <div style={{width: "100%", justifyContent: 'center'}}>
            <Paper style={{justifyContent: 'center', padding: '10px'}}>

	
                    
              <div style={{justifyContent: 'center',
              '& .MuiTextField-root': {
                  margin: '1px'
              },display: 'flex',
              flexWrap: 'wrap'}}
                  component={Paper} >
<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="Viso2"
                label="Iso 2"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeIso2}
                value={this.state.iso2}
                
                />
                 </Grid>

<Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
                <TextField
                name="Vfechareg"
                label="Nombre"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeName}
                value={this.state.name}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="Vfechareg"
                label="Iso 3"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeIso3}
                value={this.state.iso3}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="Vfechareg"
                label="Iso Code"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeIsoCode}
                value={this.state.iso_code}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="Vfechareg"
                label="Currency"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeCurrency}
                value={this.state.currency}
                
                />
                 </Grid>

                 <Grid item lg={12} md={12} sm={12} xs={12}  style={{margin: '10px'}}>
<TextField
                name="Vfechareg"
                label="Currency Code"
                variant= "outlined"
                fullWidth
                autoComplete="off"
                onChange={this.onChangeCurrencyCode}
                value={this.state.currency_code}
                
                />
                 </Grid>
              </div>
              </Paper>
              </div>
              </Grid>
                    </DialogContent>

              <DialogActions style={{padding: 30}}>
                  <Button variant="contained" onClick={() => this.handleChangeAdd(false)} color="primary">
                      Cancelar
                  </Button>
                  
              <LoadingButton
                size="small"
                onClick={() => this.savePais(true)}
                endIcon={<SaveIcon />}
                color="secondary"
                loading={this.state.loading}
                loadingPosition="end"
                variant="contained"
              >
                Guardar
              </LoadingButton >
              </DialogActions>

              </Dialog>
              </div>
                </React.Fragment>
                  );
                }
                
              }

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {
  createPais
})(PaisDialog);

