import React from "react";
import { Grid, withStyles } from '@material-ui/core';
import { Route , withRouter} from 'react-router-dom';

import PageTitle from "../../components/PageTitle/PageTitle";
import AppupdateTable from "../../components/Table/appupdate.table";

const styles = theme => ({
    paper: {
        margin: theme.spacing(3),
        padding: theme.spacing(2)
    }
})

const Appupdate = ({ classes, ...props }) => {
    return (
        <React.Fragment>
            <PageTitle title="Control de Versiones App" />
            <Grid container>
            <AppupdateTable/>
            </Grid>
        </React.Fragment>
    );
}

export default withRouter(Appupdate);

