import {
  USUARIOS_BY_ID,
} from "../constants/usuario.constants";

import { toast } from 'react-toastify';
//import initialState from './initialState';

const initialState = [];



function usuario2Reducer(usuariosbyId = initialState, action) {
  const { type, payload } = action;

  function Refreshdata(){
    window.location.reload(true);
  }

  switch (type) {
    
    case USUARIOS_BY_ID: 
      return payload;

    default:
      return usuariosbyId;
  }
};



export default usuario2Reducer;