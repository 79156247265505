import { combineReducers } from "redux";
import paiss from "./pais.reducers";
import paissbyid from "./pais2.reducers";
import appupdates from "./appupdate.reducers";
import appupdatesbyid from "./appupdate2.reducers";
import monedas from "./monedas.reducers";
import monedasbyid from "./monedas2.reducers";
import tipo_usuarios from "./tipo_usuario.reducers";
import tipo_usuariosbyId from "./tipo_usuario2.reducers";
import paquetes from "./paquetes.reducers";
import paquetes2 from "./paquetes2.reducers";
import usuariosbyId from "./usuarios2.reducers";
import usuarios from "./usuarios.reducers";
import permisos from "./permisos.reducers";
import permisosbyid from "./permisos2.reducers";
import clientes from "./clientes.reducers";
import clientesbyId from "./clientes2.reducers";

export const reducers = combineReducers({
     paiss, monedas, monedasbyid, tipo_usuarios, paquetes, 
     usuarios, paissbyid, paquetes2, tipo_usuariosbyId, appupdates, appupdatesbyid,
     usuariosbyId, permisos, permisosbyid, clientes, clientesbyId
})