import React, { useState, useEffect, useContext } from "react";
import { Drawer, IconButton, List,Button } from "@material-ui/core";
import {
  Home as HomeIcon,
  LibraryBooks as LibraryIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import styles from './styles.module.css'
import GroupsIcon from '@mui/icons-material/Groups';
import jwt from 'jwt-decode'
import {Grid, Divider, Paper, TextField} from "@material-ui/core";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import BackpackIcon from '@mui/icons-material/Backpack';
import RoomIcon from '@mui/icons-material/Room';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';

// styles
import useStyles from "./styles";
//import styles from './styles.module.css'
import "./scrollbar.css";

// components
import SidebarLink from "./SidebarLink/SidebarLink";
/*import {
  findPermisosById
} from "../../actions/permisos.actions";*/
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import {http} from "../../utils/api";


import { AuthContext } from "../../context/AuthContext";
//const tken = localStorage.getItem("token")
const tken = localStorage.getItem("token") 
//if (!tken) return false
const decoded = tken!=undefined ?  jwt(tken): null; 
//console.log("token", decoded)       
const structure = [
  { id: 5, type: "divider" },
  { id: 0, label: "Dashboard", link: "/admin/dashboard", icon: <HomeIcon />},
  { id: 1, label: "Paquetes", link: "/admin/paquetes", icon: <BackpackIcon />},
  { id: 30, label: "Usuarios", link: "/admin/usuarios/usuarios" ,icon: <PersonIcon />} ,
  { id: 30, label: "Clientes", link: "/admin/clientes/clientes" ,icon: <GroupIcon /> },
  { id: 3, label: "Tablas del Sistema", icon: <FormatListBulletedIcon /> ,
  
  children:[
    {id: 10, label: "Pais", link: "/admin/system/pais", icon: <RoomIcon /> } ,
     {id: 11, label: "Moneda", link: "/admin/system/moneda" },
     {id: 11, label: "App Update", link: "/admin/system/appupdate" }
  ]
   }
];

function Sidebar({ location, ...props }) {
  var classes = useStyles();
  var theme = useTheme();

  //axios.get('https://pathc/id?=${id}')
  const { logout} = useContext(AuthContext)

  // global
  var { isSidebarOpened } = useLayoutState(); 
  var layoutDispatch = useLayoutDispatch();

  var id = decoded.idUsuarios

  /*tken != undefined ?
const config = {
  headers: {
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }
};
:*/

  /*http.get(`/permisos/${id}`, config).
  then(res => {
    console.log("permisos", res)
   })*/

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange(); 
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    }; 
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      
      {
      isSidebarOpened ? 
     
         <div className={styles.paperDatos}>
           
           <Grid container >
          
           <Grid item lg={3} md={3} sm={3} xs={3} style={{margin: '10px'}} >
           <img className={styles.avatar} src="https://i.imgur.com/xASbbpf.gif"/>
             </Grid>

             <Grid item lg={6} md={6} sm={6} xs={6}  style={{margin: '10px'}}>
             
                  <div>
                     <h1 className={styles.h1}>{decoded.nombres}</h1>
                  </div>

                  <div>
                     <h1 className={styles.h1}>{decoded.apellidos} </h1>
                  </div>

                  <div>
                     <h2 className={styles.h2}>Nivel Usuario: {decoded.tipoUsuarios} </h2>
                  </div>

             </Grid>
            { /*<Grid item lg={12} md={12} sm={12} xs={12} className={styles.btnlogout}>
             <div className={classes.logoutMenu}>
            <Button
              variant="contained" 
              color="secondary"
              className={classes.logoutMenuButton}
              onClick={() => logout(props.history)}
            >
              Cerrar Sesión
            </Button>
          </div>
      </Grid>*/}
           </Grid>
      </div>
      :
      <div></div>}
      <div>
        <List className={classes.sidebarList}>
          {structure.map(link => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List>
      </div>
      
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}


export default withRouter(Sidebar);